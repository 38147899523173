import React, { ReactElement } from "react";
import { Typography } from "lib/typography";
import { makeStyles } from "@mui/styles";

import { Card } from "managerPortal/components/cards/interfaces";
import { SurveySection } from "../../context/interfaces";
import StackedBar from "../stackedBar/StackedBar";
import { unfavorableColor, neutralColor, favorableColor } from "../../styles/GlobalStyles";
import CustomValueCell from "../shared/CustomValueCell";
import TruncateDisplay from "../shared/TruncateDisplay";
import { ItemData, SnapShotDefinition } from "./content/types";
import { theme } from "lib/theme";

type styleProps = {
    stackbarWidth: number;
};
const useStyles = makeStyles(() => ({
    listItem: {
        display: "grid",
        gridTemplateRows: "56px",
        gridTemplateColumns: "1fr auto auto auto auto",
        borderBottom: theme.border.main
    },
    cellInfo: {
        gridRow: "1 / 2",
        gridColumn: "1 / 2",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        paddingLeft: theme.spacing(3)
    },
    listItemBar: (props: styleProps) => {
        return {
            borderLeft: theme.border.main,
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
            boxSizing: "border-box",
            minWidth: props.stackbarWidth,
            width: props.stackbarWidth,
            widthMax: props.stackbarWidth,
            "& div": {
                height: 28
            }
        };
    },
    cell: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    cellNo: {
        gridRow: "1 / 2",
        gridColumn: "3 / 4",
        width: 78
    },
    cellDemo: {
        gridRow: "1 / 2",
        gridColumn: "4 / 5",
        width: 78,
        borderLeft: theme.border.main
    },
    cellEmpty: {
        gridRow: "1 / 2",
        gridColumn: "2 / 3",
        width: 78
    },
    cellOverall: {
        gridRow: "1 / 2",
        gridColumn: "5 / 6",
        borderLeft: theme.border.main,
        width: 78
    },
    cellYes: {
        gridRow: "1 / 2",
        gridColumn: "4 / 5"
    },
    cellBenchmark: {
        gridRow: "1 / 2",
        gridColumn: "6 / 7",
        borderLeft: theme.border.main,
        width: 80
    },
    cellPreviousPeriod: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gridRow: "1 / 2",
        gridColumn: "2 / 4",
        boxSizing: "border-box",
        minWidth: 78,
        maxWidth: 157
    },
    individualCellPreviousPeriod: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        boxSizing: "border-box",
        borderLeft: theme.border.main,
        height: 56,
        width: 78,
        minWidth: 78,
        maxWidth: 78
    },
    listItemBarScore: {
        display: "flex",
        minWidth: "32px",
        alignItems: "center",
        justifyContent: "flex-end"
    }
}));

type Props = {
    selectedCard: Card;
    itemData: ItemData;
    surveySections: SurveySection[];
    showBenchmarks: boolean;
    showPreviousPeriod: boolean;
    showOrgOverall: boolean;
    showDemographic: boolean;
    labelLength: number;
    snapShotDefinition: SnapShotDefinition;
};

const ShowENPS = (props: Props): ReactElement => {
    const {
        itemData,
        showBenchmarks,
        showDemographic,
        showOrgOverall,
        showPreviousPeriod,
        selectedCard,
        labelLength,
        snapShotDefinition
    } = props;

    const displayValues = itemData.scores.slice(0, 3);
    const colors = [unfavorableColor, neutralColor, favorableColor];
    const total = itemData.scores.slice(0, 3).reduce((a: number, b: number): number => a + b, 0);
    const ENPSScore = itemData.scores.slice(3)[0];

    const numberOfColumnsWithoutPreviousPeriod = [showDemographic, showBenchmarks, showOrgOverall].filter(
        Boolean
    ).length;
    const numberOfColumnsWithPreviousPeriod =
        [showDemographic, showBenchmarks, showOrgOverall].filter(Boolean).length + selectedCard.previousPeriod?.length;
    const numberOfColumns = showPreviousPeriod
        ? numberOfColumnsWithPreviousPeriod
        : numberOfColumnsWithoutPreviousPeriod;

    const stackbarWidth = 420 - (numberOfColumns - 1) * 10;
    const classes = useStyles({ stackbarWidth });

    if (!itemData.scores) return <></>;

    return (
        <div
            className={classes.listItem}
            key={itemData.itemId}
            data-testid={`pick-one-row-${itemData.title.split(" ").join("")}`}
        >
            <div className={classes.cellInfo}>
                <TruncateDisplay title={itemData.title} maxLabelLength={labelLength}>
                    <Typography variant="body1">{itemData.title} </Typography>
                </TruncateDisplay>
                <div className={classes.listItemBar}>
                    <StackedBar
                        values={displayValues}
                        colors={colors}
                        displayAllPercentage={true}
                        percentageFontSize={14}
                    />
                    <div className={classes.listItemBarScore}>
                        <Typography
                            display={"inline"}
                            fontSize={16}
                            fontWeight={500}
                            color={ENPSScore > 0 ? favorableColor : ENPSScore < 0 ? unfavorableColor : neutralColor}
                        >{`${ENPSScore > 0 ? "+" : ""}${ENPSScore}`}</Typography>
                    </div>
                </div>
            </div>

            {showDemographic && (
                <div className={`${classes.cellDemo} ${classes.cell}`}>
                    <CustomValueCell value={itemData.demographicScore} />
                </div>
            )}

            {showOrgOverall && (
                <div className={`${classes.cellOverall} ${classes.cell}`}>
                    <CustomValueCell value={itemData.overallScore} />
                </div>
            )}
            {showBenchmarks && itemData.scores && total > 0 && (
                <div className={`${classes.cellBenchmark} ${classes.cell}`}>
                    <CustomValueCell value={itemData.benchmarkScore} />
                </div>
            )}
            {showBenchmarks && !itemData.scores && total > 0 && (
                <div className={`${classes.cellBenchmark} ${classes.cell}`}>
                    <Typography>-</Typography>
                </div>
            )}
            {showBenchmarks && total <= 0 && (
                <div className={`${classes.cellBenchmark} ${classes.cell}`}>
                    <Typography>-</Typography>
                </div>
            )}
            {showPreviousPeriod && itemData.scores && (
                <div className={`${classes.cellPreviousPeriod}`}>
                    {snapShotDefinition.previousPeriodInfo.map((item, index) => (
                        <div key={index} className={`${classes.individualCellPreviousPeriod}`}>
                            <CustomValueCell value={itemData[item.previousPeriodMap]} />
                        </div>
                    ))}
                </div>
            )}
            {showPreviousPeriod && !itemData.scores && (
                <div className={`${classes.cellPreviousPeriod} ${classes.cell}`}>
                    <Typography>-</Typography>
                </div>
            )}
            {showPreviousPeriod && total <= 0 && (
                <div className={`${classes.cellPreviousPeriod} ${classes.cell}`}>
                    <Typography>-</Typography>
                </div>
            )}
        </div>
    );
};

export default ShowENPS;
