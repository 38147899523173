import { Translation } from "../interfaces";

export const spanishUI: Translation = {
    dashboard: "Tablero",
    surveys: "Encuestas",
    reports: "Informes",
    feedback: "Realimentación",
    actionPlanning: "Planificación de acciones",
    admin: "Administración",
    profile: "Perfil",
    settings: "Configuración",
    help: "ayuda",
    logout: "Cerrar sesión",
    home: "Hogar",
    contactUs: "Contacta con nosotras",
    loginError: "Error de inicio de sesión",
    loginErrorMsg: "Su cuenta ha sido inhabilitada. Póngase en contacto con nosotros en support@talentmap.com.",
    pageNotFound: "404 - Página no encontrada",
    sorryCantSeemPage: "Lo sentimos, parece que no podemos encontrar lo que está buscando.",
    pageNotExistOrRemoved: "La página no existe o se ha eliminado",
    goHomepage: "ir a la página de inicio",
    goalsChallenges: "Objetivos y desafíos",
    organizations: "Organizaciones",
    users: "Usuarios",
    adminSurveys: "Encuestas",
    noOptionsAvailable: "No hay opciones disponibles",
    refreshPage: "Actualizar la página",
    internalServerError: "500 - Error interno del servidor",
    somethingWentWrong: "Lo siento, algo salió mal",
    workingToFix: "Estamos trabajando para solucionar el problema",
    addOrganization: "agregar organización",
    import: "importar",
    addUser: "Agregar usuario",
    information: "Información",
    tags: "Etiquetas",
    deactivate: "desactivar",
    reactivate: "reactivar",
    name: "Nombre",
    yearEstablished: "año de Establecimiento",
    employeeCount: "Recuento de empleados",
    industry: "Industria",
    type: "Tipo",
    logo: "Logo",
    uploadLogo: "Subir logotipo",
    dragDropFilesHereOrClick: "Adjuntar archivo aquí",
    dropHere: "Suelta aquí",
    pngTransparent: "PNG con fondo transparente",
    errorMustUploadPNG: "Debe cargar un archivo PNG",
    cannotBeDroppedHere: "Este tipo de archivo no se puede soltar aquí",
    organizationHasBeenDeactivated: "La organización ha sido desactivada",
    organizationHasBeenReactivated: "La organización ha sido reactivada",
    general: "General",
    reportScales: " Informe de escalas",
    benchmarks: "Parámetros",
    dimensions: "Dimensiones",
    surveyNameCannotBeBlank: "El nombre de la encuesta no puede estar en blanco",
    orgNameCannotBeBlank: "El nombre de la organización no puede estar en blanco",
    cancel: "cancelar",
    save: "salvar",
    organizationSaveSuccessfully: "Organización guardada correctamente",
    organizationInformationLabel: "Información de la organización",
    noFileAttached: "No hay archivo adjunto",
    uploadSurvey: "Importar encuesta",
    upload: "Subir",
    noLogoUploaded: "No se ha subido ningún logotipo",
    deleteLogo: "Eliminar logotipo",
    numberOfRows: "Número de filas",
    goBack: "eliminar y empezar de nuevo",
    completeUpload: "Carga completa",
    uploadSurveyPreview: "Importar vista previa",
    noOrganizationSelected: "Ninguna organización seleccionada",
    deactivated: "desactivado",
    active: "activo",
    responses: "Respuestas",
    selectBeforeManagingSurveys: "Seleccionar antes de administrar encuestas",
    errorLoadingContactUs: "Se produjo un error durante la carga. Si este problema persiste, comuníquese con nosotros.",
    errorLoadingCSV: "Error al cargar el archivo CSV",
    errorMustUploadCSV: "Debes subir un archivo CSV",
    surveyFileCSV: "Archivo de encuesta (.csv)",
    responseFileCSV: "Archivo de respuesta (.csv)",
    scaleFileCSV: "Archivo de escala (.csv)",
    startDate: "Fecha de inicio",
    endDate: "Fecha de finalización",
    closeDate: "Fecha de finalización",
    required: "Requerido",
    surveyHasBeenDeactivated: "La encuesta ha sido desactivada",
    surveyHasBeenReactivated: "La encuesta se ha reactivado",
    errorUpdatingContactUs:
        "Se produjo un error durante la actualización. Si este problema persiste, comuníquese con nosotros.",
    dateCreated: "Fecha de creación",
    createdBy: "Creado por",
    orgChart: "Organigrama",
    yearEstablishedMustBeNumber: "El año establecido debe ser un número",
    yearEstablishedMustBe4Digits: "El año establecido debe tener 4 dígitos (por ejemplo, 1983)",
    minimumYearEstablished: "El año mínimo permitido es ",
    maximumYearEstablished: "El año máximo permitido es ",
    reportWillBeUnlockSurveyCreated: "Los informes se desbloquearán una vez que se haya creado una encuesta.",
    startSurveyClickSurveyOrUploadSurvey: `Sube una encuesta haciendo clic en "Administrador".`,
    reportWillBeUnlockReportViewer:
        "Los informes se desbloquearán una vez que el administrador del sitio haya cargado los datos de respuesta a la encuesta.",
    email: "Correo electrónico",
    noSurveyInformationSelected: "No se seleccionó información de la encuesta",
    selectSurveyInformation: "Seleccionar información de la encuesta",
    isCommentViewer: "Ver comentarios",
    canCreateSurveys: "Crear encuestas",
    powerPoint: "PowerPoint",
    excel: "Excel",
    live: "Tiempo real",
    responseRateReport: "Informe de tasa de respuesta",
    emptyData: "<Valor en blanco>",
    isSiteAdmin: "Administrador del sitio",
    userInformationLabel: "Información del usuario",
    source: "Fuente",
    export: "Exportar",
    noActiveUser: "Agregar un usuario",
    inviteUser: "Los usuarios agregados a la organización aparecerán aquí",
    noDeactivatedUSer: "No hay usuarios desactivados",
    usersRemovedFromOrg: "Los usuarios que se eliminan de la organización se colocan aquí.",
    isSurveyCreator: "Creador de encuestas",
    talentMapAdmin: "Administrador de TalentMap",
    role: "Rol",
    apply: "aplicar",
    newUserHasBeenCreated: "Se ha creado un nuevo usuario",
    userHasBeenDeactivated: "El usuario ha sido desactivado",
    userHasBeenReactivated: "El usuario ha sido reactivado",
    userNameCannotBeBlank: "El nombre de usuario no puede estar en blanco",
    userEmailCannotBeBlank: "El correo electrónico del usuario no puede estar en blanco",
    userEmailCannotBeInvalid: "El correo electrónico del usuario no puede ser inválido",
    userCreateError: "No se pudo crear el usuario, verifique su entrada o intente nuevamente más tarde",
    userEmailError: "Es posible que se haya utilizado esta dirección de correo electrónico",
    surveyNameMustBeUnique: "El nombre de la encuesta debe ser único",
    duplicatedLiveSurveyName: "Tiene una encuesta en vivo con nombres duplicados",
    unableSaveSurvey: "No se puede guardar la encuesta",
    organizationNameMustBeUnique: "El nombre de la organización debe ser único",
    addSurveyResult: "Agregar resultado de encuesta",
    campaign: "Campaña",
    resultOptimization: "Resultado de la encuesta de configuración",
    survey: "Encuesta",
    selectSurvey: "Seleccione una encuesta",
    campaignName: "Nombre",
    confirm: "Confirmar",
    results: "Resultados",
    create: "Crear",
    surveyGizmoLoadingError: "No se pueden cargar encuestas desde Survey Gizmo, intente actualizar la página",
    noSGCampaigns: "No hay campañas de disponibles",
    noSGSurveys: "No hay encuesta disponible",
    confidentialityThreshold: "confidencialidad Umbral",
    belowConfidentialityThreshold: "Por debajo del umbral de confidencialidad",
    noActiveOrganizations: "Sin organizaciones activas",
    noActiveOrganizationsMessage:
        'Haga clic en el botón "Agregar organización" en la parte superior para agregar una nueva organización.',
    noDeactivatedOrganizations: "Sin organizaciones desactivadas",
    noDeactivatedOrganizationsMessage: "Las organizaciones que están desactivadas se colocan aquí.",
    noResultsCreated: "No se crearon resultados",
    noResultsCreatedMessage:
        'Haga clic en el botón "Agregar resultado de la encuesta" en la parte superior para agregar un nuevo resultado.',
    noDeactivatedResults: "Sin resultados desactivadas",
    noDeactivatedResultsMessage: "Los resultados que están desactivados se colocan aquí.",
    permission: "Permiso",
    continue: "Seguir",
    surveyResults: "Resultados de la encuesta",
    search: "Buscar",
    switchOrganization: "Cambiar de organización",
    change: "cambiar",
    reportBreakdown: "Informe desglose",
    contactField: "Demográfico",
    surveyItem: "Elemento de encuesta",
    overallResponseRate: "Tasa de respuesta global",
    surveysSent: "Enviadas",
    surveysCompleted: "Completadas",
    responseRatePercentage: "Tasa de respuesta (%)",
    responseRate: "Tasa de respuesta",
    collapseAll: "Desplegar todo",
    expandAll: "Expandir todo",
    toSeeBreakdownReport:
        "Para ver un desglose del informe, seleccione un elemento del menú desplegable de la izquierda.",
    clickThePlusIconToAddMoreBreakdown: "Haga clic en el icono más para agregar más elementos al desglose del informe.",
    clickThePlusButtonToAddANewFilter: "Haga clic en el botón más para agregar un nuevo filtro.",
    automap: "Automap",
    selectBenchmark: "Seleccionar benchmark",
    campaigns: "Campañas",
    invalidConfidentialityThreshold: "Umbral de confidencialidad no válido",
    surveyResultName: "Nombre del resultado de la encuesta",
    compass: "Compass",
    imported: "Importado",
    canViewAllOrganizationReportData: "Ver todos los datos del informe de la organización",
    reportViewerWarning:
        "Al seleccionar el permiso, se anulará la asignación del usuario de las tarjetas que se le puedan asignar",
    none: "ninguno",
    noOrgChartAvailable: "No hay organigrama disponible",
    noOrgChartAvailableMsg: "En este momento, aún no se le ha asignado una tarjeta en el organigrama.",
    noOrgChartAvailableContact: "Comuníquese con su administrador para obtener ayuda.",
    filters: "Filtros",
    filter: "Filtrar",
    equalTo: "Igual a",
    notEqualTo: "No igual a",
    greaterThan: "Mas grande que",
    lessThan: "Menos que",
    and: "Y",
    or: "O",
    date: "Date",
    showBenchmarkInReports: "Mostrar comparativa en informes",
    confirmAutomapTitle: "¿Desea auto-mapear los puntos de referencia?",
    confirmAutomapMessage:
        "El auto-mapeo de los puntos de referencia borrará todos los puntos de referencia actualmente mapeados.",
    benchmarkMappingHelp: [
        "Al hacer clic en el botón Automap, los elementos de referencia se asignarán automáticamente a los elementos de su encuesta.",
        "Los elementos que no se hayan podido asignar automáticamente se indicarán con un fondo rojo.",
        "Los elementos no asignados se pueden asignar manualmente seleccionando la sección correspondiente y luego el elemento del punto de referencia."
    ],
    benchmarkMappingEmptyState: [
        "Para comenzar, seleccione un tipo de comparativa en el menú desplegable de arriba.",
        "Para obtener ayuda sobre cómo realizar el mapeo de referencia, consulte el icono de ayuda."
    ],
    noBenchmark: "Sin punto de referencia",
    switchBenchmark: "¿Cambiar Benchmark?",
    switchBenchmarkMsg:
        "Al cambiar el punto de referencia, se borrarán todos los puntos de referencia asignados actualmente.",
    customScale: "Elementos de la encuesta",
    defaultSettings: "Escalas predeterminadas",
    newFilter: "Filtro nuevo",
    clearFilters: "Borrar filtros",
    useSavedFilter: "Usar filtro guardado",
    saveFilter: "Guardar filtro",
    filterNotValid: "Filter not valid",
    nameMustBeUnique: "Name must be unique",
    nameCannotBeEmpty: "Name cannot be empty",
    dateResponseSubmitted: "Última respuesta",
    noFilterSaved: "No filter saved",
    noFilterAdded: "No filter added",
    customScaleHelp: [
        "Anular predeterminado: permite establecer escalas personalizadas para la pregunta usando los menús desplegables a continuación. ",
        " ",
        "Desde el menú desplegable de configuración: Incluir en informes - (predeterminado) La fecha de la pregunta se incluye en todos los aspectos de los informes.",
        " ",
        "Ignorar de los informes: ignora los datos de las preguntas de todos los informes.",
        " ",
        "Incluir en informes pero eliminar de la puntuación de dimensión general: la fecha de la pregunta seguirá apareciendo en los informes, pero no se incluirá en la puntuación general.",
        " ",
        `Ocultar a los lectores de informes restringidos - El usuario marca esta opción para ocultar la pregunta de los informes solo para los lectores de informes restringidos. La puntuación de la pregunta todavía se agrega a la puntuación general, pero el usuario no puede ver el desglose de los datos de esta pregunta.`
    ],
    defaultScaleCustomized: "Escala predeterminada personalizada:",
    defaultScaleUsed: "Escala predeterminada usada:",
    overrideDefault: "Invalidar por defecto",
    includeInReports: "Incluir en informes",
    removeFromReports: "Eliminar de informes",
    noOverallScore: "Incluir en informes pero eliminar de la puntuación de dimensión general",
    reportingOptions: "Opciones de informes",
    favorable: "Favourable",
    neutral: "Neutral",
    unfavorable: "Desfavourable",
    ignored: "ignorada",
    hideFromRestrictedReportViewer: "Ocultar a los lectores de informes restringidos",
    customizeIndividualQuestions: "Personalizar elementos individuales",
    useNominalScale: "Usar escala nominal",
    noData: "No hay puntaje de dimensión general disponible",
    totalResponses: "Respuestas totales",
    benchmark: "Punto de referencia",
    noSearchResults: "No se han encontrado resultados de búsqueda.",
    confidentialityWarning: "¡Advertencia de confidencialidad!",
    confidentialityWarningDescription:
        "Los filtros sleccionados tienen un resultado con un número de respuestas inferior al umbral de confidencialidad. Para preservar la confidencialidad de las respuestas, no podemos mostrar este informe.",
    confidentialityWarningDescriptionAllSubGroup:
        "Todos los grupos en esta diapositiva están por debajo del umbral de confidencialidad. Para preservar la confidencialidad de las respuestas, no podemos mostrar este informe.",
    previousPeriod: "Período Anterior",
    noPreviousPeriodMappingCreated: "No se creó ningún mapeo de período anterior",
    toContinueEnterInformationBelowAndAutomap: `Para continuar ingresa la información a continuación y haz clic en "Automapa"`,
    accessDenied: "Acceso denegado",
    userHasNoAccess: "No tienes acceso a esta página.",
    PleaseContactUsForLicense:
        "Póngase en contacto con nosotros en support@talentmap.com o con su administrador para obtener una licencia para acceder a esta página",
    previousSurvey: "Encuesta anterior",
    mappingLabel: "Etiqueta de mapeo",
    previousSurveyDescription: "Seleccione una encuesta anterior para asignarla al resultado de la encuesta actual.",
    mappingLabelDescription: [
        "Máximo 6 caracteres",
        "Esta etiqueta se mostrará en informes donde se utilizan períodos anteriores."
    ],
    currentSurvey: "Encuesta actual",
    mappingToSurvey: "Asignación a encuesta",
    showInReports: "Mostrar en informes",
    deleteMapping: "Eliminar asignación",
    mapTo: "Asigna a",
    remapGroupItems: "Reasignar elementos de grupo",
    clearMappings: "Asignaciones claras",
    clearMapping: "Asignaciones clara",
    editMapping: "Editar asignación",
    clickToAddNewMapping: "Haga clic en el botón más para agregar una nueva asignación.",
    deleteMappingMsg: "Esta acción eliminará permanentemente el mapeo.",
    delete: "Borrar",
    mapped: "Asignada",
    previousPeriodMapping: "Mapeo del período anterior",
    unableToRemapGroup: "Unable to remap group",
    unableToMapValue: "Unable to map value",
    unableToEditSurveyComparison: "Unable to edit survey comparison",
    unableToAutoMap: "Unable to automap",
    thisActionWillPermanentlyRemoveMapping: "Esta acción eliminará permanentemente la asignación.",
    items: "Elementos",
    contacts: "contactos",
    unableToDeleteSurveyComparison: "Unable to delete survey comparison",
    editItemMapping: "Editar asignación de elementos",
    responseRateEmptyMessage:
        "Para continuar, seleccione una encuesta en el menú desplegable que se encuentra en la parte superior izquierdo de la página",
    openSurveys: "Encuestas Abiertas",
    demographics: "Demografía",
    responseRateHelpText: [
        "Utilice el menú desplegable para desglosar la tasa de respuesta general por grupo demográfico. (Por ejemplo: vea la tasa de respuesta de cada departamento).",
        "Puede desglosar aún más el informe de tasa de respuesta haciendo clic en el botón más para agregar menús desplegables adicionales (por ejemplo: Ver la tasa de respuesta para cada grupo de edad dentro de un departamento)."
    ],
    responseRateEmptyBreakdownPageText: [
        "El filtro seleccionado no contiene respuestas de encuesta completadas.",
        "Actualice la selección del filtro."
    ],
    lastUpdated: "última vez actualizado: ",
    noSurveyCreated: "No Survey Created",
    responseRateNoSurveyCreateMessage: [
        "Reporting data will be available once a survey has been created.",
        "Please contact your administrator if you have any questions."
    ],
    keyDriversNoDisplay: `Key drivers cannot be displayed with less than 30 responses.`,
    snapshot: "Snapshot",
    overallScores: "Puntajes generales",
    snapshotReport: "Snapshot Informe",
    close: "Cerrar",
    next: "Siguiente",
    back: "Espalda",
    finish: "Terminar",
    reset: "Reiniciar",
    hierarchyName: "Nombre de organigrama",
    createHierarchy: "Crear organigrama",
    createPowerPoint: "Crear PowerPoint",
    configureCard: "Configurar Tarjeta",
    existingHierarchies: "Organigrama existente",
    availableFields: "Campos Disponibles",
    hierarchyFields: "Campos de organigrama",
    dragHereToAdd: "Arrastre aquí para agregar",
    fields: "campos.",
    stackedBarCharts: "gráficos de barras apiladas.",
    responseCount: "Recuento de Respuestas",
    childCount: "Recuento de Niños",
    exploreComments: "Explorar Comentarios",
    showCorrelations: "Mostrar Correlaciones",
    collaborator: "Colaborador",
    creator: "Creador",
    info: "Info",
    howTo: "Cómo",
    tasks: "Tareas",
    assign: "Asignar",
    status: "Estado",
    title: "Título",
    dueDate: "Fecha de Vencimiento",
    goals: "Metas",
    notes: "Notas",
    attachments: "archivos adjuntos",
    dropFile: "Suelta el archivo aquí ...",
    selectFile: "Arrastre y suelte el archivo aquí o haga clic para seleccionar el archivo",
    noActionAssociatedToCard: "No hay acciones asociadas a esta tarjeta",
    noActionSelected: "No hay acción seleccionada",
    surveyInformation: "Información de la Encuesta",
    organization: `Organización`,
    organizationTimeZone: "Zona horaria",
    surveySections: "Secciones de Encuesta",
    commentsEmptyMessageAdmin: "Seleccione la sección para ver la nube de palabras y los comentarios.",
    commentsEmptyMessageManager: "Seleccione la sección para ver la nube de palabras.",
    noTaskToAction: "No hay tarea asociada a esta acción",
    deleteThisItem: "¿Eliminar este elemento?",
    deleteCannotBeReversed: "Esta acción no se puede revertir y puede afectar a otros usuarios.",
    makeDefault: "Hacer por Defecto",
    yes: "Sí",
    no: "No",
    reassignUser:
        "El usuario actualmente está asignado a una tarjeta diferente y continuar esta acción los reasignará a esta tarjeta en su lugar.",
    highlightUnassignedCards: "Resaltar Tarjetas sin Asignar",
    language: "Idioma",
    show: `Mostrar`,
    peers: `Compañeros`,
    parent: `Padre`,
    selectAll: `Seleccionar todo`,
    deselectAll: `Deseleccionar todo`,
    new: `Nuevo`,
    action: `Acción`,
    task: `Tarea`,
    notStarted: `No Empezado`,
    inProgress: `En Progreso`,
    complete: `Completar`,
    default: `Defecto`,
    createHierarchyHelp: [
        `Arrastre los elementos de los Campos disponibles a los Campos de organigrama para construir la organigrama.`,
        `Para eliminar un elemento de la tarjeta, arrastre los elementos de la lista Campos de organigrama.`
    ],
    configureCardHelp: [
        `Arrastre elementos desde la columna izquierda a la tarjeta para crear el diseño de tarjeta predeterminado.`,
        `Para eliminar un elemento de la tarjeta, arrastre de la tarjeta a la columna izquierda.`,
        `El diseño de tarjeta predeterminado es el que usará y verá cualquier persona que inicie sesión en TalentGate por primera vez.`,
        `Establezca su diseño de tarjeta actual como predeterminado haciendo clic en el icono en la esquina superior derecha`,
        `y haciendo clic en "Establecer como predeterminado".`
    ],
    notAssigned: `No asignado`,
    duplicatedHierarchyName: `Ya existe una organigrama con este nombre.`,
    duplicatedActionName: `Ya hay una acción con este nombre.`,
    duplicatedTaskName: `Ya hay una tarea con este nombre.`,
    setAsDefault: `Establecer por defecto`,
    revertToDefault: `Vuelva al valor predeterminado`,
    clearCard: `Tarjeta clara`,
    more: `Más`,
    defaultCardLayoutNotSet: `Diseño de tarjeta predeterminado no establecido`,
    hierarchyOutOfDate: `La organigrama esta desactualizada`,
    currentHierarchyOutOfDate: `La organigrama actual está desactualizada`,
    keyDriver: `Factor clave`,
    keyDrivers: `Factores Clave`,
    allStepsCompleted: `Todos los pasos completados`,
    correlations: `Correlaciones`,
    topCorrelations: `Principales Correlaciones`,
    comments: `Comentarios`,
    commentsCaps: `COMENTARIOS`,
    wordCloud: `Nube de Palabras`,
    wordCloudCaps: `NUBE DE PALABRAS`,
    description: `Descripción`,
    noTaskDescription: `No hay descripción para esta tarea`,
    commentsDialogAdminHelp: [
        `La búsqueda mostrará los comentarios que coinciden`,
        `con la palabra o palabras ingresadas.`,
        ` `,
        `Como resultado, la nube de palabras mostrará más`,
        `palabras utilizadas dentro de los resultados de búsqueda.`
    ],
    commentsDialogManagerHelp: [
        `Esto buscará comentarios para la palabra o palabras`,
        `ingresó. Esto cambiará la nube de palabras,`,
        `mostrando las palabras más utilizadas de los comentarios`,
        `que están asociadas a la búsqueda.`
    ],

    noStatusShown: `No hay estado que mostrar para esta acción`,
    managerComparisons: `Comparaciones de Gerentes`,
    immediateSuperior: `Superior inmediato`,
    notificationSettings: `Configuración de las notificaciones`,
    turnOnNotification: `Activar las notificaciones`,
    managerComparisonsHelp: `Los gerentes solo pueden ver la sección de la organigrama que tienen asignada. Esto les permite ver otras tarjetas en la organigrama para que puedan compararse con la organización en general, su superior inmediato y sus pares dentro de su equipo.`,
    notificationSettingsHelp: [
        `Los correos electrónicos de notificación solo se envían a los usuarios cuando tienen una nueva notificación.`,
        `Nuevas notificaciones incluyen:`,
        `• Asignación a una tarjeta`,
        `• La acción se comparte en una tarjeta a la que está asignado`,
        `Los correos electrónicos se envían una vez al día a las 9:00 a.m.`
    ],
    notificationWarming: `El correo electrónico de notificación será enviado a las 9:00 AM (Hora del este en los Estados Unidos) todos los días.`,
    dueDateExpired: `Fecha de vencimiento vencida`,
    correlationHelp: [
        `Una correlación es una medida estadística de la fuerza de la relación entre dos dimensiones.`,
        ` `,
        `Cuanto más oscuro es el color del cuadrado, más fuerte es la correlación entre esas dos dimensiones.`
    ],
    actionReport: `Informe de acción`,
    customScales: `Escalas personalizadas`,
    noDefaultScale: "No hay un conjunto de escalas predeterminado",
    noQuestionToCustomScale: "No hay preguntas para personalizar la escala",
    noScaleNorQuestionSelected: `No hay escala ni pregunta seleccionada`,
    cantDropFile: `Este tipo de archivo no se puede soltar aquí`,
    fileNotUploaded: `El archivo no se cargó, intente nuevamente`,
    files: `Archivos`,
    groupStatus: `Estado del grupo`,
    cardStatus: `Estado de la tarjeta`,
    personal: "Personal",
    shared: "Shared",
    cannotSaveNoValidFilter: "No se puede guardar si el filtro no es válido",
    duplicatedFilterName: `Ya existe un filtro con este nombre.`,
    ignoreQuestion: "Ignorar pregunta",
    hideForManager: "Ocultar para administradores",
    noFilesEmptyStateMessage: `No hay archivos adjuntos/`,
    turnOnReportFilters: `Activar filtros de informes para gerentes`,
    clearFilterCondition: "Borrar condiciones de filtro",
    loadFilter: "Filtro de carga",
    addNewCondition: "Agregar nueva condición de filtro",
    clearCondition: " Condición clara",
    doYouWantToProceed: "¿Desea continuar?",
    thisActionWillChangeLayout: "Esta acción actualizará el diseño de las jerarquías existentes",
    benchmarkMapping: "Mapeo de referencia",
    selectBenchmarkSection: "Seleccione la sección",
    selectBenchmarkQuestion: "Seleccionar pregunta",
    selectFieldsFilter: "Seleccionar todos los campos que deberían aparecer en los filtros",
    hiddenQuestionWillRemovedDefaultLayout:
        "Ocultar pregunta para los gerentes actualizará el diseño predeterminado. Si está utilizando el diseño predeterminado, actualizará su gráfico",
    cannotSetDefaultLayoutWithHiddenQuestion:
        "El diseño predeterminado no se puede establecer con una pregunta oculta para los administradores",
    cannotSetDefaultLayoutWithHiddenSection:
        "El diseño predeterminado no se puede establecer cuando todas las preguntas de una sección se ignoran u ocultan para los administradores",
    displayKeyDriversIsSetOff: "Mostrar controladores clave para gerentes se activa en Configuración general",
    keyDriverCannotBePartDefaultLayoutEngagementHidden:
        "Los controladores clave no pueden formar parte del diseño predeterminado si la sección ENGAGEMENT está oculta o ignorada",
    warning: "Advertencia",
    reportFilters: "Filtros de informes",
    displayKeyDriversForManagers: "Mostrar controladores clave para gerentes",
    keyDriversWillBeRemovedDefaultLayout: "Los controladores clave se eliminarán del diseño predeterminado",
    noGoals: "No se han ingresado goles.",
    disallowedFilterParagraphs: [
        "Los filtros seleccionados producen un informe que difiere en el mismo número o menos de respuestas que el umbral de confidencialidad establecido de un informe reciente. Para preservar la confidencialidad de las respuestas, no podemos mostrar este informe.",
        "Para preservar la confidencialidad de las respuestas individuales, TalentMap realiza un seguimiento de los resultados de los informes realizados por cada usuario. Si el conjunto de personas en un informe determinado es demasiado similar al conjunto de personas en un informe reciente, entonces puede ser posible ver si una persona determinada respondió positiva o negativamente a una encuesta. Cuando esto ocurra, bloquearemos este informe para que no se le muestre a fin de garantizar la privacidad de los encuestados.",
        "Por ejemplo, filtrar los resultados para ver solo el resultado de hombres o mujeres generalmente es algo legítimo, ya que a menudo tendrán diferentes respuestas sobre cosas como el equilibrio entre el trabajo y la vida. Pero si un departamento específico tiene solo una mujer y los resultados se filtran para incluir solo a hombres, el efecto es eliminar a una sola persona de este informe. En ese caso, puede identificar fácilmente a la persona que fue eliminada y, si los resultados son más positivos, sabrá que respondió negativamente a la encuesta.",
        "El sistema realiza un seguimiento de los últimos cinco informes que ejecutó cada usuario, y el resultado de cada nuevo filtro debe diferir en al menos el número de umbral de confidencialidad establecido de encuestados de uno de esos informes recientes para que se devuelva."
    ],
    noDescription: "Sin descripción",
    infoNotes: "Notas",
    noOverallAvailable: "No disponible en general",
    displayBenchmarksInCard: "Mostrar puntos de referencia en la tarjeta",
    cantDisplayBenchmark: "El punto de referencia no se puede mostrar hasta que se mapeen todos los elementos",
    exportingSnapshotPowerPoint: "Exportación de informe de instantánea de PowerPoint",
    thereIsNoFilterSaved: "No hay ningún filtro guardado",
    editHierarchyName: "Editar nombre de jerarquía",
    actionPlanningTemplate: "Plantilla de planificación de acciones",
    editCardName: "Editar nombre de la tarjeta",
    cardName: "Nombre de la tarjeta",
    taskName: "Nombre de la tarea",
    filterIsNotValid: "Al menos un filtro no es válido",
    order: "Orden",
    published: "Publicado",
    keyDriverHelp:
        "Los impulsores clave son las áreas más importantes en las que el análisis encontró que afectaba la participación.",

    slide: {
        snapshotReport: {
            infoTitle: "Comprensión de su informe",
            infoText: {
                first: "A menos que se indique lo contrario, la puntuación de dimensión general es la media (promedio) de todos los elementos, excluidos los elementos personalizados",
                second: "El% de puntuación favorable de su organización es mayor (+) o menor (-) que el% de puntuación favorable del índice de referencia para ese elemento",
                third: "Indica que la referencia del artículo no está disponible para la comparación.",
                fourth: "El% de puntuación favorable del período actual de su organización es mayor (+) o menor (-) que el% de puntuación favorable del período de su organización para ese elemento."
            },
            overallTitle: "Puntuaciones generales",
            filterTitle: "Filtros de informe aplicados",
            endTitle: "Nuestra confidencialidad",
            disclaimer: "No se proporcionarán datos o informes a menos que haya al menos cinco (5) encuestadoss"
        }
    },
    organizationLogo: "Logotipo de la organización",
    dialogUploadLogo: {
        controls: {
            labelUploadLogo: "Subir logotipo",
            helperUploadFileType: "PNG o SVG con fondo transparente",
            helperUploadNoLogo: "No se ha subido ningún logotipod"
        },
        actions: {
            deleteLogo: "Eliminar logotipo"
        }
    },
    exportPowerPoint: "Exportar a PowerPoint",
    dialogExportSlide: {
        title: "Exportar a PowerPoint",
        controls: {
            labelMainTitle: "Título",
            labelSubTitle: "Subtitular",
            helperMainTitle: "Aparece en la página de título y como encabezados de página de informe",
            helperSubTitle: "Solo aparecerá en la página de título",
            errorMainTitle: "Campo obligatorio"
        }
    },
    errorMessage: {
        fileErrorNoMultiFiles: "Solo se permite un archivo",
        fileErrorFormatPNGorSVG: "El archivo debe ser uno de los siguientes: PNG o SVG"
    },
    thankYou: "Gracias",
    layoutCardLimitDrop: "La tarjeta puede tener hasta 7 gráficos de barras (incluido el controlador clave)",
    dragChartHere: "Arrastre aquí para agregar un controlador clave o gráficos de barras",
    dragResponseRateHere: "Tasa de respuesta",
    responseNATooltip: "Para proteger la privacidad, cuando haya menos de 5 respuestas, ocultaremos los datos. ",
    errorLoadingOrgChart:
        "Se produjo un error al cargar el organigrama. Vuelva a cargar la página o seleccione una jerarquía diferente",
    engagement: "compromiso",
    noSurveyResultSelected: "No se seleccionó ningún resultado de encuesta",
    noSurveyResultSelectedMsg:
        "Para ver el organigrama, seleccione el resultado de una encuesta del menú desplegable en la parte superior izquierdo de la página.",
    openMenu: "Menú abierto",
    exportToPdf: "Exportar a PDF",
    exportToExcel: "Exportar a Excel",
    heatmap: "Heatmap",
    unableToFetchPDF: "No se puede recuperar PDF",
    unableToFetchPPTX: "No se puede recuperar PowerPoint",
    heatmapReport: "Heatmap Informe",
    compareTo: "Comparar con",
    cellContent: "Contenido de la celda",
    noDemographicSelected: "No se seleccionó ningún grupo demográfico",
    noDemographicSelectedMsg:
        "Para ver el mapa de calor, seleccione un grupo demográfico del menú desplegable de la izquierda.",
    difference: "Diferencia",
    overall: "Organización General",
    overallFiltered: "Resultados Filtrados",
    noMeaningfulInfo: "No podemos mostrarte algo significativo aquí.",
    noDemographicInfo: "No hay información demográfica en la encuesta seleccionada.",
    noDemographicInfoMeg: "Heatmap no está disponible para esta encuesta, seleccione otra encuesta.",
    item: "Item",
    demographicValue: "Valor demográfico",
    snapshotExportMsg: [
        "Exportando archivo .pptx...",
        "Se completó la exportación del archivo .pptx, verifique su carpeta de descarga."
    ],
    comparisons: "Comparaciones",
    theAbilitytoCompareToOverall:
        "La capacidad de comparar con la organización en general solo está disponible cuando se filtra el informe.",
    compareGroup: "Grupo de comparación",
    heatmapMesg: "Los datos se redondean al número entero más cercano.",
    snapshotCompareConfidentialityMsg:
        "El grupo con el que se está comparando está por debajo del umbral de confidencialidad.",
    switchAxis: "Eje de conmutación",
    heatmapWarningMesg:
        "Los grupos inferiores al umbral mínimo no se muestran. Consulte Informe de tasa de respuesta para obtener más detalles.",
    between: "entre",
    commentReport: "Informe de Comentarios",
    totalComments: "Respuestas totales",
    resultsTooSimilar: "Resultados demasiado similares",
    clearAll: "BORRAR TODOS",
    addLookdown: "Añadir diapositivas de búsqueda",
    lookdownTooltip:
        "Las diapositivas de búsqueda comparan los subgrupos con el grupo general. Estas diapositivas se colocan encima de la diapositiva Puntajes generales de las dimensiones en el orden en que se crearon.",
    clickThePlusButtonToAddANewLookdown: "Haga clic en el botón más para agregar una diapositiva de vista hacia abajo",
    surveyDimensionOrItem: "Dimensión o elemento de la encuesta",
    allComments: "Todos los comentarios",
    demographicsLabels: "Etiquetas demográficas",
    originalDemographicsLabel: "Etiqueta original",
    demographicsLabelDataExample: "Ejemplo de datos",
    demographicsReportLabels: "Etiqueta de informe",
    demographicLabelTooltip:
        "Las etiquetas de los informes aparecerán en los filtros, las comparaciones, los mapas de calor, las exportaciones de informes, el mapeo del período anterior y el cuadro de diálogo de exportación de instantáneas.",
    reportLabelMustBeUnique: "La etiqueta del informe ya existe. Por favor actualice.",
    reportLabelCannotBeBlank: "La etiqueta del informe no puede estar en blanco.",
    loading: "Cargando...",
    clickThePlusButtonToAddANewHeatmapLookdown:
        "Haga clic en el botón más para agregar una diapositiva de mapa de calor",
    addHeatmapLookdown: "Añadir diapositivas de mapas de calor",
    heatmapWarningPrefix: "ADVERTENCIA DE DIAPOSITIVA DE MAPA DE CALOR",
    heatmapWarningSuffix: "tiene demasiados valores seleccionados y puede expandirse fuera de la diapositiva",
    columnLabel: "Etiqueta de columna",
    columnLabelHelperText: "Máximo 6 caracteres",
    columnLabelErrorText: "Limite la etiqueta a 6 caracteres",
    value: "Valor",
    scales: "ESCAMAS",
    fieldsTab: "CAMPOS",
    convertSurveyItemHelper:
        "Convierta un campo de elemento de encuesta (pregunta) en un campo demográfico que se pueda filtrar en informes de instantáneas, mapas de calor y comentarios.",
    reportLabel: "Etiqueta de informe",
    addToDemographics: "Agregar a datos demográficos",
    english: "Inglés",
    french: "Francés",
    spanish: "Español",
    portuguese: "Portugués",
    addResponseRateLookdown: "Añadir diapositivas de tasa de respuesta",
    clickThePlusButtonToAddANewRRLookdown:
        "Haga clic en el botón más para agregar una diapositiva de Tasa de respuesta",
    properties: "Propiedades",
    showInFirstReportColumn: "Mostrar en la primera columna del informe",
    addSecondMapping: "Agregar segundo período",
    labelExists: "La etiqueta de asignación existe",
    addKeyDriversLookdown: "Agregar diapositiva de controladores clave",
    keyDriverslookdownTooltip:
        "Los impulsores clave solo se pueden generar si hay 30 o más encuestados. Los controladores clave aparecerán en una sola diapositiva. Agregar un máximo de 3 (tres) controladores clave.",
    clickThePlusButtonToAddANewKeyDriversLookdown:
        "Haga clic en el botón más para agregar una diapositiva de Controladores clave",
    responseCountWarning:
        "El número mínimo de encuestados requerido para generar un análisis de impulsores clave es 30",
    columnLabelHelperText24: "Máximo 30 caracteres",
    columnLabelErrorText24: "Limite la etiqueta a 30 caracteres",
    noItemAvailable: "No hay puntaje de artículo disponible",
    correlationReport: "Correlaciones Reporte",
    correlationHelpText: [
        `Una correlación es una medida estadística de la fuerza de la relación entre dos dimensiones`,
        ` `,
        `o elementos. Cuanto más oscuro es el color del cuadrado, más fuerte es la correlación.`
    ],
    notEnoughCorrelationResponse: "Ejecutar un informe de correlación requiere al menos 30 o más respuestas.",
    benchmarkTags: "Etiquetas de referencia",
    addTag: "Añadir etiqueta",
    deleteTag: "Eliminar etiqueta",
    createNewTag: "Crear nueva etiqueta",
    thisActionWillPermanentlyRemoveTag:
        "Esta acción no se puede revertir. La etiqueta se eliminará de todas las organizaciones, encuestas y puntos de referencia creados anteriormente. Los datos de los puntos de referencia que ya se crearon no se modificarán.",
    createdTags: "Etiquetas creadas",
    duplicateTag: "La etiqueta ya existe.",
    benchmarkInformation: "Etiquetas de referencia",
    createNewBenchmark: "Crear nuevo punto de referencia",
    englishTitle: "Título en inglés",
    frenchTitle: "Título francés",
    numberOfOrganizations: "# de Organizaciones",
    numberOfSurveys: "# de encuestas",
    numberOfResponses: "# de respuestas",
    archived: "archivada",
    activate: "Activar",
    archive: "archivo",
    duplicateBenchmarkName: "Nombre de referencia duplicado",
    hideBenchmarkData: "Ocultar datos de referencia",
    hidePPData: "Ocultar datos del período anterior",
    removeFilterSimilarityWarning: "Eliminar advertencia de similitud de filtro",
    canViewAllOrganizationReportDataTooltip:
        "Al marcar esta casilla, el usuario podrá ver todos los datos del informe de cada encuesta. Si lo deja sin marcar, podrá asignar el usuario a una tarjeta en el organigrama. Para obtener más información, haga clic en el signo de interrogación.",
    removeFilterSimilarityWarningTooltip:
        "Al marcar esta casilla, el usuario podrá ejecutar filtros en pequeños grupos demográficos. Para obtener más información, haga clic en el signo de interrogación.",
    clearBenchmarkMappingTitle: "¿Borrar mapeo de puntos de referencia?",
    clearBenchmarkMapping:
        "Borrar el punto de referencia eliminará todos los datos de punto de referencia del resultado de esta encuesta.",
    dimensionsAndItems: "Dimensiones & Artículos",
    commentCount: "Recuento de comentarios",
    wordCloudSettings: "Configuración de la nube de palabras",
    ok: "ok",
    number: "Número",
    showTopWordsPrefix: "Mostrar arriba",
    showTopWordsSuffix: "palabras",
    invalidTopWordsNumber: "Número invalido",
    maximumTopWordNumber: "Máximo 50",
    importStopWords: "Archivo de Palabras Vacías (.csv)",
    stopWordsTooltip:
        "Importe palabras vacías con cada palabra en una sola fila usando un archivo .csv. Para obtener más información, haga clic en el signo de interrogación.",
    stopWordsErrorMustUploadCSV: "Cargue un archivo CSV",
    stopWords: "Para las palabras",
    stopWordExist: "La palabra de parada ya existe",
    duplicateStopWords: "Palabras vacías duplicadas en el archivo csv",
    resetWordCloudTitle: "¿Restablecer nube de palabras?",
    resetWordCloudMessage: "Esta acción no se puede revertir y puede afectar a otras usuarios.",
    commentSettings: "Configuración de Comentarios",
    preferences: "Preferencias",
    hiddenWordsAndPhrases: "Palabras/frases ocultas",
    showCommentMinWordsPrefix: "Mostrar mínimo",
    showCommentMinWordsSuffix: "palabras",
    importHiddenWords: "Archivo de Palabras/Frases Ocultas (.csv)",
    hiddenWordsTooltip:
        "Importe palabras, nombres, frases, etc. con cada palabra/frase en una sola fila usando un archivo .csv. Para obtener más información, haga clic en el signo de interrogación.",
    hiddenWordExist: "La palabra/frase oculta ya existe",
    resetHiddenWordTitle: "¿Restablecer palabras ocultas?",
    showCommentRandomSamplePrefix: "Mostrar muestra aleatoria",
    showCommentRandomSampleSuffix: "comentarios por artículo",
    minmumTopWordNumber: "Mínima 1",
    commentSamplePrefix: "Ejemplo de Comentario - mostrando",
    of: "de",
    commentSampleSuffix: "comentarios totales",
    words: "palabras",
    minimum: "Mínima",
    randomSample: "Muestra aleatoria",
    commentsLowerCase: "comentarios",
    maximumOneWord: "Máximo 1 palabra",
    dimensionScore: "Puntuación de dimensión",
    dimsionScoreThreshold: "Umbral 1-5. No más de 0,2 decimales",
    maximumFive: "Máxima 5",
    minimumOne: "Mínima 1",
    invalidNumber: "Número invalido",
    showDimensionScore: "Mostrar puntaje de dimensión",
    dimensionScoreHighToLow: "Dimensión - de mayor a menor",
    dimensionScoreLowToHigh: "Dimensión - de menor a mayor",
    noneCapitalize: "Ninguna",
    sort: "Ordenar",
    dimsionScoreFavorable: "Favorable ≥ que",
    dimsionScoreUnfavorable: "Desfavorable ≤ que",
    dimensionScoreTooltip:
        "Establezca un umbral para ver los resultados por puntaje de dimensión. Las puntuaciones mayores que/menores que no pueden superponerse. Para obtener más información, haga clic en el signo de interrogación.",
    dimsionScoreCommentSettingsThreshold:
        "Modificar umbral de puntos de colores por dimensión. Las puntuaciones mayores que/menores que no pueden superponerse. Para obtener más información, haga clic en el signo de interrogación.",
    dimsionColorThreshold: "Umbral de color de dimensión",
    showSentimentScore: "Mostrar confianza en el sentimiento",
    sentimentScoreHighToLow: "Sentimiento - de mayor a menor",
    sentimentScoreLowToHigh: "Sentimiento - de menor a mayor",
    sentimentScoreCalculationInProgress:
        "Obtención de confianza en los comentarios. Esto puede tardar varios minutos en completarse.",
    negativeSentiment: "Sentimiento negativo",
    positiveSentiment: "Sentimiento positivo",
    neutralSentiment: "Sentimiento neutral",
    score: "confianza",
    overallSentiment: "Sentimiento General",
    positive: "Positiva",
    negative: "Negativa",
    sentimentScore: "Confianza de Sentimiento",
    autoTranslate: "Traducción automática",
    sentimentScoreHeader: "Rango de Porcentaje",
    sentimentScoreTooltip:
        "Establecer el rango de porcentaje de confianza del sentimiento. Para obtener más información, haga clic en el signo de interrogación.",
    passwordReset: "Correo electrónico enviado con éxito",
    passwordResetError: "Error al restablecer la contraseña.",
    resetPassWord: "Restablecer contraseña",
    assignAccess: "Asignar Acceso",
    assignRestrictedReportViewers: "Asignar visores de informes restringidos",
    noRestrictedUser: "No Se Han Creado Visores de Informes Restringidos",
    filtersUpperCase: "FILTROS",
    filterLabel: "Etiqueta de Filtro",
    applyFilter: "Aplicar Filtro",
    autoTranslationCalculationInProgress:
        "Traducción automática en curso. Esto puede tardar varios minutos en completarse.",
    addusersToStart: "Para agregar un usuario, vaya a Admin y luego a Usuarios",
    noReportDataAvailable: "No Hay Datos de Informe Disponibles",
    pleaseContactAdministrator: [
        "Todavía no se le ha asignado ningún informe.",
        "Póngase en contacto con su administrador para obtener ayuda."
    ],
    dashboardLayoutOptions: "Diseñador de Paneles",
    dashboardTooltip:
        "Agregue tipos de informes al editor de diseño haciendo clic en un icono de lápiz. Para obtener más información, haga clic en el signo de interrogación.",
    preview: "Vista previa",
    configurePane: "Configurar Panel de Control",
    image: "Imagen",
    pane: "Panel",
    addPNGImage: "Agregar Imagen PNG",
    deleteDashboardConfiguration: "¿Borrar la configuración del panel?",
    dashboardTitle: "Título del Tablero",
    spanishTitle: "Título español",
    singleDimensionOrItem: "Sola Dimensión o Artículo",
    label: "La etiqueta",
    columnLabelErrorText50: "Limite la etiqueta a 50 caracteres",
    columnLabelHelperText50: "Máximo 50 caracteres",
    singleScore: "única Puntuación",
    beingUsed: "se utiliza",
    noDashboardcreated: "No se ha creado ningún panel de control para este informe",
    column: "Columna",
    row: "Fila",
    size: "Talla",
    goToSnapshotReport: "Ir a Informe de instantáneas",
    overallDimension: "Dimensión Global",
    hidePriveousPeriodData: "Ocultar datos del período anterior",
    keyDriverOf: "Factores Clave",
    dimension: "Dimensión",
    impact: "Impacto",
    belowKeyDriverThreshold:
        "El número mínimo de encuestados requerido para generar el análisis del controlador clave es de 30.",
    review: "revisar",
    singleDimensionWithItems: "Sola Dimension con Artículos",
    lookdown: "Vigilancia",
    excludedDimensions: "Dimensiones Excluidas",
    overallDimensionScore: "Global Dimension Scores",
    bm: "BM",
    map: "Asignada",
    permissions: "permisos",
    bulkExport: "Exportación Múltiple",
    isBulkReportingEnabled: "Ejecutar informes a granel",
    dashboardOverall: "General",
    count: "Conde",
    tooManyRowsWarning: "Hay demasiadas filas de datos para mostrar esta table correctamente.",
    bulkExportPowerPoint: "Exportación a granel a PowerPoint",
    bulkStatus: "Granel Estado",
    bulkProgress: "Informe de exportación a granel",
    startSnapshotBulkExport: "Informe Snapshot a granel en cola. Comprobar el estado en la página de descargas.",
    reportQueued: "Informe en cola",
    queuePositions: "Posición de cola",
    reportGenerating: "Generar informes",
    reportCompleted: "Informe completo",
    report: "Informe",
    showQuestionnaireItemOnly: "Mostrar solo los artículos del cuestionario",
    startDownloading: "Descargar el informe",
    favourableUnfavourableItems: "Favourable / Unfavourable Items",
    bulkReportDemographics: "Informe Demográfico",
    startHeatmapBulkExport: "Informe heatmap a granel en cola. Comprobar el estado en la página de descargas.",
    startCommentBulkExport: "Informe de comentarios masivos en cola. Comprobar el estado en la página de descargas.",
    startCorrelationBulkExport: "Informe correlación a granel en cola. Comprobar el estado en la página de descargas.",
    startDashboardBulkExport: "Informe tablero a granel en cola. Comprobar el estado en la página de descargas.",
    bulk: "Grueso",
    bulkExportExcel: "Exportación a granel a Excel",
    bulkExportPDF: "Exportación a granel a PDF",
    queued: "En Cola,",
    errored: "Errores",
    completed: "Completado",
    noQueuedBulkExports: "Exportaciones a granel sin cola",
    noErroredBulkExports: "No hay exportaciones a granel erróneas",
    noCompletedBulkExports: "Ninguna exportación completa a granel",
    deleteBulkExport: "Eliminar exportación en bloque",
    deleteCannotBeReversedForBulk: "Esta acción no se puede revertir",
    deleteAllBulkExport: "Eliminar todas las exportaciones a granel completadas",
    deleteCompleted: "Eliminar completado",
    requestedTime: "Tiempo Solicitado",
    completedTime: "Tiempo completo",
    errorInfo: "Información del Error",
    overallSS: "General",
    removeOverallComment: "Eliminar Comentarios Generales Diapositiva",
    insights: "Información",
    outcome: "Resultado",
    keyItems: "Elementos Clave",
    keyItemBelow30: "Este informe requiere al menos 30 o más respuestas.",
    percentage: "porcentaje",
    showInHeatmapComparison: "Mostrar en Heatmap Comparaciones",
    isInsightPageEnabled: "Ver la Página de Insights",
    commentSample: "Observaciones relacionadas con este tema",
    loadingComments: "Cargar comentarios. Esto puede tardar varios minutos en completarse.",
    noCommentInInsightPage: "Esta encuesta no tiene comentarios textuales.",
    participants: "Participantes",
    open: "Abrir",
    closed: "Cerrado",
    noOpenSurvey: "No hay encuestas abiertas en su cuenta",
    noClosedSurvey: "No hay encuestas cerradas en su cuenta",
    uploadCSVFile: "Importar Archivo CSV",
    statusReport: "Informe de Situación",
    NumberOfParticipantsUpdated: "Número de Participantes Actualizados",
    missingColumnHeader: "Cabecera(s) de Columna Faltante",
    invalidEmails: "Email(s) no Válido",
    copyToClipborad: "Copiar al portapapeles",
    missingEmailField: "Campo de Correo Electrónico Perdido",
    missingEmailValue: "Hay una celda en blanco en la columna de correo electrónico",
    clickImportToAddContacts: "Haga clic en importar para agregar participantes",
    page: "Página",
    confirmDelete: "Confirmar Eliminación",
    deletingParticipant: "La eliminación de un participante no se puede revertir.",
    uploadStatus: "Estado de Subida",
    loadingParticipants: "Carga de participantes",
    duplicateEmailField: "Campo de correo electrónico duplicado",
    uploadMayTakesTime: [
        "La carga puede tardar varios minutos en completarse.",
        "Haga clic en la página de inicio para continuar usando Compass"
    ],
    activityLog: "Registro de actividad",
    createdTime: "Fecha de Creación",
    lastResponseSubmittedTime: "Última Respuesta",
    user: "Usuario",
    fieldAdded: "Campo demográfico añadido",
    fieldRemoved: "Demografía Campo eliminado",
    participantCreated: "Participantes Crearon",
    participantUpdated: "Participantes Actualizado",
    participantDeleted: "Participantes Suprimido",
    noActivityLog: "Sin actividad del usuario",
    andMore: "y más",
    initialImport: "Importación Inicial",
    saveSuccessfully: "Guardado Correctamente",
    isParticipantEnabled: "Ver página de participantes",
    lockPPAndBenchmark: "Bloquear Benchmark y Mapeo de Periodo Anterior",
    confirmImport: "Confirmar Importación",
    confirmImportParticipants: [
        "Asegúrese de que el archivo es correcto. La carga incorrecta de archivos puede resultar en la pérdida de respuestas a la encuesta.",
        "Si se hacen cambios en un encabezado(s) de etiqueta de columna, los datos del período anterior deberán ser remapeados para la columna(s) afectada."
    ],
    lockMessagePrefix: "Póngase en contacto con su gestor de proyectos de TalentMap o con ",
    lockMessageSuffix: " para desbloquear esta asignación de encuesta.",
    opportunity: "Oportunidad",
    strength: "Fuerza",
    noneUpperCase: "Ninguno",
    summary: "Resumen",
    commonTopicsFrom: "Temas comunes De",
    loadingCommentsSummary:
        "Cargar resumen de comentarios y sugerencias. Esto puede tardar varios minutos en completarse.",
    commonSuggestionFrom: "Posibles sugerencias para mejorar",
    uploadContactsError: "Hubo un error al subir participantes. Por favor, póngase en contacto con ",
    summarySettings: "Ajustes de resumen",
    summaryPrompt: "Sumario Inmediato",
    suggestionPrompt: "Sugerencias Inmediato",
    confirmEditPrompt: "Confirmar Promp Editar",
    editPromptMessage:
        "La edición de un aviso cambiará el resumen y el texto / o sugerencias para todos los usuarios que tienen acceso a los comentarios.",
    aiInsights: "Conocimientos de IA",
    updateEmailField: "El sistema no permitirá actualizaciones al encabezado de columna de correo electrónico",
    bulkPPT: "GRUESO PPT",
    bulkEXCEL: "GRUESO EXCEL",
    bulkPDF: "GRUESO PDF",
    bulkstatus: "GRUESO ESTADO",
    powerpoint: "POWERPOINT",
    pdf: "PDF",
    anyWord: "Cualquier Palabra",
    exactMatch: "Texto Exacto",
    semanticSearch: "Significado",
    ask: "PREGUNTAR",
    loadingCommentsAsk: "La generación de respuesta...",
    commentAskAnswer: "Obtenga respuestas generadas por IA a preguntas sobre ",
    typeYourQuestion: "Escriba su pregunta aquí",
    confirmNewCommentConversation: "Todo el texto será eliminado.",
    tour: "Visitas",
    clear: "Claro",
    clearConversation: "Borrar conversación",
    benchmarkData: "Referencia",
    pPData: "Período Anterior",
    other: "Otro",
    reportError: "Se produjo un error",
    pleaseContact: "Ponte en contacto con",
    group: "Grupo",
    siteAdmin: "Administrador del Sitio",
    restrictedReportViewer: "Visor de Informes Restringido",
    reportViewer: "Visor de Informes",
    never: "Nunca",
    lastAccess: "Último Acceso",
    itemBelowConfidentialityThreshold: "Artículo por debajo del umbral de confidencialidad",
    previousPeriodMappingExporting: "Exportar archivo PreviousPeriod.xlsx...",
    previousPeriodMappingExportCompleted:
        "Archivo Previous Period Export.xlsx exportación se ha completado, por favor, compruebe su carpeta de descarga.",
    knowledgeBase: "Base de Conocimiento",
    whatIsNew: "Qué hay Nuevo",
    duplicateHeader: "Encabezado duplicado",
    clearDimensionMappings: "¿Mapeos de dimensiones claros?",
    thisActionWillUnmapItemsMapping: "Esta acción desentrañará todos los elementos dentro de la dimensión.",
    lookup: "Búsqueda",
    addLookupLookdown: "Agregar búsqueda / Diapositivas de búsqueda",
    lookupLookdownTooltip:
        "Lookup / lookdown le permite aislar un grupo, luego ver la puntuación de un elemento o dimensión en particular y elegir para ver lo que está por encima y por debajo del grupo. Para obtener más información, haga clic en el signo de interrogación.",
    includeLookupOrgOverall: "Incluir Organización En general",
    includeWordCloud: "Incluir comentario nube de palabras(s)",
    downloads: "Descargas",
    bulkReportDownloads: "Descargas de reportes",
    bulkDemographicSame: "Los campos demográficos y de búsqueda no pueden ser los mismos.",
    selectOrg: "Seleccione la organización",
    noCreatedBulkExports: "No se han creado informes a granel",
    resend: "Reenviar Invitación",
    editParticipantsWarning:
        "No se permiten cambios en esta lista, ya que la encuesta está actualmente asignada a otra encuesta para datos de períodos anteriores. Para realizar cambios, primero desmonte la encuesta que está usando esta lista de participantes.",
    allowInReports: "Disponibles para Informar",
    orgEngagementScore: "Puntuación de la participación de la organización",
    newCommentsSubmitted: "Nuevos comentarios presentados",
    confirmAction: "Confirmar acción",
    confirmRegenerateCommentSummary: [
        "La regeneración del resumen de comentarios para esta encuesta no puede ser revertida.",
        "Asegúrese de haber exportado los informes necesarios."
    ],
    copied: "Copiado",
    regenerate: "Regenerar",
    copyToClipboradCaseSensitive: "Copiar a Clipborad",
    snapshotExportLayoutCustomization: "Instantánea Exportación Diseño Personalización",
    deleteSnapshotExportLayout: "Borrar todas las diapositivas subidas",
    replaceCoverSlide: "Reemplazar la cubierta de la diapositiva",
    addAdditionalIntroSlide: "Agregar diapositivas adicionales de introducción(s)",
    reportDetailsSlide: "Informe Detalles Diapositiva - Incluye recuento de respuestas, referencia y filtros.",
    replaceUnderstandingResults: "Reemplazar la diapositiva de comprensión de los resultados",
    replaceSurveyResultsTransition: "Reemplazar la diapositiva de transición de los resultados del estudio",
    responseRateSlide: "Índices de respuesta Diapositiva(s)",
    lookupLookdownSlide: "Búsqueda de diapositivas/Búsqueda de diapositivas",
    replaceUnderstandingHeatmap: "Remplacer la compréhension de votre diapositive de carte thermique",
    heatmapSlide: "Mapa de calor Diapositiva(s)",
    replaceUnderstandingKeyDrivers: "Reemplazar la diapositiva de comprensión de los principales factores",
    keyDriverSlides: "Diapositivas de los principales controladores",
    surveyDataSlides: "Diapositivas de datos de encuestas",
    addOutroSlides: "Agregar diapositiva(s) de Otro",
    snapshotExportDescription: [
        `Cambiar o agregar diapositivas estáticas a la exportación de PowerPoint Snapshot.`,
        `Se pueden agregar una o varias diapositivas a cada sección de carga.`,
        `Las diapositivas deben ser de 16 x 9 y no más grandes que 5 MB por sección.`,
        `Haga clic en Guardar para subir los cambios antes de previsualizar la presentación.`,
        `Al diseñar diapositivas, utilice un diseño maestro vacío.`,
        `Descargar plantilla de diapositiva`,
        `.  Para más información, visite nuestro`,
        `Base de Conocimientos`
    ],
    sizeLimit5MB: "El archivo PPTX debe tener 5 MB o menos",
    errorMustUploadPPTX: "Debe cargar un archivo PPTX",
    previewSnapshotExport: "Vista previa de la exportación de instantáneas",
    detractors: "Detractores",
    promoters: "Promotores"
};
