import React, { ReactElement, useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";

import { useLang, useCommentsService } from "core/hooks";
import { Button } from "lib/button";
import { SurveyInfo } from "components/admin/results/interfaces";
import ConfirmationDialog from "components/shared/ConfirmationDialog";
import { emptySurveyInfo } from "components/admin/results/init";
import { CommentSummaryPromptPayload } from "api/rest/interfaces/comments-service";
import { Box } from "lib/box";
import { theme } from "lib/theme";
import { Popover } from "lib/popover";
import { Typography } from "lib/typography";
import { TextField } from "lib/text-field";
import { Divider } from "lib/divider";

const useStyles = makeStyles(() => ({
    paper: {
        marginTop: 204,
        overflowY: "hidden"
    },
    popoverContainer: {
        width: 670,
        height: 450,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        boxSizing: "border-box"
    },
    popoverRowHeader: {
        display: "flex",
        height: 50,
        minHeight: 50,
        width: "100%",
        justifyContent: "flex-start",
        alignItems: "center",
        borderBottom: theme.border.main,
        paddingLeft: 24,
        boxSizing: "border-box"
    }
}));

export enum CommentSummarySettingDataKey {
    summary = "summary",
    suggestion = "suggestion"
}

type Props = {
    summarySettingAnchorEl: Element | null;
    handleClosePopover: () => void;
    surveyId: SurveyInfo["id"];
};

export const CommentSummarySettingPopup = (props: Props): ReactElement => {
    const { lang } = useLang();
    const [confirmDeleteDialogOpen, setConfirmDeleteDialogOpen] = useState(false);
    const [commentSummaryPrompt, setCommentSummaryPrompt] = useState("");
    const [commentSuggestionPrompt, setCommentSuggestionPrompt] = useState("");
    const commentsService = useCommentsService();

    const classes = useStyles();

    const handleCancelOnClick = (): void => {
        props.handleClosePopover();
    };

    const handleConfirmationClicked = (): void => {
        const commentPromptSettingsPayload: CommentSummaryPromptPayload = {
            surveyId: props.surveyId,
            summaryPrompt: commentSummaryPrompt,
            suggestionPrompt: commentSuggestionPrompt
        };
        commentsService.updateCommentPromptSettings(commentPromptSettingsPayload).then((): void => {
            commentsService.getCommentPromptSettings(props.surveyId).then((): void => {
                setConfirmDeleteDialogOpen(false);
                props.handleClosePopover();
            });
        });
    };

    const handleChangeField = (event: React.ChangeEvent<HTMLInputElement>): void => {
        if (event.target.name === CommentSummarySettingDataKey.summary) {
            setCommentSummaryPrompt(event.target.value);
        }
        if (event.target.name === CommentSummarySettingDataKey.suggestion) {
            setCommentSuggestionPrompt(event.target.value);
        }
    };

    useEffect(() => {
        if (props.surveyId && props.surveyId !== emptySurveyInfo.id) {
            commentsService.getCommentPromptSettings(props.surveyId).then(data => {
                setCommentSummaryPrompt(data.summaryPrompt);
                setCommentSuggestionPrompt(data.suggestionPrompt);
            });
        }
    }, []);

    return (
        <>
            <Popover
                classes={{ paper: classes.paper }}
                open={props.summarySettingAnchorEl !== null}
                onClose={props.handleClosePopover}
                anchorEl={props.summarySettingAnchorEl}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right"
                }}
                transformOrigin={{
                    vertical: "center",
                    horizontal: "right"
                }}
                onKeyUp={e => {
                    if (
                        e.key === "Enter" &&
                        commentSummaryPrompt.trim() !== "" &&
                        commentSuggestionPrompt.trim() !== ""
                    ) {
                        setConfirmDeleteDialogOpen(true);
                    }
                }}
            >
                <Box width="670px" height="500px" overflow={"hidden"}>
                    <div className={classes.popoverRowHeader}>
                        <Typography variant="h6">{lang.summarySettings}</Typography>
                    </div>
                    <Box p={2}>
                        <Typography pl={1}>{lang.summaryPrompt}</Typography>
                        <TextField
                            sx={{ height: 114, maxHeight: 114, overflowY: "auto" }}
                            name={CommentSummarySettingDataKey.summary}
                            value={commentSummaryPrompt}
                            onChange={handleChangeField}
                            multiline
                            minRows={4}
                        />
                    </Box>
                    <Box p={2}>
                        <Typography pl={1}>{lang.suggestionPrompt}</Typography>
                        <TextField
                            sx={{ height: 174, maxHeight: 174, overflowY: "auto" }}
                            name={CommentSummarySettingDataKey.suggestion}
                            value={commentSuggestionPrompt}
                            onChange={handleChangeField}
                            multiline
                            minRows={4}
                        />
                    </Box>
                    <Divider />
                    <Box
                        display="flex"
                        height={50}
                        alignItems="center"
                        justifyContent="flex-end"
                        gap={1}
                        width="100%"
                        pr={2}
                    >
                        <Button
                            variant="text"
                            onClick={(): void => {
                                setConfirmDeleteDialogOpen(true);
                            }}
                            disabled={commentSummaryPrompt.trim() === "" || commentSuggestionPrompt.trim() === ""}
                            data-testid="btn-comment-summary-setting-apply"
                        >
                            {lang.ok}
                        </Button>
                        <Button
                            variant="text"
                            onClick={handleCancelOnClick}
                            data-testid="btn-comment-summary-setting-cancel"
                        >
                            {lang.cancel}
                        </Button>
                    </Box>
                </Box>
            </Popover>
            {confirmDeleteDialogOpen && (
                <ConfirmationDialog
                    onCancelClicked={(): void => setConfirmDeleteDialogOpen(false)}
                    open={confirmDeleteDialogOpen}
                    onConfirmationClicked={handleConfirmationClicked}
                    title={lang.confirmEditPrompt}
                    message={lang.editPromptMessage}
                    confirmButtonText={lang.ok}
                    cancelButtonVariant="text"
                />
            )}
        </>
    );
};

CommentSummarySettingPopup.displayName = "CommentSummarySettingPopup";
