import React, { useState, ReactElement, useRef, useEffect } from "react";
import { makeStyles } from "@mui/styles";

import { SurveySection, SurveyQuestion } from "managerPortal/context/interfaces";
import { useLang } from "core/hooks";
import { getAvailableSurveySectionsOnlyPickOne, surveySectionsPickOneWithIsFunAndItemId } from "core/helpers";
import { QuestionType } from "managerPortal/context/enums";
import SelectExpandableList from "managerPortal/components/shared/SelectExpandableList";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { TextField } from "lib/text-field";
import { Box } from "lib/box";
import { Size } from "./SingleDimensionOrItem";
import { DimensionOrItem } from "./SingleDimensionOrItem";
import { Box as BoxType, TitleTranslation } from "../interfaces";
import { SelectOptionExpandablePanel } from "components/shared/interfaces";
import { Select, SelectChangeEvent } from "lib/select-custom";
import { Typography } from "lib/typography";
import { useUser } from "core/context/user/useUser";

type Props = {
    selectedPanelId: string;
    boxes: BoxType[];
    singleDimensionOrItemSize: Size[];
    selectedSingleDimensionOrItemSize: string;
    singleDimensionOrItemSizeSelected: (event: SelectChangeEvent<unknown>) => void;
    singleDimensionOrItem: DimensionOrItem;
    surveySections: SurveySection[];
    updateItem: (item: DimensionOrItem) => void;
    setSingleDimensionOrItemSize: (size: string) => void;
    titleTranslation: TitleTranslation[];
    updateTitleTranslation: (newTitleTranslations: TitleTranslation[]) => void;
};

const useStyles = makeStyles(() => ({
    surveyItemList: {
        paddingBottom: 10,
        height: 392,
        width: 400
    },
    surveyItemButton: {
        display: "flex",
        textTransform: "none",
        width: "100%",
        height: 49,
        borderBottom: "solid thin darkgrey",
        borderRadius: 0,
        backgroundColor: "#fafafa"
    },
    surveyItemButtonTextWrapper: {
        paddingLeft: "16px",
        display: "flex",
        width: "100%",
        alignItems: "center"
    },
    surveyItemButtonText: {
        flexBasis: "90%",
        display: "block",
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        fontWeight: 400,
        fontSize: 14,
        textAlign: "left"
    },
    surveyItemButtonIcon: {
        flexBasis: "10%"
    },
    size: {
        paddingTop: 6
    }
}));

const SingleDimensionOrItemCellType = (props: Props): ReactElement => {
    const classes = useStyles();
    const { lang } = useLang();
    const { user } = useUser();
    const [showSurveyItemList, setShowSurveyItemList] = useState<boolean>(false);
    const singleDimensionOrItemListRef = useRef<HTMLDivElement>(null);

    const handleSurveyItemSelect = (id: string, level: number, title: string): void => {
        setShowSurveyItemList(false);
        if (level === 0) {
            props.updateItem({
                ...props.singleDimensionOrItem,
                isSection: true,
                measureId: +id.slice(1),
                measureField: title
            });
        } else if (level === 1) {
            props.updateItem({
                ...props.singleDimensionOrItem,
                isSection: false,
                measureId: +id.slice(1),
                measureField: title
            });
        }
    };

    const surveySections = (): SurveySection[] => {
        return props.surveySections
            .map((section: SurveySection) => {
                return {
                    ...section,
                    questions: section.questions.filter(
                        (question: SurveyQuestion) => question.questionType === QuestionType.PickOne && question.isFun
                    )
                };
            })
            .filter((section: SurveySection) => section.questions.length);
    };

    const getDisabledSectionIds = (): SelectOptionExpandablePanel["id"][] => {
        const allItemsRemovedOverallScoreSectionIds = [] as SelectOptionExpandablePanel["id"][];
        const availableSurveySections = getAvailableSurveySectionsOnlyPickOne(surveySections(), user);
        availableSurveySections.forEach(section => {
            const items = section.questions.map(question => question.items).reduce((a, b) => a.concat(b));
            if (items.every(item => item.isRemovedOverallScore === true)) {
                allItemsRemovedOverallScoreSectionIds.push(section.sectionId.slice(1));
            }
        });
        return allItemsRemovedOverallScoreSectionIds;
    };

    const handleOpenSurveyItemList = (): void => {
        setShowSurveyItemList(!showSurveyItemList);
        setTimeout(() => {
            if (singleDimensionOrItemListRef && singleDimensionOrItemListRef.current) {
                singleDimensionOrItemListRef.current.scrollIntoView({
                    behavior: "smooth",
                    block: "end",
                    inline: "nearest"
                });
            }
        }, 100);
    };

    const handleListOnBlur = (): void => {
        if (showSurveyItemList) {
            setShowSurveyItemList(false);
        }
    };
    const handleOnFocus = (event: React.FocusEvent): void => {
        if (event.target) {
            const element = event.target as HTMLInputElement;
            element.select();
        }
    };

    const handleChangeField = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const { name, value } = event.target;

        if (name === "englishTitle") {
            const updatedTitleTranslations = props.titleTranslation.map(t =>
                t.languageCode === "en" ? { languageCode: "en", name: value } : t
            );
            props.updateTitleTranslation(updatedTitleTranslations);
            return;
        }
        if (name === "frenchTitle") {
            const updatedTitleTranslations = props.titleTranslation.map(t =>
                t.languageCode === "fr" ? { languageCode: "fr", name: value } : t
            );
            props.updateTitleTranslation(updatedTitleTranslations);
            return;
        }
        if (name === "spanishTitle") {
            const updatedTitleTranslations = props.titleTranslation.map(t =>
                t.languageCode === "es" ? { languageCode: "es", name: value } : t
            );
            props.updateTitleTranslation(updatedTitleTranslations);
            return;
        }
    };
    useEffect(() => {
        const boxFound = props.boxes.find(box => box.id === props.selectedPanelId);
        if (boxFound) {
            props.setSingleDimensionOrItemSize(`${boxFound.height} x ${boxFound.width}`);
        }
    }, []);

    return (
        <Box display="flex" flexDirection="column" gap={2}>
            <Box width={200}>
                <Select
                    label={lang.size}
                    value={props.selectedSingleDimensionOrItemSize}
                    onChange={props.singleDimensionOrItemSizeSelected}
                    data-testid="singleDimensionOrItem"
                    items={props.singleDimensionOrItemSize.map((size: Size) => {
                        return {
                            value: size.title,
                            name: size.title
                        };
                    })}
                />
            </Box>
            <Box tabIndex={0} onBlur={handleListOnBlur} ref={singleDimensionOrItemListRef}>
                <div
                    onClick={handleOpenSurveyItemList}
                    className={classes.surveyItemButton}
                    data-testid={"dashboard-btn-surveyItem"}
                >
                    <div className={classes.surveyItemButtonTextWrapper}>
                        <div className={classes.surveyItemButtonText} data-testid={"dashboard-label-surveyItem"}>
                            {props.singleDimensionOrItem.measureField
                                ? props.singleDimensionOrItem.measureField
                                : `${lang.surveyDimensionOrItem}`}
                        </div>
                        <div className={classes.surveyItemButtonIcon}>
                            <ArrowDropDownIcon style={{ color: "grey" }} />
                        </div>
                    </div>
                </div>
                {showSurveyItemList && (
                    <div className={classes.surveyItemList} data-testid={"dashboard-label-surveyItem-list"}>
                        <SelectExpandableList
                            rawData={surveySectionsPickOneWithIsFunAndItemId(
                                getAvailableSurveySectionsOnlyPickOne(surveySections(), user)
                            )}
                            disabledSectionIds={getDisabledSectionIds()}
                            onItemSelected={handleSurveyItemSelect}
                        />
                    </div>
                )}
            </Box>
            <Typography variant="body1">{lang.title}</Typography>
            <Box display="flex" alignItems="center">
                <Typography variant="body1" width={100}>
                    {lang.english}
                </Typography>
                <TextField
                    onChange={handleChangeField}
                    onFocus={handleOnFocus}
                    data-testid={"singleDimensionOrItems-englishTitle-TextField"}
                    margin="dense"
                    name={"englishTitle"}
                    value={props.titleTranslation.filter(t => t.languageCode === "en")[0]?.name ?? ""}
                />
            </Box>
            <Box display="flex" alignItems="center">
                <Typography width={100} variant="body1">
                    {lang.spanish}
                </Typography>
                <TextField
                    onChange={handleChangeField}
                    onFocus={handleOnFocus}
                    data-yestId={"singleDimensionOrItems-spanishTitle-TextField"}
                    margin="dense"
                    name={"spanishTitle"}
                    value={props.titleTranslation.filter(t => t.languageCode === "es")[0]?.name ?? ""}
                />
            </Box>
            <Box display="flex" alignItems="center">
                <Typography width={100} variant="body1">
                    {lang.french}
                </Typography>
                <TextField
                    onChange={handleChangeField}
                    onFocus={handleOnFocus}
                    data-testid={"singleDimensionOrItems-frenchTitle-TextField"}
                    margin="dense"
                    name={"frenchTitle"}
                    value={props.titleTranslation.filter(t => t.languageCode === "fr")[0]?.name ?? ""}
                />
            </Box>
        </Box>
    );
};

export default SingleDimensionOrItemCellType;
