import { SurveyQuestion, SurveySection, SurveyItem } from "managerPortal/context/interfaces";
import { SelectOptionExpandablePanel } from "components/shared/interfaces";
import { QuestionType } from "managerPortal/context/enums";

export const surveySectionsToExpandableData = (
    surveySections: SurveySection[],
    onlyFunScale?: boolean
): SelectOptionExpandablePanel[] => {
    const mapSurveyItemFields: SelectOptionExpandablePanel[] = [];
    if (surveySections.length) {
        surveySections.forEach((surveyItemFields: SurveySection) => {
            const fieldSubNode: SelectOptionExpandablePanel[] = [];
            surveyItemFields.questions
                .filter((question: SurveyQuestion) => (onlyFunScale && question.isFun) || !onlyFunScale)
                .forEach((question: SurveyQuestion) => {
                    if (question.questionType === QuestionType.PickOne) {
                        question.items.forEach((item: SurveyItem) => {
                            const reportOptions = [];
                            reportOptions.push(item.isIgnored);
                            reportOptions.push(item.isRemovedOverallScore);
                            reportOptions.push(item.isHiddenForManagers);
                            fieldSubNode.push({
                                id: item.fieldId.toString(),
                                title: item.title,
                                isDefault: !(item.isDefault === false || reportOptions.includes(true))
                            });
                        });
                    }
                });
            const isSurveyItemFieldsDefault = fieldSubNode.every((item: SelectOptionExpandablePanel) => item.isDefault);
            mapSurveyItemFields.push({
                id: surveyItemFields.sectionId,
                title: surveyItemFields.title,
                subNode: fieldSubNode,
                isDefault: isSurveyItemFieldsDefault
            });
        });
    }

    return mapSurveyItemFields.filter((item: SelectOptionExpandablePanel) => item.subNode?.length);
};

export const surveySectionsToExpandableDataInFilter = (
    surveySections: SurveySection[],
    onlyFunScale?: boolean
): SelectOptionExpandablePanel[] => {
    const mapSurveyItemFields: SelectOptionExpandablePanel[] = [];
    if (surveySections.length) {
        surveySections.forEach((surveyItemFields: SurveySection) => {
            const fieldSubNode: SelectOptionExpandablePanel[] = [];
            surveyItemFields.questions
                .filter((question: SurveyQuestion) => (onlyFunScale && question.isFun) || !onlyFunScale)
                .forEach((question: SurveyQuestion) => {
                    if (question.questionType === QuestionType.PickOne) {
                        question.items.forEach((item: SurveyItem) => {
                            fieldSubNode.push({
                                id: item.fieldId.toString(),
                                title: item.title
                            });
                        });
                    } else {
                        fieldSubNode.push({
                            id: question.questionId.toString(),
                            title: question.questionTitle
                        });
                    }
                });
            mapSurveyItemFields.push({
                id: surveyItemFields.sectionId,
                title: surveyItemFields.title,
                subNode: fieldSubNode
            });
        });
    }

    return mapSurveyItemFields.filter((item: SelectOptionExpandablePanel) => item.subNode?.length);
};

export const surveySectionsPickOne = (surveySections: SurveySection[]): SelectOptionExpandablePanel[] => {
    const mapSurveyItemFields: SelectOptionExpandablePanel[] = [];
    if (surveySections.length) {
        surveySections.forEach((surveyItemFields: SurveySection) => {
            const questionSubNode: SelectOptionExpandablePanel[] = [];
            surveyItemFields.questions
                .filter((question: SurveyQuestion) => question.questionType === QuestionType.PickOne)
                .forEach((question: SurveyQuestion) => {
                    const fieldSubNode: SelectOptionExpandablePanel[] = [];

                    if (question.questionType === QuestionType.PickOne) {
                        question.items.forEach((item: SurveyItem) => {
                            fieldSubNode.push({ id: item.fieldId.toString(), title: item.title });
                        });
                        questionSubNode.push({
                            id: question.questionId.toString(),
                            title: question.questionTitle,
                            subNode: [...fieldSubNode]
                        });
                    } else {
                        questionSubNode.push({
                            id: question.questionId.toString(),
                            title: question.questionTitle
                        });
                    }
                });
            mapSurveyItemFields.push({
                id: surveyItemFields.sectionId,
                title: surveyItemFields.title,
                subNode: questionSubNode
            });
        });
    }

    return mapSurveyItemFields.filter((item: SelectOptionExpandablePanel) => item.subNode?.length);
};
export const surveySectionsPickOneWithIsFun = (surveySections: SurveySection[]): SelectOptionExpandablePanel[] => {
    const mapSurveyItemFields: SelectOptionExpandablePanel[] = [];
    if (surveySections.length) {
        surveySections.forEach((surveyItemFields: SurveySection) => {
            const fieldSubNode: SelectOptionExpandablePanel[] = [];
            surveyItemFields.questions
                .filter((question: SurveyQuestion) => question.questionType === QuestionType.PickOne && question.isFun)
                .forEach((question: SurveyQuestion) => {
                    if (question.questionType === QuestionType.PickOne) {
                        question.items.forEach((item: SurveyItem) => {
                            fieldSubNode.push({ id: item.fieldId.toString(), title: item.title });
                        });
                    }
                });
            mapSurveyItemFields.push({
                id: surveyItemFields.sectionId,
                title: surveyItemFields.title,
                subNode: fieldSubNode
            });
        });
    }

    return mapSurveyItemFields.filter((item: SelectOptionExpandablePanel) => item.subNode?.length);
};

export const surveySectionsPickOneWithIsFunAndItemId = (
    surveySections: SurveySection[]
): SelectOptionExpandablePanel[] => {
    const mapSurveyItemFields: SelectOptionExpandablePanel[] = [];
    if (surveySections.length) {
        surveySections.forEach((surveyItemFields: SurveySection) => {
            const fieldSubNode: SelectOptionExpandablePanel[] = [];
            surveyItemFields.questions
                .filter((question: SurveyQuestion) => question.questionType === QuestionType.PickOne && question.isFun)
                .forEach((question: SurveyQuestion) => {
                    if (question.questionType === QuestionType.PickOne) {
                        question.items.forEach((item: SurveyItem) => {
                            fieldSubNode.push({ id: item.itemId.toString(), title: item.title });
                        });
                    }
                });
            mapSurveyItemFields.push({
                id: surveyItemFields.sectionId,
                title: surveyItemFields.title,
                subNode: fieldSubNode
            });
        });
    }

    return mapSurveyItemFields.filter((item: SelectOptionExpandablePanel) => item.subNode?.length);
};
