import React, { ReactElement, useState, useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { Typography } from "lib/typography";
import { mdiArrowCollapseVertical, mdiArrowExpandVertical } from "@mdi/js";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import clsx from "clsx";

import { Card } from "managerPortal/components/cards/interfaces";
import { SurveyItem, SurveyQuestion, SurveySection } from "../../../context/interfaces";
import ShowSectionInfo from "../ShowSectionInfo";
import { useLang, useWindowResize } from "core/hooks";
import { getAvailableSurveySections, sortArray } from "core/helpers";
import { SnapshotLegend } from "../SnapshotLegend";
import { Filter } from "components/filters/interfaces";
import FilterChip from "components/shared/FilterChip";
import { ReportRoutes } from "routes/enums";
import { useStyles } from "./useSnapshotSectionDetailsStyle";
import { useSnapshoTableState } from "./useSnapshoTableState";
import type { ItemData, SectionData, SortBy, SnapShotDefinition } from "./types";
import ShowPickOneItem from "../ShowPickOneItem";
import { getLabelLength } from "../helpers/getLabelLength";
import { labelLength } from "../constants/labelLength";
import { IconButtonV4 } from "lib/icon-button";
import { Button } from "lib/button";
import { Box } from "lib/box";
import { useUser } from "core/context/user/useUser";

type Props = {
    selectedCard: Card;
    showBenchmarks: boolean;
    showPreviousPeriod: boolean;
    surveySections: SurveySection[];
    showOrgOverall: boolean;
    showDemographic: boolean;
    currentFilter: Filter;
    columnLabel: string;
    selectedSurveySections: SurveySection[];
    showFavorableUnfavorableOnly: boolean;
    handleComparisonOpen: (event: React.MouseEvent<HTMLButtonElement>) => void;
    setCardElementSelected: (a: SurveySection["sectionId"]) => void;
    setCardElementUnSelected: (a: SurveySection["sectionId"]) => void;
    onSelectAllSections: () => void;
    onCollapseAllSections: () => void;
    handleDeleteFilterItem: (filterItemOrder: number) => void;
};

const SnapshotSectionDetails = (props: Props): ReactElement => {
    const {
        selectedCard,
        showBenchmarks,
        currentFilter,
        showDemographic,
        showOrgOverall,
        showPreviousPeriod,
        surveySections,
        selectedSurveySections,
        columnLabel,
        showFavorableUnfavorableOnly,
        setCardElementSelected,
        setCardElementUnSelected,
        handleDeleteFilterItem,
        onCollapseAllSections,
        onSelectAllSections,
        handleComparisonOpen
    } = props;
    const { lang, languageCode } = useLang();
    const { user } = useUser();
    const size = useWindowResize();
    const location = useLocation();
    const [isAllCollapsed, setCollapseAll] = useState(false);
    const [isAllExpanded, setExpandAll] = useState(false);
    const availableSections = useMemo(() => getAvailableSurveySections(surveySections, user), [surveySections, user]);
    const { sortBy, orderBy, setSortBy } = useSnapshoTableState();

    let mapSections: SectionData[] = [];
    if (!showFavorableUnfavorableOnly) {
        mapSections = availableSections.map((section: SurveySection) => {
            const mapQuestions = section.questions.map((question: SurveyQuestion) => {
                const getItems = (items: SurveyItem[]): ItemData[] => {
                    const mapItems = items.map((item: SurveyItem) => {
                        const itemScores = selectedCard["ISB" + item.itemId.slice(1)];
                        const itemIdInSelectCard = item.itemId.substring(0, 1) + "N" + item.itemId.slice(1);

                        return {
                            title: item.title,
                            itemId: item.itemId,
                            scores: itemScores,
                            previousPeriodScoreOne:
                                selectedCard.previousPeriod !== null &&
                                selectedCard.previousPeriod !== undefined &&
                                selectedCard.previousPeriod.length > 0
                                    ? selectedCard.previousPeriod[0].values[itemIdInSelectCard]
                                    : null,
                            previousPeriodScoreTwo:
                                selectedCard.previousPeriod !== null &&
                                selectedCard.previousPeriod !== undefined &&
                                selectedCard.previousPeriod.length > 1
                                    ? selectedCard.previousPeriod[1].values[itemIdInSelectCard]
                                    : null,
                            benchmarkScore:
                                selectedCard.benchmark !== null && selectedCard.benchmark !== undefined
                                    ? selectedCard.benchmark[itemIdInSelectCard]
                                    : null,
                            overallScore:
                                selectedCard.overall !== null && selectedCard.overall !== undefined
                                    ? selectedCard.overall[itemIdInSelectCard]
                                    : null,
                            demographicScore:
                                selectedCard.compareGroup !== null && selectedCard.compareGroup !== undefined
                                    ? selectedCard.compareGroup[itemIdInSelectCard]
                                    : null,
                            numberOfPicks: selectedCard["ISB" + item.itemId.slice(1)],
                            // set percentage to 0 for now
                            percentage: 0
                        };
                    });

                    if (sortBy === "default") {
                        return mapItems;
                    }

                    if (sortBy === "scores") {
                        return mapItems.sort(function (a, b) {
                            const valueA = a.scores[2];
                            const valueB = b.scores[2];
                            if (valueA < valueB) {
                                return orderBy === "asc" ? -1 : 1;
                            } else if (valueA > valueB) {
                                return orderBy === "asc" ? 1 : -1;
                            }
                            return 0;
                        });
                    }

                    const itemsWithPP = mapItems.filter(i => (i[sortBy] || i[sortBy] === 0) && i[sortBy] !== null);
                    const itemsNoPP = mapItems.filter(i => !i[sortBy] && i[sortBy] !== 0);

                    return [...sortArray(itemsWithPP, sortBy, orderBy), ...itemsNoPP];
                };

                return {
                    questionType: question.questionType,
                    questionId: question.questionId,
                    isFun: question.isFun,
                    questionTitle: question.questionTitle,
                    items: getItems(question.items)
                };
            });

            const sectionScores = selectedCard["SSB" + section.sectionId.slice(1)];
            const sectionIdInSelectCard = section.sectionId.substring(0, 1) + "N" + section.sectionId.slice(1);

            return {
                title: section.title,
                sectionId: section.sectionId,
                scores: sectionScores,
                previousPeriodScoreOne:
                    selectedCard.previousPeriod !== null &&
                    selectedCard.previousPeriod !== undefined &&
                    selectedCard.previousPeriod.length > 0
                        ? selectedCard.previousPeriod[0].values[sectionIdInSelectCard]
                        : null,
                previousPeriodScoreTwo:
                    selectedCard.previousPeriod !== null &&
                    selectedCard.previousPeriod !== undefined &&
                    selectedCard.previousPeriod.length > 1
                        ? selectedCard.previousPeriod[1].values[sectionIdInSelectCard]
                        : null,
                benchmarkScore:
                    selectedCard.benchmark !== null && selectedCard.benchmark !== undefined
                        ? selectedCard.benchmark[sectionIdInSelectCard]
                        : null,
                overallScore:
                    selectedCard.overall !== null && selectedCard.overall !== undefined
                        ? selectedCard.overall[sectionIdInSelectCard]
                        : null,
                demographicScore:
                    selectedCard.compareGroup !== null && selectedCard.compareGroup !== undefined
                        ? selectedCard.compareGroup[sectionIdInSelectCard]
                        : null,
                questions: mapQuestions
            };
        });

        if (sortBy === "scores") {
            mapSections.sort(function (a, b) {
                const valueA = a.scores ? a.scores[2] : 0;
                const valueB = b.scores ? b.scores[2] : 0;
                if (valueA < valueB) {
                    return orderBy === "asc" ? -1 : 1;
                } else if (valueA > valueB) {
                    return orderBy === "asc" ? 1 : -1;
                }
                return 0;
            });
        } else if (sortBy !== "default") {
            const itemsWithPP = mapSections.filter(i => (i[sortBy] || i[sortBy] === 0) && i[sortBy] !== null);
            const itemsNoPP = mapSections.filter(i => !i[sortBy] && i[sortBy] !== 0);
            mapSections = [...sortArray(itemsWithPP, sortBy, orderBy), ...itemsNoPP];
        }
    }

    const handleSort = (updateSortBy: SortBy): void => {
        setSortBy(updateSortBy);
    };

    const isSectionNoDataToDisplay = (section: SectionData) => {
        return section.scores === undefined || section.scores === null;
    };

    const isSectionHasDataToDisplay = (section: SectionData) => {
        return section.scores !== undefined && section.scores !== null;
    };

    const snapShotDefinition: SnapShotDefinition = {
        previousPeriodInfo: selectedCard.previousPeriod
            ? selectedCard.previousPeriod.map((previousPeriod, index) => {
                  return {
                      header: previousPeriod.header,
                      previousPeriodMap: index === 0 ? "previousPeriodScoreOne" : "previousPeriodScoreTwo"
                  };
              })
            : [],
        responseCount: selectedCard.response_count,
        sections:
            sortBy === "default"
                ? mapSections
                : mapSections.filter(isSectionHasDataToDisplay).concat(mapSections.filter(isSectionNoDataToDisplay))
    };

    const isInDialog = location.pathname.includes(ReportRoutes.orgChart);
    const columnWidth = size.height <= 900 ? 48 : 65;
    const numberOfColumnsWithoutPreviousPeriod = [showDemographic, showBenchmarks, showOrgOverall].filter(
        Boolean
    ).length;
    const numberOfColumnsWithPreviousPeriod =
        [showDemographic, showBenchmarks, showOrgOverall].filter(Boolean).length +
        snapShotDefinition.previousPeriodInfo.length;
    const numberOfColumns = showPreviousPeriod
        ? numberOfColumnsWithPreviousPeriod
        : numberOfColumnsWithoutPreviousPeriod;
    const stackbarWidth = 420 - (numberOfColumns - 1) * 10;
    const legendPaddingRight = numberOfColumns < 2 ? 72 : 85 - numberOfColumns * 10;

    const classes = useStyles({ columnWidth, legendPaddingRight, isInDialog, orderBy, languageCode, stackbarWidth });

    const showCellIcon = showDemographic || showBenchmarks || showOrgOverall || showPreviousPeriod;

    const handleCollapseAll = (): void => {
        onCollapseAllSections();
    };

    const handleExpandAll = (): void => {
        onSelectAllSections();
    };

    useEffect(() => {
        if (selectedSurveySections && selectedSurveySections.filter(section => section.sectionId !== "").length === 0) {
            setCollapseAll(true);
            setExpandAll(false);
        } else if (
            selectedSurveySections &&
            selectedSurveySections.filter(section => section.sectionId !== "").length === surveySections.length
        ) {
            setExpandAll(true);
            setCollapseAll(false);
        } else {
            setExpandAll(false);
            setCollapseAll(false);
        }
    }, [selectedSurveySections]);

    let mapItems: ItemData[] = [];
    if (showFavorableUnfavorableOnly) {
        availableSections.forEach(section => {
            section.questions
                .filter(q => q.isFun)
                .forEach(question => {
                    question.items.forEach(item => {
                        const itemScores = selectedCard["ISB" + item.itemId.slice(1)];
                        const itemIdInSelectCard = item.itemId.substring(0, 1) + "N" + item.itemId.slice(1);

                        mapItems.push({
                            title: item.title,
                            itemId: item.itemId,
                            scores: itemScores,
                            previousPeriodScoreOne:
                                selectedCard.previousPeriod !== null &&
                                selectedCard.previousPeriod !== undefined &&
                                selectedCard.previousPeriod.length > 0
                                    ? selectedCard.previousPeriod[0].values[itemIdInSelectCard]
                                    : null,
                            previousPeriodScoreTwo:
                                selectedCard.previousPeriod !== null &&
                                selectedCard.previousPeriod !== undefined &&
                                selectedCard.previousPeriod.length > 1
                                    ? selectedCard.previousPeriod[1].values[itemIdInSelectCard]
                                    : null,
                            benchmarkScore:
                                selectedCard.benchmark !== null && selectedCard.benchmark !== undefined
                                    ? selectedCard.benchmark[itemIdInSelectCard]
                                    : null,
                            overallScore:
                                selectedCard.overall !== null && selectedCard.overall !== undefined
                                    ? selectedCard.overall[itemIdInSelectCard]
                                    : null,
                            demographicScore:
                                selectedCard.compareGroup !== null && selectedCard.compareGroup !== undefined
                                    ? selectedCard.compareGroup[itemIdInSelectCard]
                                    : null,
                            numberOfPicks: selectedCard["ISB" + item.itemId.slice(1)],
                            // set percentage to 0 for now
                            percentage: 0
                        });
                    });
                });
        });

        if (sortBy === "scores") {
            mapItems = mapItems.sort(function (a, b) {
                const valueA = a.scores[2];
                const valueB = b.scores[2];
                if (valueA < valueB) {
                    return orderBy === "asc" ? -1 : 1;
                } else if (valueA > valueB) {
                    return orderBy === "asc" ? 1 : -1;
                }
                return 0;
            });
        }

        if (sortBy !== "default" && sortBy !== "scores") {
            const itemsWithPP = mapItems.filter(i => (i[sortBy] || i[sortBy] === 0) && i[sortBy] !== null);
            const itemsNoPP = mapItems.filter(i => !i[sortBy] && i[sortBy] !== 0);

            mapItems = [...sortArray(itemsWithPP, sortBy, orderBy), ...itemsNoPP];
        }
    }

    return (
        <>
            <div className={classes.header}>
                <div className={classes.headerInfo}>
                    <Typography variant="h6" whiteSpace={"nowrap"}>
                        {lang.totalResponses} - {snapShotDefinition.responseCount}
                    </Typography>
                    {currentFilter.items && currentFilter.items.length > 0 && (
                        <FilterChip items={currentFilter.items} onDelete={handleDeleteFilterItem} />
                    )}
                </div>
                <div className={classes.headerActions}>
                    <Box mr={"36px"}>
                        <Button
                            variant="text"
                            onClick={handleComparisonOpen}
                            data-testid="btn-snapshot-open-comparison"
                        >
                            {lang.comparisons}
                        </Button>
                    </Box>
                    <div className={classes.chartActions}>
                        <IconButtonV4
                            path={mdiArrowExpandVertical}
                            onClick={handleExpandAll}
                            disabled={isAllExpanded}
                            tooltip={lang.expandAll}
                            dataTestid="snapshot-expandAll"
                        />
                        <IconButtonV4
                            path={mdiArrowCollapseVertical}
                            onClick={handleCollapseAll}
                            disabled={isAllCollapsed}
                            tooltip={lang.collapseAll}
                            dataTestid="snapshot-collapseAll"
                        />
                    </div>
                </div>
            </div>
            <div className={classes.content} data-testid="snapshot-content-section">
                <div className={classes.heardSort} onClick={(): void => handleSort("default")}>
                    <Typography>
                        {showFavorableUnfavorableOnly ? lang.favourableUnfavourableItems : lang.dimensionsAndItems}
                    </Typography>
                    {sortBy === "default" && <ArrowDownwardIcon className={classes.arrowIcon} fontSize={"small"} />}
                </div>
                <div className={`${classes.cellInfo} ${classes.legendCell}`} onClick={(): void => handleSort("scores")}>
                    <SnapshotLegend />
                    {sortBy === "scores" && <ArrowDownwardIcon className={classes.arrowIcon} fontSize={"small"} />}
                </div>
                {showPreviousPeriod && (
                    <div className={`${classes.cellPreviousPeriod} ${classes.cell}`} data-testid={`pp-header`}>
                        {snapShotDefinition.previousPeriodInfo.map(({ header, previousPeriodMap }) => (
                            <div
                                key={header}
                                data-testid={`pp-header-${header}`}
                                onClick={(): void => handleSort(previousPeriodMap)}
                                className={clsx(
                                    classes.individualCellPreviousPeriod,
                                    sortBy === "previousPeriodScoreOne" &&
                                        previousPeriodMap === "previousPeriodScoreOne"
                                        ? classes.selected
                                        : sortBy === "previousPeriodScoreTwo" &&
                                            previousPeriodMap === "previousPeriodScoreTwo"
                                          ? classes.selected
                                          : ""
                                )}
                            >
                                <Typography variant="subtitle2">{header}</Typography>
                                {sortBy === "previousPeriodScoreOne" &&
                                    previousPeriodMap === "previousPeriodScoreOne" && (
                                        <ArrowDownwardIcon className={classes.arrowIcon} />
                                    )}
                                {sortBy === "previousPeriodScoreTwo" &&
                                    previousPeriodMap === "previousPeriodScoreTwo" && (
                                        <ArrowDownwardIcon className={classes.arrowIcon} />
                                    )}
                            </div>
                        ))}
                    </div>
                )}
                {showDemographic && (
                    <div
                        className={clsx(
                            classes.cellDemoTitle,
                            classes.cell,
                            sortBy === "demographicScore" && classes.selected
                        )}
                        onClick={(): void => handleSort("demographicScore")}
                    >
                        <Typography variant="subtitle2">
                            {columnLabel === "" ? lang.compareGroup : columnLabel}
                        </Typography>
                        {sortBy === "demographicScore" && <ArrowDownwardIcon className={classes.arrowIcon} />}
                    </div>
                )}
                {showOrgOverall && (
                    <div
                        className={clsx(
                            classes.cellOverallTitle,
                            classes.cell,
                            sortBy === "overallScore" && classes.selected
                        )}
                        onClick={(): void => handleSort("overallScore")}
                    >
                        <Typography variant="subtitle2">{lang.overallSS}</Typography>
                        {sortBy === "overallScore" && <ArrowDownwardIcon className={classes.arrowIcon} />}
                    </div>
                )}
                {showBenchmarks && (
                    <div
                        className={clsx(
                            classes.cellBenchmark,
                            classes.cell,
                            sortBy === "benchmarkScore" && classes.selected
                        )}
                        onClick={(): void => handleSort("benchmarkScore")}
                    >
                        <Typography variant="subtitle2">{lang.benchmark}</Typography>
                        {sortBy === "benchmarkScore" && <ArrowDownwardIcon className={classes.arrowIcon} />}
                    </div>
                )}
                <div className={showCellIcon ? `${classes.cellIcon} ${classes.cell}` : `${classes.cellIconWhite}`} />
            </div>
            <div id="snapshotSections" className={classes.listContainer}>
                {!showFavorableUnfavorableOnly
                    ? snapShotDefinition.sections.map((section: SectionData) => (
                          <ShowSectionInfo
                              key={section.sectionId}
                              selectedCard={selectedCard}
                              setCardElementSelected={setCardElementSelected}
                              setCardElementUnSelected={setCardElementUnSelected}
                              showBenchmarks={showBenchmarks}
                              surveySections={availableSections}
                              showPreviousPeriod={showPreviousPeriod}
                              showOrgOverall={showOrgOverall}
                              showDemographic={showDemographic}
                              selectedSurveySections={selectedSurveySections}
                              snapShotDefinition={snapShotDefinition}
                              section={section}
                          />
                      ))
                    : mapItems.map(item => (
                          <div
                              key={item.itemId}
                              className={classes.favorableUnfavorableItem}
                              data-testid={`pick-one-container-${item.itemId}`}
                          >
                              <ShowPickOneItem
                                  itemData={item}
                                  selectedCard={selectedCard}
                                  questionIdentifier="ISB"
                                  surveySections={surveySections}
                                  showBenchmarks={showBenchmarks}
                                  showPreviousPeriod={showPreviousPeriod}
                                  labelLength={getLabelLength(item.title, labelLength, isInDialog)}
                                  showDemographic={showDemographic}
                                  showOrgOverall={showOrgOverall}
                                  snapShotDefinition={snapShotDefinition}
                              />
                          </div>
                      ))}
            </div>
        </>
    );
};

export default SnapshotSectionDetails;
