import React, { ReactElement } from "react";
import { useLang } from "core/hooks";
import { makeStyles } from "@mui/styles";
import { PanelItem, DashboardData, DashboardItemData, DashboardSectionData } from "./interface";
import { PieChart } from "react-minimal-pie-chart";
import { neutralColor, unfavorableColor, favorableColor } from "core/styles/ColorsDefinition";
import { darkGreyColor } from "managerPortal/styles/GlobalStyles";
import { GRID_ROW_LENGTH, GRID_COL_LENGTH } from "components/admin/results/dashboard/Dashboard";
import { itemHeight, itemWidth } from "./DashboardItem";
import { Tooltip } from "lib/tooltip";
import { Typography } from "lib/typography";

type CellTypeImageStyleProps = {
    xPosition: number;
    yPosition: number;
    numberOfColumn: number;
    numberOfRow: number;
    height: number;
    width: number;
};

interface Props {
    testid: number;
    item: PanelItem;
    dashboardData: DashboardData;
}

const useStyles = makeStyles(() => ({
    cell: (styleProps: CellTypeImageStyleProps) => {
        return {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            position: "absolute",
            alignItems: "center",
            top: `${(styleProps.yPosition / styleProps.numberOfRow) * 100}%`,
            left: `${(styleProps.xPosition / styleProps.numberOfColumn) * 100}%`,
            height: itemHeight * styleProps.height,
            width: itemWidth * styleProps.width
        };
    },
    pieChart: {
        display: "flex",
        justifyContent: "center",
        height: "70%",
        paddingBottom: 8
    },
    pp: (styleProps: CellTypeImageStyleProps) => {
        return {
            display: "flex",
            width: styleProps.width * itemWidth,
            maxWidth: styleProps.width * itemWidth,
            minWidth: styleProps.width * itemWidth,
            position: "absolute",
            top: (itemHeight * styleProps.height) / 2 - 8,
            fontSize: styleProps.height > 4 ? 16 : 14,
            justifyContent: "center"
        };
    },
    ppValueNegative: (styleProps: CellTypeImageStyleProps) => {
        return {
            display: "flex",
            width: styleProps.width * itemWidth,
            maxWidth: styleProps.width * itemWidth,
            minWidth: styleProps.width * itemWidth,
            position: "absolute",
            top: (itemHeight * styleProps.height) / 2 + 8,
            justifyContent: "center",
            color: unfavorableColor,
            fontSize: 20,
            fontWeight: 500
        };
    },
    ppValuePositive: (styleProps: CellTypeImageStyleProps) => {
        return {
            display: "flex",
            width: styleProps.width * itemWidth,
            maxWidth: styleProps.width * itemWidth,
            minWidth: styleProps.width * itemWidth,
            position: "absolute",
            top: (itemHeight * styleProps.height) / 2 + 8,
            justifyContent: "center",
            color: favorableColor,
            fontSize: 20,
            fontWeight: 500
        };
    },
    ppValueNA: (styleProps: CellTypeImageStyleProps) => {
        return {
            display: "flex",
            width: styleProps.width * itemWidth,
            maxWidth: styleProps.width * itemWidth,
            minWidth: styleProps.width * itemWidth,
            position: "absolute",
            top: (itemHeight * styleProps.height) / 2 + 8,
            justifyContent: "center",
            color: darkGreyColor,
            fontSize: 20,
            fontWeight: 400
        };
    },
    cellText: (styleProps: CellTypeImageStyleProps) => {
        return {
            display: "block",
            width: styleProps.width * itemWidth * 0.8,
            maxWidth: styleProps.width * itemWidth * 0.8,
            textAlign: "center",
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            fontSize: styleProps.height > 4 ? 20 : 16,
            fontWeight: 500,
            paddingBottom: 8
        };
    }
}));

const defaultLabelStyle = {
    fontSize: "8px",
    fontFamily: "sans-serif"
};

const CellSingleDimensionOrItem = (props: Props): ReactElement => {
    const { item, dashboardData, testid } = props;
    const classes = useStyles({
        xPosition: item.xPosition,
        yPosition: item.yPosition,
        numberOfColumn: GRID_COL_LENGTH,
        numberOfRow: GRID_ROW_LENGTH,
        height: item.height,
        width: item.width
    });
    const { lang, languageCode } = useLang();

    const getPPValue = (item: DashboardItemData | DashboardSectionData): string => {
        if (item.previousPeriodDeltas.length === 0) return "";
        const ppValue = item.previousPeriodDeltas[0];
        if (ppValue === 0) return "0";
        if (!ppValue) return "n/a";
        if (ppValue > 0) {
            return `+${ppValue}`;
        } else return ppValue.toString();
    };

    const ppValuePositive = (item: DashboardItemData | DashboardSectionData): boolean => {
        if (item.previousPeriodDeltas.length === 0) return false;
        const ppValue = item.previousPeriodDeltas[0];
        if (ppValue && ppValue > 0) return true;
        return false;
    };
    const ppValueNA = (item: DashboardItemData | DashboardSectionData): boolean => {
        if (item.previousPeriodDeltas.length === 0) return true;
        const ppValue = item.previousPeriodDeltas[0];
        if (!ppValue) return true;
        return false;
    };

    const getDonutChart = (id: number, isSection: boolean): ReactElement => {
        const sectionOrItemFound = isSection ? dashboardData.sections[id] : dashboardData.items[id];
        if (sectionOrItemFound) {
            return (
                <div className={classes.pieChart}>
                    <PieChart
                        data={[
                            {
                                title: lang.favorable,
                                value: sectionOrItemFound.favorablePercent,
                                color: favorableColor
                            },
                            { title: lang.neutral, value: sectionOrItemFound.neutralPercent, color: neutralColor },
                            {
                                title: lang.unfavorable,
                                value: sectionOrItemFound.unfavorablePercent,
                                color: unfavorableColor
                            }
                        ]}
                        label={({ dataEntry }): string =>
                            Math.round(dataEntry.value) > 0 ? Math.round(dataEntry.value).toString() : ""
                        }
                        startAngle={180}
                        labelStyle={defaultLabelStyle}
                        lineWidth={35}
                        labelPosition={82}
                    />
                    <div className={classes.pp}>
                        {dashboardData.previousPeriodLabels.length > 0
                            ? `+/- ${dashboardData.previousPeriodLabels[0]}`
                            : ""}
                    </div>
                    <div
                        className={
                            ppValueNA(sectionOrItemFound)
                                ? classes.ppValueNA
                                : ppValuePositive(sectionOrItemFound)
                                  ? classes.ppValuePositive
                                  : classes.ppValueNegative
                        }
                    >
                        {getPPValue(sectionOrItemFound)}
                    </div>
                </div>
            );
        } else {
            return <></>;
        }
    };

    return (
        <div className={classes.cell} data-testid={`panel-${testid}`}>
            <Tooltip
                title={item.translations.filter(t => t.languageCode === languageCode)[0]?.name ?? ""}
                placement={"bottom"}
            >
                <Typography variant="body2" className={classes.cellText} style={{ fontWeight: 500 }}>
                    {item.translations.filter(t => t.languageCode === languageCode)[0]?.name ?? ""}
                </Typography>
            </Tooltip>
            {getDonutChart(JSON.parse(item.props).id, JSON.parse(item.props).isSection)}
        </div>
    );
};

export default CellSingleDimensionOrItem;
