import { sortArray } from "./SortArray.helper";
import { SurveyInfo } from "components/admin/results/interfaces";
import { MapSurveyToAutocompleteOption } from "components/reports/layout/ReportsLayout";

export const getMapSurvey = (survey: SurveyInfo) => {
    return { id: survey.id, label: survey.translations[0].name };
};

export const mapSurveysToAutocompleteOption = (
    surveys: SurveyInfo[],
    includeAll: boolean = false
): MapSurveyToAutocompleteOption[] => {
    const mapsurveys = surveys
        .filter(includeAll ? s => s.active : s => s.active && s.allowInReports)
        .map((survey: SurveyInfo) => {
            return getMapSurvey(survey);
        });
    return sortArray(mapsurveys, "label", "asc");
};

export const getMapSurveySelected = (mapSurveys: MapSurveyToAutocompleteOption[], selectedSurveyId: number) => {
    const found = mapSurveys.find(item => item.id === selectedSurveyId);
    if (found) {
        return found;
    }
    return {
        id: -1,
        label: ""
    };
};
