import React, { ReactElement } from "react";
import { Typography } from "lib/typography";

import { useLang } from "core/hooks";

const ShowBelowConfidentialityMessage = (): ReactElement => {
    const { lang } = useLang();

    return (
        <>
            <Typography variant="h6" mt={3} ml={3} fontWeight="medium">
                {lang.confidentialityWarning}
            </Typography>
            <Typography variant="body2" mt={1.5} ml={3} mr={3}>
                {lang.confidentialityWarningDescription}
            </Typography>
        </>
    );
};

export default ShowBelowConfidentialityMessage;
