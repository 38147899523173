import { gql } from "@apollo/client";

export const organizationsQuery = gql`
    query organizations {
        organizations {
            id
            name
            active
            created
            tags {
                id
                name
            }
            isDashboardEnabled
            organizationLogo {
                id
                logoUrl
                originalFileName
            }
            isAIEnabled
        }
    }
`;
