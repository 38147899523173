import React, { ReactElement, useRef } from "react";
import { Typography } from "lib/typography";

import { useLang } from "core/hooks";
import { useStyles } from "./comments-list.style";
import { emptySurveyInfo } from "components/admin/results/init";
import { CommentState } from "../interface";
import { CommentsDetail } from "managerPortal/components/comments/CommentsDetail";

import { ConfidentialityResult } from "managerPortal/components/snapshotReport/enums";
import ShowTooSimilarConfidentialityMessage from "../ShowTooSimilarConfidentialityMessage";
import ShowBelowConfidentialityMessage from "../ShowBelowConfidentialityMessage";

import StackedBar from "managerPortal/components/stackedBar/StackedBar";
import { errorColor, neutralColor, successColor } from "core/styles/ColorsDefinition";
import { CommentDetailsLegend } from "../CommentDetailsLegend";
import { getUserSettings } from "../helpers/get-user-settings";
import { Chip } from "lib/chip";
import { SearchType } from "components/reports/comment/Comment";
import { useUser } from "core/context/user/useUser";

export enum SentimentCalculationStatus {
    notCalculated,
    calculating,
    completed
}

export enum AutoTranslationCalculationStatus {
    notCalculated,
    calculating,
    completed
}

type Props = {
    sentimentScoreCalculationStatus: CommentState["sentimentScoreCalculationStatus"];
    overallSentimentScore: CommentState["overallSentimentScore"];
    confidentialityResult: CommentState["confidentialityResult"];
    sentimentScoreNeutralThreshold: CommentState["sentimentScoreNeutralThreshold"];
    comments: CommentState["comments"];
    searchTerm: CommentState["searchTerm"];
    commentLength: CommentState["commentLength"];
    hasMore: CommentState["hasMore"];
    showCommentDialog: boolean;
    fetchMoreData: () => void;
    commentsNumberPerSection: string;
    searchType: SearchType;
};

export const CommentsList = ({
    sentimentScoreCalculationStatus,
    overallSentimentScore,
    confidentialityResult,
    sentimentScoreNeutralThreshold,
    comments,
    searchTerm,
    showCommentDialog,
    commentLength,
    hasMore,
    fetchMoreData,
    commentsNumberPerSection,
    searchType
}: Props): ReactElement => {
    const { lang } = useLang();
    const { user } = useUser();
    const userSettings = getUserSettings(user);
    const scrollTarget = useRef<HTMLDivElement>(null);

    const classes = useStyles({
        showSentimentScore:
            userSettings.showSentimentScores && sentimentScoreCalculationStatus === SentimentCalculationStatus.completed
                ? true
                : false,
        showDimensionScore: userSettings.showSectionScore ? true : false
    });

    if (user.settings.comment.surveySelected === emptySurveyInfo.id && !showCommentDialog) {
        return (
            <div className={classes.pivotGridWrapper}>
                <Typography pt={1} pl={3}>
                    {lang.noSearchResults}
                </Typography>
            </div>
        );
    }

    return (
        <div className={classes.commentDetails}>
            {confidentialityResult === ConfidentialityResult.success && commentsNumberPerSection !== "" ? (
                <div className={classes.commentDetailsHeader}>
                    <Typography variant="h6">{commentsNumberPerSection}</Typography>
                    {userSettings.minWordsPerComment > 1 ? (
                        <Chip
                            className={classes.minimumWordChip}
                            data-testid={"minimum-word-chip"}
                            label={`${lang.minimum} ${userSettings.minWordsPerComment} ${lang.words}`}
                        />
                    ) : (
                        <></>
                    )}
                    {userSettings.commentsRandomSampleCount && userSettings.commentsRandomSampleCount > 0 ? (
                        <Chip
                            className={classes.minimumWordChip}
                            data-testid={"random-sample-word-chip"}
                            label={`${lang.randomSample} ${userSettings.commentsRandomSampleCount} ${lang.commentsLowerCase}`}
                        />
                    ) : (
                        <></>
                    )}
                </div>
            ) : confidentialityResult === ConfidentialityResult.success && userSettings.minWordsPerComment > 1 ? (
                <div className={classes.commentDetailsHeader}>
                    <Chip
                        className={classes.minimumWordChip}
                        data-testid={"minimum-word-chip"}
                        label={`${lang.minimum} ${userSettings.minWordsPerComment} ${lang.words}`}
                    />
                </div>
            ) : (
                <></>
            )}
            {userSettings.showSentimentScores &&
                confidentialityResult === ConfidentialityResult.success &&
                sentimentScoreCalculationStatus === SentimentCalculationStatus.completed && (
                    <div className={classes.commentDetailsStackedBar}>
                        <Typography className={classes.stackBarHeader}>{lang.overallSentiment}</Typography>
                        <div className={classes.stackedBarWrapper}>
                            <CommentDetailsLegend />
                            <div className={classes.stackedBar}>
                                <StackedBar
                                    values={overallSentimentScore}
                                    colors={[errorColor, neutralColor, successColor]}
                                    displayAllPercentage={true}
                                    percentageFontSize={14}
                                    fromComment={true}
                                />
                            </div>
                        </div>
                    </div>
                )}
            <div className={classes.comments} data-testid="comments" id="scrollableDiv" ref={scrollTarget}>
                {confidentialityResult === ConfidentialityResult.tooSimilar ? (
                    <div className={classes.pivotGridWrapper}>
                        <ShowTooSimilarConfidentialityMessage />
                    </div>
                ) : confidentialityResult === ConfidentialityResult.belowThreshold ? (
                    <div className={classes.pivotGridWrapper}>
                        <ShowBelowConfidentialityMessage />
                    </div>
                ) : (
                    <div>
                        <CommentsDetail
                            comments={comments.slice(0, commentLength)}
                            hasMore={hasMore}
                            fetchMoreData={fetchMoreData}
                            searchTerm={searchTerm}
                            sentimentScoreNeutralThreshold={sentimentScoreNeutralThreshold}
                            showSentimentScore={userSettings.showSentimentScores}
                            showSectionScore={userSettings.showSectionScore}
                            searchType={searchType}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};
