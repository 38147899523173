import React, { ReactElement, useState, useEffect, useCallback } from "react";
import FileCopy from "@mui/icons-material/FileCopy";
import Sync from "@mui/icons-material/Sync";
import Highlighter from "react-highlight-words";
import { RegenerateCommentSummaryPayload } from "api/rest/interfaces/comments-service";
import { filterConnectorToBackEnd } from "components/filters/helper";
import { useLang, useCommentsService } from "core/hooks";
import { useCommentSummaryStyles } from "./comments-summary.style";
import { CommentsSummaryPayload } from "api/rest/interfaces";
import { Filter } from "components/filters/interfaces";
import { CommentSummaryStatus } from "managerPortal/components/comments/interfaces";
import Loading from "components/shared/Loading";
import { CommentState } from "../interface";
import { ConfidentialityResult } from "managerPortal/components/snapshotReport/enums";
import ShowTooSimilarConfidentialityMessage from "../ShowTooSimilarConfidentialityMessage";
import ShowBelowConfidentialityMessage from "../ShowBelowConfidentialityMessage";
import { Typography } from "lib/typography";
import { IconButton } from "lib/icon-button";
import { Snackbar } from "lib/snackbar";
import ConfirmationDialog from "components/shared/ConfirmationDialog";
import { Tooltip } from "lib/tooltip";

type Props = {
    surveyId: number;
    sectionOrItemTitle: string;
    currentFilter: Filter;
    searchTerm: string;
    itemIds: number[];
    summaryLoadingStatus: boolean;
    updateSummaryLoadingStatus: (status: boolean) => void;
    confidentialityResult: CommentState["confidentialityResult"];
};

export const CommentsSummary = ({
    surveyId,
    sectionOrItemTitle,
    currentFilter,
    searchTerm,
    itemIds,
    summaryLoadingStatus,
    updateSummaryLoadingStatus,
    confidentialityResult
}: Props): ReactElement => {
    const classes = useCommentSummaryStyles();
    const { lang, languageCode } = useLang();
    const commentsService = useCommentsService();
    const [summary, setSummary] = useState<string>("");
    const [suggestion, setSuggestion] = useState<string>("");
    const [snackbar, setSnackbar] = useState({ isOpen: false, message: "" });
    const [summaryId, setSummaryId] = useState<number>(0);
    const [suggestionId, setSuggestionId] = useState<number>(0);
    const [regenerateSummaryIndicator, setRegenerateSummaryIndicator] = useState<boolean>(false);
    const [confirmImportDialogOpen, setConfirmImportDialogOpen] = useState(false);
    const [isCopied, setIsCopied] = useState<boolean>(false);
    const [canRegenerateSummary, setCanRegenerateSummary] = useState<boolean>(false);

    const copyToClipboard = (): void => {
        setIsCopied(true);
        setTimeout(() => {
            setIsCopied(false);
        }, 1500);
        navigator.clipboard.writeText(
            `${lang.commonTopicsFrom} ${sectionOrItemTitle}:
${summary}


${lang.commonSuggestionFrom} ${sectionOrItemTitle}:
${suggestion}`
        );
    };

    const getStatus = useCallback((): void => {
        let timerId: NodeJS.Timeout;
        const commentsSummaryPayload: CommentsSummaryPayload = {
            surveyId: surveyId,
            hierarchyId: 0,
            cardId: 0,
            filters: filterConnectorToBackEnd(currentFilter.items),
            languageCode,
            itemIds,
            title: ""
        };
        commentsService
            .getCommentSummary(commentsSummaryPayload)
            .then(status => {
                if (
                    status &&
                    status.suggestionStatus === CommentSummaryStatus.Completed &&
                    status.summaryStatus === CommentSummaryStatus.Completed
                ) {
                    updateSummaryLoadingStatus(false);
                    if (timerId) {
                        clearTimeout(timerId);
                    }
                    setSummary(status.summary);
                    setSuggestion(status.suggestions);
                    setCanRegenerateSummary(status.canRegenerate);
                    setSuggestionId(status.suggestionId);
                    setSummaryId(status.summaryId);
                } else {
                    updateSummaryLoadingStatus(true);
                    timerId = setTimeout(() => getStatus(), 3000);
                }
            })
            .catch(() => {
                if (timerId) {
                    clearTimeout(timerId);
                }
                setSnackbar({ isOpen: true, message: lang.somethingWentWrong });
                updateSummaryLoadingStatus(false);
            });
    }, [currentFilter.items, surveyId, JSON.stringify(itemIds), regenerateSummaryIndicator]);

    const getHighlitedSummary = (summary: string): ReactElement => {
        return (
            <>
                <Highlighter
                    highlightClassName={classes.highlightWord}
                    searchWords={searchTerm ? [searchTerm] : []}
                    caseSensitive={false}
                    autoEscape={true}
                    textToHighlight={summary}
                />
            </>
        );
    };

    const handleCloseSnackbar = (): void => {
        setSnackbar({ isOpen: false, message: "" });
    };

    const regenerateSummary = (): void => {
        if (summaryId && suggestionId) {
            const payload: RegenerateCommentSummaryPayload = {
                summaryId,
                suggestionId,
                itemIds,
                filters: filterConnectorToBackEnd(currentFilter.items)
            };
            updateSummaryLoadingStatus(true);
            commentsService
                .regenerateCommentSummary(payload)
                .then(() => {
                    setRegenerateSummaryIndicator(!regenerateSummaryIndicator);
                })
                .catch(() => setSnackbar({ isOpen: true, message: lang.somethingWentWrong }));
        }
    };

    useEffect(() => {
        getStatus();
    }, [currentFilter.items, surveyId, JSON.stringify(itemIds), regenerateSummaryIndicator]);

    if (summaryLoadingStatus) {
        return (
            <Loading styleProps={{ background: "white" }} zIndex={1000} borderLeft>
                <Typography>{lang.loadingCommentsSummary}</Typography>
            </Loading>
        );
    }

    return (
        <>
            {confidentialityResult === ConfidentialityResult.tooSimilar ? (
                <div className={classes.pivotGridWrapper}>
                    <ShowTooSimilarConfidentialityMessage />
                </div>
            ) : confidentialityResult === ConfidentialityResult.belowThreshold ? (
                <div className={classes.pivotGridWrapper}>
                    <ShowBelowConfidentialityMessage />
                </div>
            ) : (
                <div className={classes.summary}>
                    <div className={classes.summaryHeader}>
                        <Typography
                            pl={"12px"}
                            pr={"12px"}
                            variant="h6"
                            style={{ fontWeight: 500, fontSize: 16 }}
                        >{`${lang.commonTopicsFrom} ${sectionOrItemTitle}`}</Typography>
                        <div className={classes.summaryHeaderRight}>
                            {canRegenerateSummary && (
                                <div className={classes.summaryHeaderRightDetails}>
                                    <Typography>{lang.newCommentsSubmitted}</Typography>
                                    <Tooltip title={lang.regenerate} placement="bottom">
                                        <IconButton
                                            className={classes.settingIcon}
                                            onClick={() => setConfirmImportDialogOpen(true)}
                                            id="btn-summary-regenerate"
                                            color="primary"
                                        >
                                            <Sync style={{ fontSize: "1.8rem" }} />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                            )}
                            <Tooltip
                                title={isCopied ? lang.copied : lang.copyToClipboradCaseSensitive}
                                placement="bottom"
                            >
                                <IconButton
                                    className={classes.settingIcon}
                                    onClick={copyToClipboard}
                                    id="btn-summary-copy-to-clipBoard"
                                    color="primary"
                                >
                                    <FileCopy />
                                </IconButton>
                            </Tooltip>
                        </div>
                    </div>
                    <Typography pl={"24px"} pr={"24px"} pt={"8px"} pb={"8px"} style={{ whiteSpace: "pre-wrap" }}>
                        {getHighlitedSummary(summary)}
                    </Typography>
                    <div className={classes.suggestionHeader}>
                        <Typography
                            pl={"12px"}
                            pr={"12px"}
                            variant="h6"
                            style={{ fontWeight: 500, fontSize: 16 }}
                        >{`${lang.commonSuggestionFrom} ${sectionOrItemTitle}`}</Typography>
                    </div>
                    <Typography pl={"24px"} pr={"24px"} pt={"8px"} pb={"8px"} style={{ whiteSpace: "pre-wrap" }}>
                        {getHighlitedSummary(suggestion)}
                    </Typography>
                </div>
            )}
            <Snackbar open={snackbar.isOpen} message={snackbar.message} handleClose={handleCloseSnackbar} />
            {confirmImportDialogOpen && (
                <ConfirmationDialog
                    onCancelClicked={(): void => {
                        setConfirmImportDialogOpen(false);
                    }}
                    open={confirmImportDialogOpen}
                    onConfirmationClicked={(): void => {
                        setConfirmImportDialogOpen(false);
                        regenerateSummary();
                    }}
                    title={lang.confirmAction}
                    message={lang.confirmRegenerateCommentSummary[0]}
                    submessage={lang.confirmRegenerateCommentSummary[1]}
                    confirmButtonText={lang.ok}
                    cancelButtonVariant="text"
                />
            )}
        </>
    );
};
