import React, { ReactElement, useEffect, useReducer, useState } from "react";
import { Typography } from "lib/typography";
import saveAs from "file-saver";
import { useLazyQuery, useMutation } from "@apollo/client";

import NoSurveySelected from "assets/images/emptyPages/ReportingEmptyState.svg";
import {
    useLang,
    useSurveys,
    useLoading,
    useHeatmapService,
    useQueryWithPromise,
    useUpdateUserSettings,
    useOrgChartService
} from "core/hooks";
import { heatmapOnLoad } from "api/queries";
import { Select, SelectChangeEvent } from "lib/select-custom";
import { emptySurveyInfo } from "components/admin/results/init";
import { SurveyInfo } from "components/admin/results/interfaces";
import Error500 from "components/errorPage/Error500";
import { ComponentStatus } from "core/enums";
import { initFilter } from "components/filters/inits";
import { DemographicOptions, HeatmapState, OverallValue } from "./interface";
import { heatmapReducer } from "./reducer";
import { HeatmapActions } from "./action";
import {
    ConfidentialityResult,
    defaultCompareOption,
    filteredOverallOption,
    previousPeriodOption,
    initCompareTarget,
    initHeatmapDisplayValue,
    initHeatmapRestoreValue
} from "./heatmap.init";
import {
    heatmapValueMapper,
    heatmapSectionMapper,
    heatmapItemOverallMapper,
    heatmapSectionOverallMapper
} from "./helper";
import { useHeatMapStyles } from "./heatmap.style";
import EmptyPage from "components/shared/EmptyPage";
import { initBreakdownItem, initBreakdownItems } from "../responseRate/init";
import {
    copyArrayWithObjects,
    sortArray,
    DemographicCompareFilterMapper,
    mapSurveysToAutocompleteOption,
    getMapSurveySelected
} from "core/helpers";
import { BreakdownItem, BreakdownItems } from "components/shared";
import HeatGrid from "./HeatGrid";
import HeatGridAxis from "./HeatGridAxis";
import Filters from "components/filters/Filters";
import { FilterMode, FilterType } from "components/filters/enums";
import { Filter, FilterParameter } from "components/filters/interfaces";
import { userSettingMutation } from "api/mutations";
import { HrisFieldInfo } from "managerPortal/interfaces";
import { SurveySection } from "managerPortal/context/interfaces";
import { Translation } from "core/languages/interfaces";
import ShowConfidentialityMessage from "managerPortal/components/snapshotReport/ShowConfidentialityMessage";
import ShowNotEnoughResponsesMessage from "managerPortal/components/snapshotReport/ShowNotEnoughResponsesMessage";
import { ReportsLayout } from "components/reports/layout/ReportsLayout";
import { DialogBulkExportSlide } from "managerPortal/components/shared/DialogBulkExportSlide";
import { BulkExportDemographic } from "managerPortal/components/shared/DialogExportSlideWithLookdown";
import { BulkReportType } from "api/rest/BulkReportStatus";
import { UserSettings } from "components/admin/users/interface";
import { Snackbar } from "lib/snackbar";
import { Autocomplete, AutocompleteValue } from "lib/autocomplete";
import { Box } from "lib/box";
import { HeatmapComparison } from "../../../api/rest/interfaces/HeatmapService.interface";
import { useUser } from "core/context/user/useUser";

const initialState = (lang: Translation): HeatmapState => {
    return {
        selectedSurvey: emptySurveyInfo,
        surveyItemsOptions: [],
        breakdownItems: initBreakdownItems,
        contactFieldsOptions: [],
        status: ComponentStatus.idle,
        currentFilter: initFilter,
        isFilterDialogOpen: false,
        isExportDialogOpen: false,
        gridData: [initHeatmapDisplayValue],
        sectionData: [],
        sectionOverall: [],
        itemOverall: [],
        currentSelectedId: initBreakdownItem.id,
        rawData: [],
        responseCount: [],
        compareTargets: [],
        selectedCompareOption: defaultCompareOption(lang),
        selectedCompareTarget: initCompareTarget,
        contactFieldsValues: [],
        compareOptions: [defaultCompareOption(lang)],
        confidentialityResult: ConfidentialityResult.success,
        snackBar: { isOpen: false, message: "" }
    };
};

const Heatmap = (): ReactElement => {
    const heatmapService = useHeatmapService();
    const { lang, languageCode } = useLang();
    const { surveys } = useSurveys();
    const orgChartService = useOrgChartService();
    const { setLoading } = useLoading();
    const { user, setUser } = useUser();
    const { setInitUserSetting, setUserSettingAfterFilterApplied } = useUpdateUserSettings();
    const [state, dispatch] = useReducer(heatmapReducer, initialState(lang));
    const [isDataReady, setDataReady] = useState<boolean>(
        state.gridData[0].delta !== initHeatmapDisplayValue.delta &&
            state.breakdownItems.items[0].id !== initBreakdownItem.id &&
            state.status !== ComponentStatus.loading
    );
    const [shouldGridUpdate, setShouldGridUpdate] = useState<boolean>(false);
    const [switchAxis, setSwitchAxis] = useState<boolean>(true);
    const [contactFields, setContactFields] = useState<HrisFieldInfo[]>([]);
    const [isBulkExportDialogOpen, setBulkExportDialogOpen] = useState(false);
    const [bulkExportDemographic, setBulkExportDemographic] = useState<BulkExportDemographic>({
        demographicFieldId: -1,
        demographicField: ""
    });
    const [showDeltaValue, setShowDeltaValue] = useState<boolean>(true);
    const [enablePreviousPeriod, setEnablePreviousPeriod] = useState<boolean>(false);

    const fetchHris = useQueryWithPromise(heatmapOnLoad);
    const defaultOption = defaultCompareOption(lang);
    const filteredOverall = filteredOverallOption(lang);
    const previousPeriod = previousPeriodOption(lang);
    const classes = useHeatMapStyles();
    const mapSurveys = mapSurveysToAutocompleteOption([...surveys, emptySurveyInfo]);
    const selectedMapSurvey = getMapSurveySelected(mapSurveys, state.selectedSurvey.id);

    const showEmptyPage = (): boolean => {
        if (surveys.length === 0) {
            return true;
        }

        if (!user.settings.heatmap) {
            return true;
        }

        if (user.settings.heatmap) {
            if (user.settings.heatmap.surveySelected === emptySurveyInfo.id) {
                return true;
            }
            if (surveys.find(s => s.id === user.settings.heatmap.surveySelected) === undefined) {
                return true;
            }
        }

        if (state.selectedSurvey.id === emptySurveyInfo.id) {
            return true;
        }
        return false;
    };

    const [updateUserSetting] = useMutation(userSettingMutation);
    const [loadBreakdownItems, { error: errorContactFieldsAndSurveyItems }] = useLazyQuery(heatmapOnLoad, {
        onCompleted: data => {
            if (data) {
                const hrisList: HrisFieldInfo[] = [...data.demographicFieldsAndValues].filter(
                    (field: HrisFieldInfo) => !field.isHidden
                );
                const sortedDemographic = sortArray(hrisList, "fieldName", "asc");
                setContactFields(sortedDemographic);
                const compareOptions =
                    sortedDemographic.length === 1 && enablePreviousPeriod
                        ? [defaultOption, filteredOverall, previousPeriod]
                        : sortedDemographic.length === 1 && !enablePreviousPeriod
                          ? [defaultOption, filteredOverall]
                          : enablePreviousPeriod
                            ? [
                                  defaultOption,
                                  filteredOverall,
                                  previousPeriod,
                                  ...sortedDemographic.map((contactField: { fieldId: number; fieldName: string }) => {
                                      return {
                                          name: contactField.fieldName,
                                          id: contactField.fieldId
                                      };
                                  })
                              ]
                            : [
                                  defaultOption,
                                  filteredOverall,
                                  ...sortedDemographic.map((contactField: { fieldId: number; fieldName: string }) => {
                                      return {
                                          name: contactField.fieldName,
                                          id: contactField.fieldId
                                      };
                                  })
                              ];
                dispatch({
                    type: HeatmapActions.LOAD_SECTION_HRIS,
                    payload: {
                        surveyItemsOptions: data.surveyItemFields,
                        contactFieldsOptions: sortArray(
                            sortedDemographic.map((contactField: { fieldId: number; fieldName: string }) => {
                                return {
                                    title: contactField.fieldName,
                                    id: contactField.fieldId
                                };
                            }),
                            "title",
                            "asc"
                        ),
                        contactFieldsValues: data.demographicFieldsAndValues,
                        compareOptions,
                        status: ComponentStatus.success
                    }
                });
            }
        }
    });

    const ActionItem = (props: { title: string; content: string; subTitle?: string }): JSX.Element => {
        return (
            <div className={classes.infoHeaderItem_middle}>
                <Typography variant="subtitle2">{props.title}</Typography>
                <Typography variant="body1">{props.content}</Typography>
                {props.subTitle && <Typography variant="subtitle2">{props.subTitle}</Typography>}
            </div>
        );
    };

    const updateUserSettings = (settings: UserSettings): void => {
        const mutationOptions = {
            variables: { settings: JSON.stringify(settings) }
        };
        updateUserSetting(mutationOptions);
        setUser({ settings });
    };

    const setUserSettings = (
        surveyId: number,
        filter: Filter,
        breakdownItems: BreakdownItems,
        optionId: number,
        targetId: number,
        targetName: string
    ): void => {
        const settings = {
            ...user.settings,
            filtersItems: filter.items,
            heatmap: {
                ...user.settings.heatmap,
                surveySelected: surveyId,
                breakdownItems: breakdownItems.items,
                selectedCompareOptionID: optionId,
                selectedCompareTargetID: targetId,
                selectedCompareTargetName: targetName,
                showFavorable: !showDeltaValue
            }
        };
        const mutationOptions = {
            variables: { settings: JSON.stringify(settings) }
        };
        updateUserSetting(mutationOptions);
        setUser({ settings });
    };

    const handleSurveySelected = (id: number): void => {
        const selectedSurvey = surveys.find((survey: SurveyInfo) => survey.id === id);
        if (selectedSurvey && selectedSurvey.id !== state.selectedSurvey.id) {
            heatmapService.getHeatmapSurveyInfo(id, initFilter.items).then(data => {
                setEnablePreviousPeriod(data.enablePreviousPeriod);
                dispatch({
                    type: HeatmapActions.SELECT_SURVEY,
                    payload: {
                        selectedSurvey,
                        currentSelectedId: initBreakdownItem.id,
                        currentFilter: initFilter,
                        breakdownItems: initBreakdownItems,
                        gridData: [initHeatmapDisplayValue],
                        sectionData: [],
                        rawData: [],
                        sectionOverall: [],
                        itemOverall: [],
                        responseCount: [],
                        contactFieldsOptions: [],
                        contactFieldsValues: [],
                        compareOptions: data.enablePreviousPeriod
                            ? [defaultOption, filteredOverall, previousPeriod]
                            : [defaultOption, filteredOverall],
                        selectedCompareOption: defaultOption,
                        compareTargets: [],
                        selectedCompareTarget: initCompareTarget,
                        status: ComponentStatus.loading
                    }
                });
                loadBreakdownItems({ variables: { surveyId: id, languageCode } });
            });
        }
        if (!user.settings.heatmap || id !== user.settings.heatmap.surveySelected) {
            setInitUserSetting(id);
        }
    };

    const handleOpenFilter = (): void => {
        dispatch({
            type: HeatmapActions.OPEN_CLOSE_FILTER,
            payload: { isFilterDialogOpen: true }
        });
    };

    const getComparisonType = (optionId: number): HeatmapComparison =>
        optionId === defaultOption.id
            ? HeatmapComparison.Overall
            : optionId === previousPeriod.id
              ? HeatmapComparison.Previous
              : optionId === filteredOverall.id
                ? HeatmapComparison.FilterRestricted
                : HeatmapComparison.FilterUnrestricted;

    const fetchHeatmapValue = (
        breakdownId: number,
        filter: Filter,
        surveyId: number,
        compareValue: string,
        surveySections: SurveySection[],
        optionId: number,
        showDelta: boolean
    ): void => {
        setShouldGridUpdate(true);
        if (surveyId !== emptySurveyInfo.id && breakdownId !== initBreakdownItem.id) {
            heatmapService
                .getHeatmap(
                    surveyId,
                    breakdownId,
                    filter.items,
                    languageCode,
                    DemographicCompareFilterMapper(optionId, compareValue, filter),
                    getComparisonType(optionId),
                    true
                )
                .then(response => {
                    if (response.overallResponseCount === undefined) {
                        dispatch({ type: HeatmapActions.SET_STATUS, payload: { status: ComponentStatus.error } });
                        setUser({
                            settings: {
                                ...user.settings,
                                filtersItems: initFilter.items,
                                heatmap: initHeatmapRestoreValue
                            }
                        });
                        return;
                    }
                    if (response.confidentialityResult !== ConfidentialityResult.success) {
                        dispatch({
                            type: HeatmapActions.SET_GRID_DATA,
                            payload: {
                                status: ComponentStatus.success,
                                gridData: [initHeatmapDisplayValue],
                                rawData: [],
                                sectionData: [],
                                sectionOverall: [],
                                itemOverall: [],
                                responseCount: [{ fieldName: lang.overallSS, count: response.overallResponseCount }],
                                confidentialityResult: response.confidentialityResult
                            }
                        });
                    } else if (response.heatMaps && response.heatMaps.length > 0) {
                        const responseCount = response.fieldResponseCount;
                        const gridData = heatmapValueMapper(response.heatMaps, surveySections, showDelta);
                        const sectionData = heatmapSectionMapper(
                            response.sectionHeatMaps,
                            surveySections,
                            showDelta !== undefined ? showDelta : showDeltaValue
                        );
                        const itemOverall_res = response.overallItems.map(
                            (item: { itemId: number; value: number }): OverallValue => {
                                return {
                                    id: item.itemId,
                                    delta: item.value
                                };
                            }
                        );
                        const sectionOverall_res = response.overallSections.map(
                            (section: { sectionId: number; value: number }): OverallValue => {
                                return {
                                    id: section.sectionId,
                                    delta: section.value
                                };
                            }
                        );

                        //push overall res
                        if (response.overallResponseCount && optionId !== previousPeriod.id)
                            responseCount.push({ fieldName: lang.overallSS, count: response.overallResponseCount });

                        const sectionOverall = heatmapSectionOverallMapper(sectionOverall_res, surveySections);

                        const itemOverall = heatmapItemOverallMapper(itemOverall_res, surveySections);

                        dispatch({
                            type: HeatmapActions.SET_GRID_DATA,
                            payload: {
                                status: ComponentStatus.success,
                                gridData,
                                rawData: response.heatMaps,
                                sectionData,
                                sectionOverall,
                                itemOverall,
                                responseCount,
                                confidentialityResult: response.confidentialityResult
                            }
                        });
                    } else {
                        dispatch({ type: HeatmapActions.SET_STATUS, payload: { status: ComponentStatus.success } });
                    }
                })
                .catch(() => {
                    dispatch({ type: HeatmapActions.SET_STATUS, payload: { status: ComponentStatus.error } });
                })
                .finally(() => {
                    dispatch({ type: HeatmapActions.SET_STATUS, payload: { status: ComponentStatus.success } });
                    setShouldGridUpdate(false);
                });
        } else {
            dispatch({ type: HeatmapActions.SET_STATUS, payload: { status: ComponentStatus.error } });
        }
    };

    const restoreHeatmap = async (
        surveyId: number,
        breakdownId: number,
        name: string,
        filterItems: FilterParameter[],
        selectedOptionId: number,
        selectedTargetId: number,
        enablePP: boolean
    ): Promise<void> => {
        dispatch({ type: HeatmapActions.SET_STATUS, payload: { status: ComponentStatus.loading } });
        const selectedSurvey = surveys.find((survey: SurveyInfo) => survey.id === surveyId);
        const loadedHris = await fetchHris({ surveyId: surveyId, languageCode });

        if (loadedHris) {
            const { data } = loadedHris;
            let restoredFilter = user.settings.filtersItems
                ? { ...state.currentFilter, items: filterItems }
                : initFilter;
            const updatedItems = { items: [{ id: breakdownId, name }], type: state.breakdownItems.type };

            const hrisList: HrisFieldInfo[] = [...data.demographicFieldsAndValues].filter(
                (field: HrisFieldInfo) => !field.isHidden
            );
            restoredFilter.items
                .filter(item => item.filterType === FilterType.contactField)
                .forEach(item => {
                    if (!hrisList.map(list => list.fieldId).includes(item.field.id as number)) {
                        restoredFilter = initFilter;
                    }
                });
            const sortedDemographic = sortArray(hrisList, "fieldName", "asc");
            setContactFields(sortedDemographic);

            const contactFieldFound: HrisFieldInfo = data.demographicFieldsAndValues.find(
                (c: HrisFieldInfo) => c.fieldId === selectedOptionId
            );

            const contactFieldOption = contactFieldFound
                ? { id: contactFieldFound.fieldId, name: contactFieldFound.fieldName }
                : selectedOptionId === filteredOverall.id
                  ? filteredOverall
                  : selectedOptionId === previousPeriod.id
                    ? previousPeriod
                    : defaultOption;

            const targets = !contactFieldFound
                ? []
                : contactFieldFound.fieldValues.map((value: string, index: number): DemographicOptions => {
                      return { name: value, id: index };
                  });
            const selectedTarget = targets.find((t: DemographicOptions) => t.id === selectedTargetId);

            const selectedTargetOption = selectedTarget ? selectedTarget : initCompareTarget;

            const compareOptions =
                sortedDemographic.length === 1 && enablePP
                    ? [defaultOption, filteredOverall, previousPeriod]
                    : sortedDemographic.length === 1 && !enablePP
                      ? [defaultOption, filteredOverall]
                      : enablePP
                        ? [
                              defaultOption,
                              filteredOverall,
                              previousPeriod,
                              ...sortedDemographic.map((contactField: { fieldId: number; fieldName: string }) => {
                                  return {
                                      name: contactField.fieldName,
                                      id: contactField.fieldId
                                  };
                              })
                          ]
                        : [
                              defaultOption,
                              filteredOverall,
                              ...sortedDemographic.map((contactField: { fieldId: number; fieldName: string }) => {
                                  return {
                                      name: contactField.fieldName,
                                      id: contactField.fieldId
                                  };
                              })
                          ];
            dispatch({
                type: HeatmapActions.RESTORE_USER_SETTINGS,
                payload: {
                    selectedSurvey,
                    breakdownItems: updatedItems,
                    currentFilter: restoredFilter,
                    surveyItemsOptions: data.surveyItemFields,
                    contactFieldsOptions: sortedDemographic.map(
                        (contactField: { fieldId: number; fieldName: string }) => {
                            return {
                                title: contactField.fieldName,
                                id: contactField.fieldId
                            };
                        }
                    ),
                    contactFieldsValues: data.demographicFieldsAndValues,
                    compareOptions
                }
            });
            setShouldGridUpdate(true);
            dispatch({
                type: HeatmapActions.SET_COMPARE_OPTIONS,
                payload: {
                    selectedCompareOption: contactFieldOption,
                    compareTargets: targets,
                    selectedCompareTarget: selectedTargetOption
                }
            });

            fetchHeatmapValue(
                breakdownId,
                restoredFilter,
                surveyId,
                selectedTargetOption.name,
                data.surveyItemFields,
                selectedOptionId,
                true
            );
        } else {
            dispatch({ type: HeatmapActions.SET_STATUS, payload: { status: ComponentStatus.success } });
        }
    };

    const handleSelectBreakdown = (value: AutocompleteValue | null): void => {
        if (!value) return;
        const { id, label } = value;

        setShouldGridUpdate(false);
        dispatch({ type: HeatmapActions.SET_STATUS, payload: { status: ComponentStatus.loading } });
        dispatch({ type: HeatmapActions.SET_CURRENT_BREAKDOWN, payload: { currentSelectedId: id } });
        const foundIndex = state.breakdownItems.items.findIndex((item: BreakdownItem) => item.id === id);
        let _updateBreakdownItems = [];
        if (foundIndex > -1) {
            _updateBreakdownItems = copyArrayWithObjects(state.breakdownItems.items);
        } else {
            _updateBreakdownItems.push({ id, name: label });
        }

        const updatedItems = { items: copyArrayWithObjects(_updateBreakdownItems), type: state.breakdownItems.type };
        setShouldGridUpdate(true);
        dispatch({
            type: HeatmapActions.SET_BREAKDOWN_ITEMS,
            payload: {
                breakdownItems: updatedItems
            }
        });

        setUserSettings(
            state.selectedSurvey.id,
            state.currentFilter,
            updatedItems,
            state.selectedCompareOption.id,
            state.selectedCompareTarget.id,
            state.selectedCompareTarget.name
        );

        if (id !== initBreakdownItem.id && state.selectedSurvey.id !== emptySurveyInfo.id) {
            fetchHeatmapValue(
                id,
                state.currentFilter,
                state.selectedSurvey.id,
                state.selectedCompareTarget.name,
                state.surveyItemsOptions,
                state.selectedCompareOption.id,
                showDeltaValue
            );
        }
    };

    const handleStartExport = (isBulkExport: boolean): void => {
        if (isBulkExport) {
            setBulkExportDialogOpen(true);
        } else {
            dispatch({ type: HeatmapActions.SET_STATUS, payload: { status: ComponentStatus.loading } });
            if (state.breakdownItems.items[0].id !== initBreakdownItem.id) {
                heatmapService
                    .exportToExcel(
                        state.selectedSurvey.id,
                        state.breakdownItems.items[0].id,
                        state.currentFilter.items,
                        languageCode,
                        DemographicCompareFilterMapper(
                            state.selectedCompareOption.id,
                            state.selectedCompareTarget.name,
                            state.currentFilter
                        ),
                        getComparisonType(state.selectedCompareOption.id),
                        switchAxis,
                        !showDeltaValue
                    )
                    .then((blob: unknown) => {
                        saveAs(blob as Blob, "heatmap.xlsx");
                        dispatch({ type: HeatmapActions.SET_STATUS, payload: { status: ComponentStatus.success } });
                    })
                    .catch(() => {
                        dispatch({ type: HeatmapActions.SET_STATUS, payload: { status: ComponentStatus.success } });
                    });
            }
        }
    };

    const handleBulkExport = (): void => {
        dispatch({
            type: HeatmapActions.SNACKBAR_OPEN_CLOSE,
            payload: { snackBar: { isOpen: true, message: lang.startHeatmapBulkExport } }
        });
        orgChartService
            .startBulkExport(
                state.selectedSurvey.id,
                bulkExportDemographic.demographicFieldId,
                languageCode,
                BulkReportType.Heatmap,
                user.settings
            )
            .then(() => setBulkExportDialogOpen(false));
    };

    const handleUpdateBulkExportDemographic = (updatedBulkExport: BulkExportDemographic): void => {
        setBulkExportDemographic(updatedBulkExport);
    };
    //end of sidebar actions

    //Filter start

    const handleCloseFilters = (): void => {
        dispatch({
            type: HeatmapActions.OPEN_CLOSE_FILTER,
            payload: { isFilterDialogOpen: false }
        });
    };

    const handleSelectCompareOption = (value: AutocompleteValue | null): void => {
        if (!value) return;
        const { id } = value;
        const contactFieldFound = state.contactFieldsValues.find(c => c.fieldId === id);

        let selectedOption = defaultOption;

        if (id === filteredOverall.id) {
            selectedOption = filteredOverall;
        }

        if (id === previousPeriod.id) {
            selectedOption = previousPeriod;
        }

        if (id === defaultOption.id || id === filteredOverall.id || id === previousPeriod.id) {
            dispatch({
                type: HeatmapActions.SET_COMPARE_OPTIONS,
                payload: {
                    selectedCompareOption: selectedOption,
                    compareTargets: [],
                    selectedCompareTarget: initCompareTarget
                }
            });
            setUserSettings(
                state.selectedSurvey.id,
                state.currentFilter,
                state.breakdownItems,
                id,
                initCompareTarget.id,
                initCompareTarget.name
            );

            if (state.responseCount.length > 0) {
                dispatch({ type: HeatmapActions.SET_STATUS, payload: { status: ComponentStatus.loading } });
                fetchHeatmapValue(
                    state.breakdownItems.items[0].id,
                    state.currentFilter,
                    state.selectedSurvey.id,
                    initCompareTarget.name,
                    state.surveyItemsOptions,
                    id,
                    showDeltaValue
                );
            }

            return;
        }

        if (contactFieldFound) {
            const options = contactFieldFound.fieldValues.map((value: string, index: number): DemographicOptions => {
                return { name: value, id: index };
            });

            const sortedOptions = sortArray(options, "name", "asc");

            const selectedCompareOption: DemographicOptions = {
                name: contactFieldFound.fieldName,
                id: contactFieldFound.fieldId
            };

            dispatch({
                type: HeatmapActions.SET_COMPARE_OPTIONS,
                payload: {
                    selectedCompareOption,
                    compareTargets: sortedOptions,
                    selectedCompareTarget: sortedOptions[0]
                }
            });

            setUserSettings(
                state.selectedSurvey.id,
                state.currentFilter,
                state.breakdownItems,
                id,
                sortedOptions[0].id,
                sortedOptions[0].name
            );

            if (state.responseCount.length > 0) {
                dispatch({ type: HeatmapActions.SET_STATUS, payload: { status: ComponentStatus.loading } });
                fetchHeatmapValue(
                    state.breakdownItems.items[0].id,
                    state.currentFilter,
                    state.selectedSurvey.id,
                    options[0].name,
                    state.surveyItemsOptions,
                    contactFieldFound.fieldId,
                    showDeltaValue
                );
            }
        }
    };

    const handleSelectCompareTarget = (event: SelectChangeEvent<unknown>): void => {
        let id = -1;
        const { value } = event.target;
        if (value !== undefined && value !== null) {
            id = +value;
        }
        const targetFound = state.compareTargets.find(t => t.id === id);
        if (targetFound) {
            setUserSettings(
                state.selectedSurvey.id,
                state.currentFilter,
                state.breakdownItems,
                state.selectedCompareOption.id,
                id,
                targetFound.name
            );

            dispatch({
                type: HeatmapActions.SELECT_COMPARE_TARGET,
                payload: {
                    selectedCompareTarget: targetFound
                }
            });

            const shouldRefetch =
                (state.gridData.length > 0 && state.gridData[0].delta !== initHeatmapDisplayValue.delta) ||
                state.confidentialityResult !== ConfidentialityResult.success;

            if (shouldRefetch) {
                dispatch({ type: HeatmapActions.SET_STATUS, payload: { status: ComponentStatus.loading } });

                fetchHeatmapValue(
                    state.breakdownItems.items[0].id,
                    state.currentFilter,
                    state.selectedSurvey.id,
                    targetFound.name,
                    state.surveyItemsOptions,
                    state.selectedCompareOption.id,
                    showDeltaValue
                );
            }
        }
    };

    const updateHeatmapData = (filter: Filter): void => {
        dispatch({ type: HeatmapActions.SET_STATUS, payload: { status: ComponentStatus.loading } });

        if (state.breakdownItems.items[0].id === initBreakdownItem.id) {
            dispatch({
                type: HeatmapActions.SET_FILTER,
                payload: { status: ComponentStatus.success, currentFilter: filter }
            });
            setUserSettingAfterFilterApplied(filter);
            return;
        }
        dispatch({
            type: HeatmapActions.SET_FILTER,
            payload: { status: ComponentStatus.loading, currentFilter: filter }
        });
        loadBreakdownItems({ variables: { surveyId: state.selectedSurvey.id, languageCode } });
        setUserSettingAfterFilterApplied(filter);
        handleCloseFilters();

        heatmapService.getHeatmapSurveyInfo(state.selectedSurvey.id, filter.items).then(data => {
            setEnablePreviousPeriod(data.enablePreviousPeriod);
            fetchHeatmapValue(
                state.breakdownItems.items[0].id,
                filter,
                state.selectedSurvey.id,
                state.selectedCompareTarget.name,
                state.surveyItemsOptions,
                state.selectedCompareOption.id,
                showDeltaValue
            );
        });
    };

    const handleBulkExportDialogClose = (): void => {
        setBulkExportDialogOpen(false);
    };

    //Switch Axis state
    const handleSwitchAxis = (): void => {
        const updatedSettings = {
            ...user.settings,
            heatmap: {
                ...user.settings.heatmap,
                swapAxes: !switchAxis
            }
        };
        updateUserSettings(updatedSettings);
        setSwitchAxis(!switchAxis);
    };

    const handleSwitchDelta = (a: boolean): void => {
        setShowDeltaValue(a);
        dispatch({ type: HeatmapActions.SET_STATUS, payload: { status: ComponentStatus.loading } });
        const updatedSettings = {
            ...user.settings,
            heatmap: {
                ...user.settings.heatmap,
                showFavorable: !a
            }
        };
        updateUserSettings(updatedSettings);
        fetchHeatmapValue(
            state.breakdownItems.items[0].id,
            state.currentFilter,
            state.selectedSurvey.id,
            state.selectedCompareTarget.name,
            state.surveyItemsOptions,
            state.selectedCompareOption.id,
            a
        );
    };

    const handleCloseSnackbar = (): void => {
        dispatch({
            type: HeatmapActions.SNACKBAR_OPEN_CLOSE,
            payload: { snackBar: { isOpen: false, message: "" } }
        });
    };

    const selectedBreakdownItem = (id: number) => {
        const found = state.contactFieldsOptions.find(s => s.id === id);
        if (found) {
            return {
                id: found.id,
                label: found.title
            };
        }
        return {
            id: -1,
            label: ""
        };
    };

    useEffect(() => {
        dispatch({ type: HeatmapActions.SET_STATUS, payload: { status: ComponentStatus.loading } });
        const restoredState = user.settings.heatmap;

        if (
            restoredState &&
            restoredState.surveySelected !== emptySurveyInfo.id &&
            mapSurveys.find(s => s.id === restoredState.surveySelected) !== undefined
        ) {
            const restoredFilter = user.settings.filtersItems
                ? { ...initFilter, items: user.settings.filtersItems }
                : initFilter;
            heatmapService.getHeatmapSurveyInfo(restoredState.surveySelected, restoredFilter.items).then(response => {
                setEnablePreviousPeriod(response.enablePreviousPeriod);
                if (restoredState.breakdownItems[0].id !== initBreakdownItem.id) {
                    const { id, name } = restoredState.breakdownItems[0];
                    dispatch({ type: HeatmapActions.SET_CURRENT_BREAKDOWN, payload: { currentSelectedId: id } });
                    const checkContactField = async (): Promise<void> => {
                        const loadedHris = await fetchHris({ surveyId: restoredState.surveySelected, languageCode });
                        if (loadedHris) {
                            const { data } = loadedHris;
                            const hrisList: HrisFieldInfo[] = [...data.demographicFieldsAndValues].filter(
                                (field: HrisFieldInfo) => !field.isHidden
                            );
                            if (hrisList.find(list => list.fieldId === id)) {
                                restoreHeatmap(
                                    restoredState.surveySelected,
                                    id,
                                    name,
                                    user.settings.filtersItems,
                                    restoredState.selectedCompareOptionID !== undefined
                                        ? restoredState.selectedCompareOptionID
                                        : defaultOption.id,
                                    restoredState.selectedCompareTargetID !== undefined
                                        ? restoredState.selectedCompareTargetID
                                        : initCompareTarget.id,
                                    response.enablePreviousPeriod
                                );
                            }
                        }
                    };
                    checkContactField();
                } else {
                    const selectedSurvey = surveys.find(
                        (survey: SurveyInfo) => survey.id === user.settings.heatmap.surveySelected
                    );
                    dispatch({
                        type: HeatmapActions.SELECT_SURVEY,
                        payload: {
                            selectedSurvey: selectedSurvey,
                            currentSelectedId: initBreakdownItem.id,
                            currentFilter: restoredFilter,
                            breakdownItems: initBreakdownItems,
                            gridData: [initHeatmapDisplayValue],
                            sectionData: [],
                            rawData: [],
                            sectionOverall: [],
                            itemOverall: [],
                            responseCount: [],
                            contactFieldsOptions: [],
                            contactFieldsValues: [],
                            compareOptions: response.enablePreviousPeriod
                                ? [defaultOption, filteredOverall, previousPeriod]
                                : [defaultOption, filteredOverall],
                            selectedCompareOption: defaultOption,
                            compareTargets: [],
                            selectedCompareTarget: initCompareTarget
                        }
                    });
                    loadBreakdownItems({ variables: { surveyId: selectedSurvey?.id, languageCode } });
                    dispatch({ type: HeatmapActions.SET_STATUS, payload: { status: ComponentStatus.success } });
                }
            });
        } else {
            dispatch({ type: HeatmapActions.SET_STATUS, payload: { status: ComponentStatus.success } });
        }
    }, []);

    useEffect(() => {
        setDataReady(
            state.gridData[0].delta !== initHeatmapDisplayValue.delta &&
                state.breakdownItems.items[0].id !== initBreakdownItem.id &&
                state.status === ComponentStatus.success &&
                state.sectionData.length > 0
        );
    }, [
        state.status,
        state.breakdownItems,
        state.gridData.length,
        state.sectionData,
        state.sectionOverall,
        state.itemOverall
    ]);

    useEffect(() => {
        if (
            state.selectedSurvey.id !== emptySurveyInfo.id &&
            state.breakdownItems.items[0].id !== initBreakdownItem.id
        ) {
            restoreHeatmap(
                state.selectedSurvey.id,
                state.breakdownItems.items[0].id,
                state.breakdownItems.items[0].name,
                state.currentFilter.items,
                state.selectedCompareOption.id,
                state.selectedCompareTarget.id,
                enablePreviousPeriod
            );
        }
    }, [languageCode]);

    useEffect(() => {
        if (state.status === ComponentStatus.loading) {
            setLoading(true);
        } else {
            setLoading(false);
        }
    }, [state.status]);

    if (state.status === ComponentStatus.error || errorContactFieldsAndSurveyItems) {
        return <Error500 />;
    }

    return (
        <ReportsLayout
            mapSurveys={mapSurveys}
            selectedSurvey={selectedMapSurvey}
            handleSurveySelected={handleSurveySelected}
            handleStartExport={handleStartExport}
            handleOpenFilter={handleOpenFilter}
            exportDisabled={
                state.selectedSurvey.id === emptySurveyInfo.id ||
                state.gridData[0].delta === initHeatmapDisplayValue.delta
            }
            filterDisabled={state.selectedSurvey.id === emptySurveyInfo.id}
            exportDataTestId={"btn-heatmap-export"}
            filter={state.currentFilter}
            onDeleteFilterItem={updateHeatmapData}
            isEmptyPage={showEmptyPage()}
            contactFields={contactFields}
        >
            <div className={classes.infoHeader}>
                <div className={classes.infoHeaderItem_dropDown}>
                    <Autocomplete
                        id="heatmap-select-contact-fields-combo-box"
                        options={state.contactFieldsOptions.map(s => {
                            return { id: s.id, label: s.title };
                        })}
                        getOptionDisabled={(option): boolean => {
                            return option.id === state.currentSelectedId;
                        }}
                        noOptionsText={lang.noOptionsAvailable}
                        value={selectedBreakdownItem(state.currentSelectedId)}
                        onChange={handleSelectBreakdown}
                        placeholder={lang.demographics}
                        background="white"
                    />
                </div>
                <div className={classes.infoHeaderItem}>
                    <Autocomplete
                        id="heatmap-select-compare-options-combo-box"
                        options={state.compareOptions.map(s => {
                            return {
                                id: s.id,
                                label: s.name
                            };
                        })}
                        noOptionsText={lang.noOptionsAvailable}
                        value={state.selectedCompareOption.name}
                        disabled={state.contactFieldsOptions.length === 0 || state.contactFieldsValues.length === 0}
                        onChange={handleSelectCompareOption}
                        label={lang.compareTo}
                        background="white"
                    />
                </div>
                {state.selectedCompareOption.id === defaultOption.id ||
                state.selectedCompareOption.id === filteredOverall.id ||
                state.selectedCompareOption.id === previousPeriod.id ? (
                    <ActionItem title={lang.cellContent} content={lang.difference} subTitle={lang.favorable} />
                ) : (
                    <Box alignSelf="center" width={370} pl={"12px"} pr={"12px"}>
                        <Select
                            onChange={handleSelectCompareTarget}
                            data-testid="heatmap-select-compare-targets"
                            label={lang.demographicValue}
                            value={state.selectedCompareTarget.id}
                            items={state.compareTargets.map(i => {
                                return {
                                    value: i.id,
                                    name: i.name
                                };
                            })}
                            disabled={state.compareTargets.length === 0}
                        />
                    </Box>
                )}
                <div className={classes.infoHeaderItemMsg}>
                    <Typography variant="subtitle2">{lang.heatmapMesg}</Typography>
                    <Typography variant="subtitle2">{lang.heatmapWarningMesg}</Typography>
                </div>
            </div>
            <div className={classes.dataContent}>
                <div className={classes.sidebar}></div>
                {state.selectedSurvey.id !== emptySurveyInfo.id && state.contactFieldsOptions.length === 0 ? (
                    <div className={classes.pivotGridWrapper}>
                        <EmptyPage
                            image={NoSurveySelected}
                            message={lang.noDemographicInfo}
                            subMessage={lang.noDemographicInfoMeg}
                        />
                    </div>
                ) : state.breakdownItems.items[0].id === initBreakdownItem.id ? (
                    <div className={classes.pivotGridWrapper}>
                        <EmptyPage
                            image={NoSurveySelected}
                            message={lang.noDemographicSelected}
                            subMessage={lang.noDemographicSelectedMsg}
                        />
                    </div>
                ) : state.confidentialityResult === ConfidentialityResult.tooSimilar ? (
                    <div className={classes.pivotGridWrapper}>
                        <ShowConfidentialityMessage />
                    </div>
                ) : state.confidentialityResult === ConfidentialityResult.belowThreshold ? (
                    <div className={classes.pivotGridWrapper}>
                        <ShowNotEnoughResponsesMessage responseCount={state.gridData.length} hideTotalResponse />
                    </div>
                ) : state.confidentialityResult === ConfidentialityResult.allSubGroupsBelowThreshold ? (
                    <div className={classes.pivotGridWrapper}>
                        <ShowNotEnoughResponsesMessage
                            responseCount={state.gridData.length}
                            hideTotalResponse
                            allSubGroups
                        />
                    </div>
                ) : isDataReady && switchAxis ? (
                    <HeatGridAxis
                        gridData={state.gridData}
                        sectionData={state.sectionData}
                        sectionOverall={state.sectionOverall}
                        itemOverall={state.itemOverall}
                        responseCount={state.responseCount}
                        selectedTarget={state.selectedCompareTarget.name}
                        isFilteredOverallSelected={state.selectedCompareOption.id === filteredOverall.id}
                        isPreviousPeriodSelected={state.selectedCompareOption.id === previousPeriod.id}
                        shouldUpdate={shouldGridUpdate}
                        switchAxis={handleSwitchAxis}
                        currentFilter={state.currentFilter}
                        showDeltaValue={showDeltaValue}
                        switchDelta={handleSwitchDelta}
                    />
                ) : isDataReady && !switchAxis ? (
                    <HeatGrid
                        gridData={state.gridData}
                        sectionData={state.sectionData}
                        sectionOverall={state.sectionOverall}
                        itemOverall={state.itemOverall}
                        responseCount={state.responseCount}
                        selectedTarget={state.selectedCompareTarget.name}
                        isFilteredOverallSelected={state.selectedCompareOption.id === filteredOverall.id}
                        isPreviousPeriodSelected={state.selectedCompareOption.id === previousPeriod.id}
                        shouldUpdate={shouldGridUpdate}
                        switchAxis={handleSwitchAxis}
                        currentFilter={state.currentFilter}
                        showDeltaValue={showDeltaValue}
                        switchDelta={handleSwitchDelta}
                    />
                ) : (
                    <> </>
                )}
            </div>
            {isBulkExportDialogOpen && (
                <DialogBulkExportSlide
                    title={lang.bulkExportExcel}
                    isOpen={isBulkExportDialogOpen}
                    onClose={handleBulkExportDialogClose}
                    onSubmitCallback={handleBulkExport}
                    contactFields={contactFields}
                    bulkExportDemographic={bulkExportDemographic}
                    updateBulkExportDemographic={handleUpdateBulkExportDemographic}
                />
            )}
            {state.isFilterDialogOpen && (
                <Filters
                    onCloseFilter={handleCloseFilters}
                    isOpen={state.isFilterDialogOpen}
                    onFiltersApplied={updateHeatmapData}
                    currentFilter={state.currentFilter}
                    filterMode={FilterMode.heatmap}
                    surveyId={state.selectedSurvey.id}
                    contactFields={contactFields}
                />
            )}
            <Snackbar open={state.snackBar.isOpen} handleClose={handleCloseSnackbar} message={state.snackBar.message} />
        </ReportsLayout>
    );
};

export default Heatmap;
