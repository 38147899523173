import { HeatmapActions } from "../action";
import { HeatmapState } from "../interface";
import { Action } from "core/interfaces";

export const heatmapReducer = (state: HeatmapState, action: Action): HeatmapState => {
    const { type, payload } = action;

    switch (type) {
        case HeatmapActions.INITIAL_STATE:
            return { ...state, ...payload };
        case HeatmapActions.SELECT_SURVEY:
            return {
                ...state,
                selectedSurvey: payload.selectedSurvey,
                currentSelectedId: payload.currentSelectedId,
                currentFilter: payload.currentFilter,
                breakdownItems: payload.breakdownItems,
                gridData: payload.gridData,
                sectionData: payload.sectionData,
                rawData: payload.rawData,
                sectionOverall: payload.sectionOverall,
                itemOverall: payload.itemOverall,
                responseCount: payload.responseCount,
                contactFieldsOptions: payload.contactFieldsOptions,
                contactFieldsValues: payload.contactFieldsValues,
                compareOptions: payload.compareOptions,
                selectedCompareOption: payload.selectedCompareOption,
                compareTargets: payload.compareTargets,
                selectedCompareTarget: payload.selectedCompareTarget,
                status: payload.status
            };
        case HeatmapActions.OPEN_CLOSE_FILTER:
            return { ...state, isFilterDialogOpen: payload.isFilterDialogOpen };
        case HeatmapActions.LOAD_SECTION_HRIS:
            return {
                ...state,
                surveyItemsOptions: payload.surveyItemsOptions,
                contactFieldsOptions: payload.contactFieldsOptions,
                contactFieldsValues: payload.contactFieldsValues,
                compareOptions: payload.compareOptions,
                status: payload.status
            };
        case HeatmapActions.SET_STATUS:
            return { ...state, status: payload.status };
        case HeatmapActions.SET_GRID_DATA:
            return {
                ...state,
                gridData: payload.gridData,
                sectionData: payload.sectionData,
                status: payload.status,
                rawData: payload.rawData,
                sectionOverall: payload.sectionOverall,
                itemOverall: payload.itemOverall,
                responseCount: payload.responseCount,
                confidentialityResult: payload.confidentialityResult
            };
        case HeatmapActions.SET_FILTER:
            return {
                ...state,
                currentFilter: payload.currentFilter,
                status: payload.status
            };
        case HeatmapActions.UPDATE_GRID_DATA:
            return {
                ...state,
                gridData: payload.gridData
            };
        case HeatmapActions.SET_BREAKDOWN_ITEMS:
            return { ...state, breakdownItems: payload.breakdownItems };
        case HeatmapActions.SET_CURRENT_BREAKDOWN:
            return { ...state, currentSelectedId: payload.currentSelectedId };
        case HeatmapActions.RESTORE_USER_SETTINGS:
            return {
                ...state,
                currentFilter: payload.currentFilter,
                breakdownItems: payload.breakdownItems,
                selectedSurvey: payload.selectedSurvey,
                surveyItemsOptions: payload.surveyItemsOptions,
                contactFieldsOptions: payload.contactFieldsOptions,
                contactFieldsValues: payload.contactFieldsValues,
                compareOptions: payload.compareOptions
            };
        case HeatmapActions.SET_COMPARE_OPTIONS:
            return {
                ...state,
                selectedCompareOption: payload.selectedCompareOption,
                compareTargets: payload.compareTargets,
                selectedCompareTarget: payload.selectedCompareTarget
            };
        case HeatmapActions.SELECT_COMPARE_TARGET:
            return {
                ...state,
                selectedCompareTarget: payload.selectedCompareTarget
            };
        case HeatmapActions.SNACKBAR_OPEN_CLOSE:
            return {
                ...state,
                snackBar: { ...payload.snackBar }
            };
        default:
            return {
                ...state
            };
    }
};
