import React from "react";
import { Typography } from "lib/typography";
import { useLang } from "core/hooks";
import { DialogLayout } from "components/shared";
import { AdminParticipantsStatusStyles } from "./styles";
import {
    AdminParticiantStatusResponse,
    AdminParticiantStatusErrorType
} from "components/admin/participants/interfaces";

type Props = {
    isOpen: boolean;
    onClose: () => void;
    status: AdminParticiantStatusResponse | undefined;
};

const AdminParticipantsStatusDialog: React.FC<Props> = props => {
    const { lang } = useLang();
    const classes = AdminParticipantsStatusStyles();
    const missingColumnHeader =
        props.status && props.status.errors
            ? props.status.errors.filter(e => e.type == AdminParticiantStatusErrorType.MissingHeader)
            : [];
    const missingColumnHeaderMessage =
        props.status && props.status.errors
            ? props.status.errors
                  .filter(e => e.type == AdminParticiantStatusErrorType.MissingHeader)
                  .map(e => e.message)
                  .join(", ")
            : "";

    const invalidEmails =
        props.status && props.status.errors
            ? props.status.errors.filter(e => e.type == AdminParticiantStatusErrorType.InvalidEmail)
            : [];
    const missingEmailField =
        props.status && props.status.errors
            ? props.status.errors.filter(e => e.type == AdminParticiantStatusErrorType.MissingEmailField)[0]
            : {};

    const missingEmailValue =
        props.status && props.status.errors
            ? props.status.errors.filter(e => e.type == AdminParticiantStatusErrorType.MissingEmailValue)[0]
            : {};

    const duplicateEmailField =
        props.status && props.status.errors
            ? props.status.errors.filter(e => e.type == AdminParticiantStatusErrorType.DuplicateEmail)
            : [];

    const updateEmailField =
        props.status && props.status.errors
            ? props.status.errors.filter(e => e.type == AdminParticiantStatusErrorType.UpdateEmailField)[0]
            : {};

    const duplicatedHeader =
        props.status && props.status.errors
            ? props.status.errors.filter(e => e.type == AdminParticiantStatusErrorType.DuplicateHeader)[0]
            : {};
    const duplicatedHeaderMessage =
        props.status && props.status.errors
            ? props.status.errors
                  .filter(e => e.type == AdminParticiantStatusErrorType.DuplicateHeader)
                  .map(e => e.message)
                  .map(m => m.split(":")[1])
                  .join(", ")
            : "";

    const handleExtraActionClicked = (): void => {
        navigator.clipboard.writeText(`${lang.NumberOfParticipantsUpdated}:  ${props.status?.numParticipantsUpdated}
        ${
            missingColumnHeader.length > 0
                ? `${lang.missingColumnHeader}:  ${missingColumnHeader.length} -  ${lang.column} ${missingColumnHeaderMessage}`
                : ""
        }
        ${
            invalidEmails.length > 0
                ? `${lang.invalidEmails}:  ${invalidEmails.length} -  ${invalidEmails
                      .map(email => email.message)
                      .join(", ")}`
                : ""
        }
        ${
            duplicateEmailField.length > 0
                ? `${lang.duplicateEmailField}:  ${duplicateEmailField.length} -  ${duplicateEmailField
                      .map(email => email.message)
                      .join(", ")}`
                : ""
        }
        ${missingEmailField ? `${lang.missingEmailField}` : ""}
        ${missingEmailValue ? `${lang.missingEmailValue}` : ""}
        ${updateEmailField ? `${lang.updateEmailField}` : ""}
        ${duplicatedHeader ? `${lang.duplicateHeader}: ${duplicatedHeaderMessage}` : `${lang.duplicateHeader}`}
        `);
    };

    return (
        <>
            <DialogLayout
                open={props.isOpen}
                onClose={props.onClose}
                title={lang.statusReport}
                buttonLabelAction={lang.ok}
                onClick={props.onClose}
                height={500}
                minHeight={500}
                extraAction={props.status && props.status.errors ? lang.copyToClipborad : ""}
                extraActionOnClick={handleExtraActionClicked}
                headerPaddingRight={16}
                hideCancel
            >
                <Typography className={classes.totalResponseBold} variant={"body2"}>
                    {`${lang.NumberOfParticipantsUpdated}:  ${props.status?.numParticipantsUpdated}`}
                </Typography>
                {missingColumnHeader.length > 0 && (
                    <div className={classes.errorResponse}>
                        <Typography className={classes.totalResponseBold} variant={"body2"}>
                            {`${lang.missingColumnHeader}:  ${missingColumnHeader.length}`}
                        </Typography>
                        <Typography className={classes.totalResponse} variant={"body2"}>
                            {`-  ${lang.column} ${missingColumnHeaderMessage}`}
                        </Typography>
                    </div>
                )}
                {invalidEmails.length > 0 && (
                    <div className={classes.errorResponse}>
                        <Typography className={classes.totalResponseBold} variant={"body2"}>
                            {`${lang.invalidEmails}:  ${invalidEmails.length}`}
                        </Typography>
                        {invalidEmails.map((email, index) => (
                            <Typography key={index} className={classes.totalResponse} variant={"body2"}>
                                {`-  ${email.message}`}
                            </Typography>
                        ))}
                    </div>
                )}
                {duplicateEmailField.length > 0 && (
                    <div className={classes.errorResponse}>
                        <Typography className={classes.totalResponseBold} variant={"body2"}>
                            {`${lang.duplicateEmailField}:  ${duplicateEmailField.length}`}
                        </Typography>
                        {duplicateEmailField.map((email, index) => (
                            <Typography key={index} className={classes.totalResponse} variant={"body2"}>
                                {`-  ${email.message}`}
                            </Typography>
                        ))}
                    </div>
                )}
                {missingEmailField && (
                    <Typography className={classes.totalResponseBold} variant={"body2"}>
                        {lang.missingEmailField}
                    </Typography>
                )}
                {missingEmailValue && (
                    <Typography className={classes.totalResponseBold} variant={"body2"}>
                        {lang.missingEmailValue}
                    </Typography>
                )}
                {updateEmailField && (
                    <Typography className={classes.totalResponseBold} variant={"body2"}>
                        {lang.updateEmailField}
                    </Typography>
                )}
                {duplicatedHeader && (
                    <Typography className={classes.totalResponseBold} variant={"body2"}>
                        {`${lang.duplicateHeader}: ${duplicatedHeaderMessage}`}
                    </Typography>
                )}
            </DialogLayout>
        </>
    );
};

export default AdminParticipantsStatusDialog;
