import { gql } from "@apollo/client";

export const organizationQuery = gql`
    query organization($id: ID!) {
        organization(id: $id) {
            id
            name
            active
            created
            organizationLogo {
                id
                logoUrl
                originalFileName
            }
            isDashboardEnabled
            isAIEnabled
        }
    }
`;
