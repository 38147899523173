const standardizeString = (str: string): string => {
    if (!str) return "";
    else {
        const newStr = str.toLowerCase().replace(/[^a-zA-Z0-9]/g, "");
        return newStr;
    }
};

export const sortArray = <T, K extends keyof T>(sortedArray: T[], key: K, order: string): T[] => {
    sortedArray.sort((a: T, b: T) => {
        if (order === "asc") {
            const varA = a[key];
            const varB = b[key];
            if (typeof varA === "string" && typeof varB === "string") {
                if (standardizeString(varA) > standardizeString(varB)) {
                    return 1;
                } else if (standardizeString(varB) > standardizeString(varA)) {
                    return -1;
                } else {
                    return 0;
                }
            } else {
                return a[key] > b[key] ? 1 : b[key] > a[key] ? -1 : 0;
            }
        } else {
            const varA = a[key];
            const varB = b[key];
            if (typeof varA === "string" && typeof varB === "string") {
                if (standardizeString(varA) > standardizeString(varB)) {
                    return -1;
                } else if (standardizeString(varB) > standardizeString(varA)) {
                    return 1;
                } else {
                    return 0;
                }
            } else {
                return a[key] > b[key] ? -1 : b[key] > a[key] ? 1 : 0;
            }
        }
    });
    return sortedArray;
};
