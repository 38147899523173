import React, { ReactElement, useState, useEffect, useReducer } from "react";
import { Typography } from "lib/typography";
import { useLazyQuery } from "@apollo/client";
import saveAs from "file-saver";

import { CorrelationState } from "./interface";
import { emptySurveyInfo } from "components/admin/results/init";
import { ComponentStatus } from "core/enums";
import { initFilter } from "components/filters/inits";
import { ConfidentialityResult } from "components/reports/heatmap/heatmap.init";
import { commentReducer } from "./reducer";
import { CorrelationActions } from "./action";
import {
    useSurveys,
    useLang,
    useLoading,
    useQueryWithPromise,
    useOrgChartService,
    useUpdateUserSettings
} from "core/hooks";
import Error500 from "components/errorPage/Error500";
import { useCorrelationStyles } from "./correlation.style";
import { Snackbar } from "lib/snackbar";
import { SurveyInfo } from "components/admin/results/interfaces";
import { DialogExportSlide } from "managerPortal/components/shared/DialogExportSlide";
import Filters from "components/filters/Filters";
import { FilterMode, FilterType } from "components/filters/enums";
import { Filter, FilterParameter } from "components/filters/interfaces";
import { correlationOnLoad, demographicFieldsAndValuesQuery } from "api/queries";
import { HrisFieldInfo } from "managerPortal/interfaces";
import ShowConfidentialityMessage from "managerPortal/components/snapshotReport/ShowConfidentialityMessage";
import ShowNotEnoughResponsesMessage from "managerPortal/components/snapshotReport/ShowNotEnoughResponsesMessage";
import CorrelationGrid from "./CorrelationGrid";
import { initCorrelationDisplayValue } from "./correlation.init";
import { colorRange050To064, colorRange065To100, colorRange000To049 } from "managerPortal/styles/GlobalStyles";
import ShowNotEnoughCorrelationResponse from "./ShowNotEnoughCorrelationResponse";
import { ReportsLayout } from "../layout/ReportsLayout";
import { BulkExportDemographic } from "managerPortal/components/shared/DialogExportSlideWithLookdown";
import { getMapSurveySelected, mapSurveysToAutocompleteOption, sortArray } from "core/helpers";
import { BulkReportType } from "api/rest/BulkReportStatus";
import { DialogBulkExportSlide } from "managerPortal/components/shared/DialogBulkExportSlide";
import { useUser } from "core/context/user/useUser";

const initialState = (): CorrelationState => {
    return {
        selectedSurvey: emptySurveyInfo,
        surveyItemsOptions: [],
        status: ComponentStatus.idle,
        currentFilter: initFilter,
        isFilterDialogOpen: false,
        confidentialityResult: ConfidentialityResult.success,
        gridData: initCorrelationDisplayValue,
        responseCount: 0
    };
};

const Correlation = (): ReactElement => {
    const classes = useCorrelationStyles();
    const [state, dispatch] = useReducer(commentReducer, initialState());
    const { surveys } = useSurveys();
    const { user } = useUser();
    const { setInitUserSetting, setUserSettingAfterFilterApplied } = useUpdateUserSettings();
    const { lang, languageCode } = useLang();
    const { setLoading } = useLoading();
    const orgChartService = useOrgChartService();
    const [isExportDialogOpen, setExportDialogOpen] = useState(false);
    const [shouldGridUpdate, setShouldGridUpdate] = useState<boolean>(false);
    const [snackbar, setSnackbar] = useState({ isOpen: false, message: "" });
    const [isBulkExportDialogOpen, setBulkExportDialogOpen] = useState(false);
    const [bulkExportDemographic, setBulkExportDemographic] = useState<BulkExportDemographic>({
        demographicFieldId: -1,
        demographicField: ""
    });
    const [contactFields, setContactFields] = useState<HrisFieldInfo[]>([]);
    const fetchHris = useQueryWithPromise(correlationOnLoad);
    const fetchDemographics = useQueryWithPromise(demographicFieldsAndValuesQuery);
    let hrisList: HrisFieldInfo[] = [];
    const mapSurveys = mapSurveysToAutocompleteOption([...surveys, emptySurveyInfo]);
    const selectedMapSurvey = getMapSurveySelected(mapSurveys, state.selectedSurvey.id);

    const shouldShowEmptyPage = (): boolean => {
        if (surveys.length === 0) {
            return true;
        }

        if (!user.settings.correlation) {
            return true;
        }

        if (user.settings.correlation) {
            if (user.settings.correlation.surveySelected === emptySurveyInfo.id) {
                return true;
            }
            if (surveys.find(s => s.id === user.settings.correlation.surveySelected) === undefined) {
                return true;
            }
        }

        if (state.selectedSurvey.id === emptySurveyInfo.id) {
            return true;
        }
        return false;
    };
    const helpText = (
        <div>
            {lang.correlationHelpText.map((l: string, index: number) => (
                <Typography key={index} variant="subtitle2">
                    {l}
                </Typography>
            ))}
        </div>
    );

    const [loadDimensionItems, { error: errorSurveyItems }] = useLazyQuery(correlationOnLoad, {
        onCompleted: data => {
            if (data) {
                dispatch({
                    type: CorrelationActions.LOAD_SECTION_HRIS,
                    payload: {
                        surveyItemsOptions: data.surveyItemFields
                    }
                });
                fetchCorrelationValue(initFilter, state.selectedSurvey.id);
            }
        }
    });
    const [loadDemographics] = useLazyQuery(demographicFieldsAndValuesQuery, {
        onCompleted: data => {
            if (data.demographicFieldsAndValues) {
                hrisList = [...data.demographicFieldsAndValues].filter((field: HrisFieldInfo) => !field.isHidden);

                const sortedDemographic = sortArray(hrisList, "fieldName", "asc");
                setContactFields(sortedDemographic);
            }
        }
    });
    const fetchCorrelationValue = (filter: Filter, surveyId: number): void => {
        setShouldGridUpdate(true);
        if (surveyId !== emptySurveyInfo.id) {
            orgChartService
                .correlationReport(0, 0, filter.items, surveyId, languageCode)
                .then(response => {
                    if (response.confidentialityResult !== ConfidentialityResult.success) {
                        dispatch({
                            type: CorrelationActions.SET_GRID_DATA,
                            payload: {
                                status: ComponentStatus.idle,
                                gridData: initCorrelationDisplayValue,
                                responseCount: response.responseCount,
                                confidentialityResult: response.confidentialityResult
                            }
                        });
                    } else if (response.correlations) {
                        const responseCount = response.responseCount;
                        const gridData = response.correlations;
                        dispatch({
                            type: CorrelationActions.SET_GRID_DATA,
                            payload: {
                                status: ComponentStatus.idle,
                                gridData,
                                responseCount,
                                confidentialityResult: response.confidentialityResult
                            }
                        });
                    } else {
                        dispatch({ type: CorrelationActions.SET_STATUS, payload: { status: ComponentStatus.idle } });
                    }
                })
                .catch(() => {
                    dispatch({ type: CorrelationActions.SET_STATUS, payload: { status: ComponentStatus.error } });
                })
                .finally(() => {
                    setShouldGridUpdate(false);
                });
        } else {
            dispatch({ type: CorrelationActions.SET_STATUS, payload: { status: ComponentStatus.error } });
        }
    };

    const restoreCorrelation = async (surveyId: number, filterItems: FilterParameter[]): Promise<void> => {
        const selectedSurvey = surveys.find((survey: SurveyInfo) => survey.id === surveyId);
        const loadedHris = await fetchHris({ surveyId: surveyId, languageCode, includeQuestionTypes: 3 });

        if (loadedHris) {
            const { data } = loadedHris;
            let restoredFilter = user.settings.filtersItems
                ? { ...state.currentFilter, items: filterItems }
                : initFilter;
            const loadedDemographics = await fetchDemographics({ surveyId: surveyId, languageCode });
            if (loadedDemographics) {
                const { data } = loadedDemographics;
                hrisList = [...data.demographicFieldsAndValues].filter((field: HrisFieldInfo) => !field.isHidden);
                const sortedDemographic = sortArray(hrisList, "fieldName", "asc");
                setContactFields(sortedDemographic);
            }
            restoredFilter.items
                .filter(item => item.filterType === FilterType.contactField)
                .forEach(item => {
                    if (!hrisList.map(list => list.fieldId).includes(item.field.id as number)) {
                        restoredFilter = initFilter;
                    }
                });
            dispatch({
                type: CorrelationActions.RESTORE_USER_SETTINGS,
                payload: {
                    selectedSurvey,
                    surveyItemsOptions: data.surveyItemFields,
                    currentFilter: restoredFilter
                }
            });
            fetchCorrelationValue(restoredFilter, surveyId);
        } else {
            dispatch({ type: CorrelationActions.SET_STATUS, payload: { status: ComponentStatus.idle } });
            setLoading(false);
        }
    };

    const handleSurveySelected = (id: number): void => {
        dispatch({ type: CorrelationActions.SET_STATUS, payload: { status: ComponentStatus.loading } });
        const selectedSurvey = surveys.find((survey: SurveyInfo) => survey.id === id);
        if (selectedSurvey && selectedSurvey.id !== state.selectedSurvey.id) {
            setLoading(true);
            dispatch({
                type: CorrelationActions.SELECT_SURVEY,
                payload: {
                    selectedSurvey,
                    currentFilter: initFilter
                }
            });
            loadDimensionItems({ variables: { surveyId: id, languageCode, includeQuestionTypes: 3 } });
            loadDemographics({ variables: { surveyId: id, languageCode } });
            setLoading(false);
        }
        if (!user.settings.correlation || id !== user.settings.correlation.surveySelected) {
            setInitUserSetting(id);
        }
    };

    const handleBulkExport = (): void => {
        setSnackbar({ isOpen: true, message: lang.startCorrelationBulkExport });
        orgChartService
            .startBulkExport(
                state.selectedSurvey.id,
                bulkExportDemographic.demographicFieldId,
                languageCode,
                BulkReportType.Correlation,
                user.settings
            )
            .then(() => {
                setBulkExportDialogOpen(false);
            });
    };
    const handleUpdateBulkExportDemographic = (updatedBulkExport: BulkExportDemographic): void => {
        setBulkExportDemographic(updatedBulkExport);
    };
    const handleBulkExportDialogClose = (): void => {
        setBulkExportDialogOpen(false);
    };

    const handleStartExport = (isBulkExport: boolean): void => {
        if (isBulkExport) {
            setBulkExportDialogOpen(true);
        } else {
            setExportDialogOpen(true);
        }
    };
    const handleExportDialogClose = (): void => {
        setExportDialogOpen(false);
    };
    const handleExport = (mainTitle: string): void => {
        setLoading(true);
        setExportDialogOpen(false);
        orgChartService
            .exportCorrelationReport(0, 0, state.currentFilter.items, state.selectedSurvey.id, languageCode)
            .then((blob: unknown) => {
                saveAs(blob as Blob, mainTitle + ".xlsx");
                setLoading(false);
            })
            .catch(() => {
                setSnackbar({ isOpen: true, message: lang.unableToFetchPPTX });
                setLoading(false);
            });
    };
    const handleCloseSnackbar = (): void => {
        setSnackbar({ isOpen: false, message: "" });
    };

    //Filter start
    const handleOpenFilter = (): void => {
        dispatch({
            type: CorrelationActions.OPEN_CLOSE_FILTER,
            payload: { isFilterDialogOpen: true }
        });
    };
    const handleCloseFilters = (): void => {
        dispatch({
            type: CorrelationActions.OPEN_CLOSE_FILTER,
            payload: { isFilterDialogOpen: false }
        });
    };
    const updateCorrelationData = (filter: Filter): void => {
        dispatch({ type: CorrelationActions.SET_STATUS, payload: { status: ComponentStatus.loading } });
        dispatch({
            type: CorrelationActions.SET_FILTER,
            payload: { status: ComponentStatus.loading, currentFilter: filter }
        });
        setUserSettingAfterFilterApplied(filter);
        fetchCorrelationValue(filter, state.selectedSurvey.id);
    };

    useEffect(() => {
        dispatch({ type: CorrelationActions.SET_STATUS, payload: { status: ComponentStatus.loading } });
        const restoredState = user.settings.correlation;

        if (
            restoredState &&
            restoredState.surveySelected !== emptySurveyInfo.id &&
            mapSurveys.find(s => s.id === restoredState.surveySelected) !== undefined
        ) {
            dispatch({ type: CorrelationActions.SET_STATUS, payload: { status: ComponentStatus.loading } });
            restoreCorrelation(restoredState.surveySelected, user.settings.filtersItems);
        } else {
            dispatch({ type: CorrelationActions.SET_STATUS, payload: { status: ComponentStatus.idle } });
        }
    }, []);

    useEffect(() => {
        if (state.status === ComponentStatus.loading) {
            setLoading(true);
        } else {
            setLoading(false);
        }
    }, [state.status]);

    if (state.status === ComponentStatus.error || errorSurveyItems) {
        return <Error500 />;
    }
    return (
        <ReportsLayout
            mapSurveys={mapSurveys}
            selectedSurvey={selectedMapSurvey}
            handleSurveySelected={handleSurveySelected}
            handleStartExport={handleStartExport}
            handleOpenFilter={handleOpenFilter}
            exportDisabled={
                state.selectedSurvey.id === emptySurveyInfo.id ||
                state.confidentialityResult !== ConfidentialityResult.success
            }
            filterDisabled={state.selectedSurvey.id === emptySurveyInfo.id}
            exportDataTestId={"btn-heatmap-export"}
            filter={state.currentFilter}
            onDeleteFilterItem={updateCorrelationData}
            isEmptyPage={shouldShowEmptyPage()}
            contactFields={contactFields}
        >
            <div className={classes.infoHeader}>
                <div data-testid="totalResponseCount" className={classes.totalResponseHeader}>
                    <Typography className={classes.infoHeaderText} variant="h6">
                        {lang.totalResponses} - {state.responseCount}
                    </Typography>
                </div>
                <div className={classes.colorsReferenceContainer}>
                    <div className={classes.colorsReference}>
                        <div className={classes.boxStyle} style={{ background: colorRange065To100 }}></div>
                        <Typography>{`≥ .60`}</Typography>
                    </div>
                    <div className={classes.colorsReferenceMiddle}>
                        <div className={classes.boxStyle} style={{ background: colorRange050To064 }}></div>
                        <Typography>.40 - .60</Typography>
                    </div>
                    <div className={classes.colorsReference}>
                        <div className={classes.boxStyle} style={{ background: colorRange000To049 }}></div>
                        <Typography>{`< .40`}</Typography>
                    </div>
                </div>
                <div className={classes.infoHeaderItemMsg}>{helpText}</div>
            </div>
            <div className={classes.dataContent}>
                {state.confidentialityResult === ConfidentialityResult.tooSimilar ? (
                    <div className={classes.pivotGridWrapper}>
                        <ShowConfidentialityMessage />
                    </div>
                ) : state.confidentialityResult === ConfidentialityResult.tooFewResponsesToCorrelate ? (
                    <div className={classes.pivotGridWrapper}>
                        <ShowNotEnoughCorrelationResponse />
                    </div>
                ) : state.confidentialityResult === ConfidentialityResult.belowThreshold ? (
                    <div className={classes.pivotGridWrapper}>
                        <ShowNotEnoughResponsesMessage responseCount={state.responseCount} hideTotalResponse />
                    </div>
                ) : (
                    <CorrelationGrid
                        surveyItemsOptions={state.surveyItemsOptions}
                        currentFilter={state.currentFilter}
                        gridData={state.gridData}
                        shouldUpdate={shouldGridUpdate}
                    />
                )}
            </div>
            {isExportDialogOpen && (
                <DialogExportSlide
                    dialogTitle={lang.exportToExcel}
                    isOpen={isExportDialogOpen}
                    onClose={handleExportDialogClose}
                    onSubmitCallback={handleExport}
                />
            )}
            {isBulkExportDialogOpen && (
                <DialogBulkExportSlide
                    title={lang.bulkExportExcel}
                    isOpen={isBulkExportDialogOpen}
                    onClose={handleBulkExportDialogClose}
                    onSubmitCallback={handleBulkExport}
                    contactFields={contactFields}
                    bulkExportDemographic={bulkExportDemographic}
                    updateBulkExportDemographic={handleUpdateBulkExportDemographic}
                />
            )}
            <Snackbar open={snackbar.isOpen} message={snackbar.message} handleClose={handleCloseSnackbar} />
            {state.isFilterDialogOpen && (
                <Filters
                    onCloseFilter={handleCloseFilters}
                    isOpen={state.isFilterDialogOpen}
                    onFiltersApplied={updateCorrelationData}
                    currentFilter={state.currentFilter}
                    filterMode={FilterMode.correlation}
                    surveyId={state.selectedSurvey.id}
                    contactFields={contactFields}
                />
            )}
        </ReportsLayout>
    );
};

export default Correlation;
