import React, { ReactElement } from "react";
import { makeStyles } from "@mui/styles";

import { useLang } from "core/hooks";
import { PanelItem, DashboardData, LookdownItem } from "./interface";
import { GRID_ROW_LENGTH, GRID_COL_LENGTH } from "components/admin/results/dashboard/Dashboard";
import { itemHeight, itemWidth } from "./DashboardItem";
import { emptyBarColor, favorableColor, neutralColor, unfavorableColor } from "managerPortal/styles/GlobalStyles";
import StackedBar from "managerPortal/components/stackedBar/StackedBar";
import { darkGreyColor } from "managerPortal/styles/GlobalStyles";
import { dashboardRowLimits } from "./UserDashboard";
import { Typography } from "lib/typography";
import { Tooltip } from "lib/tooltip";

type CellTypeStyleProps = {
    xPosition: number;
    yPosition: number;
    numberOfColumn: number;
    numberOfRow: number;
    height: number;
    width: number;
    numberOfshowSideBar: number;
    numberOfRows: number;
};

interface DisplayTitle {
    title: string;
    isShowingSign: boolean;
}

interface DisplayNumber {
    value: number | null;
    isShowingSign: boolean;
}

interface Props {
    testid: number;
    item: PanelItem;
    dashboardData: DashboardData;
}

const useStyles = makeStyles(() => ({
    cell: (styleProps: CellTypeStyleProps) => {
        return {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            position: "absolute",
            top: `${(styleProps.yPosition / styleProps.numberOfRow) * 100}%`,
            left: `${(styleProps.xPosition / styleProps.numberOfColumn) * 100}%`,
            height: itemHeight * styleProps.height,
            width: itemWidth * styleProps.width
        };
    },
    cellText: (styleProps: CellTypeStyleProps) => {
        return {
            display: "flex",
            height: "10%",
            minHeight: "10%",
            maxHeight: "10%",
            width: styleProps.width * itemWidth,
            maxWidth: styleProps.width * itemWidth,
            justifyContent: "space-between"
        };
    },
    title: () => {
        return {
            display: "flex",
            justifyContent: "center",
            fontSize: 16,
            fontWeight: 500
        };
    },
    titleSide: (styleProps: CellTypeStyleProps) => {
        return {
            width: (styleProps.numberOfshowSideBar * styleProps.width * itemWidth) / 12,
            minWidth: (styleProps.numberOfshowSideBar * styleProps.width * itemWidth) / 12,
            maxWidth: (styleProps.numberOfshowSideBar * styleProps.width * itemWidth) / 12
        };
    },
    chart: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        height: "90%",
        maxHeight: "90%",
        overflowY: "hidden",
        overflowX: "hidden"
    },
    overallDimension: (styleProps: CellTypeStyleProps) => {
        return {
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            height: `${90 / styleProps.numberOfRows}%`,
            maxHeight: "30%",
            width: styleProps.width * itemWidth,
            minWidth: styleProps.width * itemWidth,
            maxWidth: styleProps.width * itemWidth
        };
    },
    overallDimensionName: (styleProps: CellTypeStyleProps) => {
        return {
            display: "flex",
            height: "100%",
            alignItems: "center",
            justifyContent: "flex-end",
            width: (styleProps.width * itemWidth) / 3,
            minWidth: (styleProps.width * itemWidth) / 3,
            maxWidth: (styleProps.width * itemWidth) / 3
        };
    },
    overallDimensionNameText: () => {
        return {
            display: "block",
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            width: "95%",
            maxWidth: "95%",
            paddingLeft: 16,
            paddingRight: 8,
            textAlign: "right"
        };
    },
    OverallDimensionChartWrapper: (styleProps: CellTypeStyleProps) => {
        return {
            display: "flex",
            height: "100%",
            alignItems: "center",
            width: (2 / 3 - styleProps.numberOfshowSideBar / 12) * styleProps.width * itemWidth,
            minWidth: (2 / 3 - styleProps.numberOfshowSideBar / 12) * styleProps.width * itemWidth,
            maxWidth: (2 / 3 - styleProps.numberOfshowSideBar / 12) * styleProps.width * itemWidth,
            justifyContent: "center"
        };
    },
    overallDimensionChart: {
        display: "flex",
        height: "80%",
        alignItems: "center",
        width: "95%",
        maxWidth: "95%",
        justifyContent: "flex-end"
    },
    displayBar: (styleProps: CellTypeStyleProps) => {
        return {
            display: "flex",
            height: "100%",
            alignItems: "center",
            width: (styleProps.numberOfshowSideBar * styleProps.width * itemWidth) / 12,
            minWidth: (styleProps.numberOfshowSideBar * styleProps.width * itemWidth) / 12,
            maxWidth: (styleProps.numberOfshowSideBar * styleProps.width * itemWidth) / 12,
            justifyContent: "center"
        };
    },
    singleDisplayBar: (styleProps: CellTypeStyleProps) => {
        return {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            width: (styleProps.width * itemWidth) / 12,
            minWidth: (styleProps.width * itemWidth) / 12,
            maxWidth: (styleProps.width * itemWidth) / 12
        };
    },
    singleTitleDisplayBar: (styleProps: CellTypeStyleProps) => {
        return {
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
            alignItems: "center",
            height: "100%",
            width: (styleProps.width * itemWidth) / 12,
            minWidth: (styleProps.width * itemWidth) / 12,
            maxWidth: (styleProps.width * itemWidth) / 12
        };
    },
    valueNA: {
        color: darkGreyColor,
        fontWeight: "bold"
    },
    valuePositive: {
        color: favorableColor,
        fontWeight: "bold"
    },
    valueNegative: {
        color: unfavorableColor,
        fontWeight: "bold"
    },
    valueNoSign: {
        fontWeight: "bold"
    },
    noData: {
        fontSize: 12
    },
    noDataCondense: {
        fontSize: 10
    },
    tooManyRows: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    }
}));

const CellLookdown = (props: Props): ReactElement => {
    const { item, dashboardData, testid } = props;
    let scoreArray = [] as LookdownItem[];
    const lookdownInfo = dashboardData.lookdownInfo;
    if (lookdownInfo) {
        scoreArray = dashboardData.lookdownInfo[`(${item.xPosition}, ${item.yPosition})`]?.scores ?? [];
    }

    const getNumberOfSideBar = (): number => {
        if (JSON.parse(item.props).hidePPDelta) return 1;
        let resultNumber = 1;
        if (
            !JSON.parse(item.props).hidePPDelta &&
            dashboardData.previousPeriodLabels &&
            dashboardData.previousPeriodLabels.filter(v => v !== null).length > 0
        ) {
            resultNumber += dashboardData.previousPeriodLabels.length;
        }
        return resultNumber;
    };

    const classes = useStyles({
        xPosition: item.xPosition,
        yPosition: item.yPosition,
        numberOfColumn: GRID_COL_LENGTH,
        numberOfRow: GRID_ROW_LENGTH,
        height: item.height,
        width: item.width,
        numberOfshowSideBar: getNumberOfSideBar(),
        numberOfRows: scoreArray.length
    });
    const { lang, languageCode } = useLang();
    const isCondensed = scoreArray.length > 15;

    const calculateTotal = (unfavorablePercent: number, neutralPercent: number, favorablePercent: number): number => {
        let total = 0;
        total = [unfavorablePercent, neutralPercent, favorablePercent].reduce(
            (a: number, b: number): number => a + b,
            0
        );
        return total;
    };

    const getScoreDisplayValues = (score: LookdownItem): number[] => {
        if (!score) return [0];
        if (calculateTotal(score.unfavorablePercent, score.neutralPercent, score.favorablePercent) <= 0) return [0];
        return [score.unfavorablePercent, score.neutralPercent, score.favorablePercent];
    };

    const getScoreColors = (score: LookdownItem): string[] => {
        if (!score) return [emptyBarColor];
        if (calculateTotal(score.unfavorablePercent, score.neutralPercent, score.favorablePercent) <= 0)
            return [emptyBarColor];
        return [unfavorableColor, neutralColor, favorableColor];
    };
    const getScoreDisplayPercentage = (score: LookdownItem): boolean => {
        if (!score) return false;
        return calculateTotal(score.unfavorablePercent, score.neutralPercent, score.favorablePercent) > 0;
    };
    const getSideBarDiplayNumber = (delta: number | null, isShowingSign: boolean): string => {
        if (!isShowingSign && delta !== null && delta !== undefined) return delta.toString();
        if (delta === undefined) return "n/a";
        if (delta === null) return "n/a";
        if (delta === 0) return "0";
        if (delta > 0) {
            return `+${delta}`;
        } else return delta.toString();
    };

    const getSideBar = (score: LookdownItem): ReactElement => {
        const displayBar = [] as DisplayNumber[];
        if (
            !JSON.parse(item.props).hidePPDelta &&
            dashboardData.previousPeriodLabels &&
            dashboardData.previousPeriodLabels.filter(v => v !== null).length > 0
        ) {
            for (let i = 0; i < dashboardData.previousPeriodLabels.length; i++) {
                if (score.previousFavorable) {
                    displayBar.push({ value: score.previousFavorable[i], isShowingSign: true });
                } else {
                    displayBar.push({ value: null, isShowingSign: true });
                }
            }
        }
        displayBar.push({ value: score.count, isShowingSign: false });
        return (
            <div className={classes.displayBar}>
                {displayBar.map((delta, index) => (
                    <div key={index} className={classes.singleDisplayBar} data-testid={`${score.text}-index-${index}`}>
                        <Typography
                            variant={isCondensed ? "caption" : "body2"}
                            className={
                                !delta.isShowingSign
                                    ? classes.valueNoSign
                                    : delta.value && delta.value > 0
                                      ? classes.valuePositive
                                      : delta.value && delta.value < 0
                                        ? classes.valueNegative
                                        : classes.valueNA
                            }
                        >
                            {getSideBarDiplayNumber(delta.value, delta.isShowingSign)}
                        </Typography>
                    </div>
                ))}
            </div>
        );
    };

    const getSideBarTitle = (): ReactElement => {
        const displayTitle = [] as DisplayTitle[];
        if (
            !JSON.parse(item.props).hidePPDelta &&
            dashboardData.previousPeriodLabels &&
            dashboardData.previousPeriodLabels.filter(v => v !== null).length > 0
        ) {
            for (let i = 0; i < dashboardData.previousPeriodLabels.length; i++) {
                displayTitle.push({ title: dashboardData.previousPeriodLabels[i], isShowingSign: true });
            }
        }
        displayTitle.push({ title: lang.count, isShowingSign: false });
        return (
            <div className={classes.displayBar}>
                {displayTitle.map((title, index) => (
                    <div key={index} className={classes.singleTitleDisplayBar}>
                        {title.isShowingSign && (
                            <Typography
                                variant={isCondensed ? "caption" : "body2"}
                                style={{ fontWeight: "bold", fontSize: 12, marginBottom: -4 }}
                            >
                                +/-
                            </Typography>
                        )}
                        <Typography
                            variant={isCondensed ? "caption" : "body2"}
                            style={{ fontWeight: "bold", fontSize: 12 }}
                        >
                            {title.title}
                        </Typography>
                    </div>
                ))}
            </div>
        );
    };

    const getChart = (): ReactElement => {
        return (
            <div className={classes.chart}>
                {scoreArray.length > 0 ? (
                    scoreArray.map((score, index) => (
                        <div key={index} className={classes.overallDimension} data-testid={`lookdown-${score.text}`}>
                            <div className={classes.overallDimensionName}>
                                <Typography
                                    className={classes.overallDimensionNameText}
                                    variant={isCondensed ? "caption" : "body2"}
                                >
                                    {score.text}
                                </Typography>
                            </div>
                            <div className={classes.OverallDimensionChartWrapper}>
                                <div className={classes.overallDimensionChart}>
                                    {getScoreDisplayValues(score) ? (
                                        <StackedBar
                                            values={getScoreDisplayValues(score)}
                                            colors={getScoreColors(score)}
                                            displayAllPercentage={getScoreDisplayPercentage(score)}
                                            percentageFontSize={isCondensed ? 10 : 12}
                                            sectionLevel
                                        />
                                    ) : (
                                        <Typography className={isCondensed ? classes.noDataCondense : classes.noData}>
                                            {lang.noData}
                                        </Typography>
                                    )}
                                </div>
                            </div>
                            {getSideBar(score)}
                        </div>
                    ))
                ) : (
                    <></>
                )}
            </div>
        );
    };

    return (
        <div className={classes.cell} data-testid={`panel-${testid}`}>
            <div className={classes.cellText}>
                <div className={classes.titleSide}></div>
                <Tooltip
                    title={item.translations.filter(t => t.languageCode === languageCode)[0]?.name ?? ""}
                    placement={"bottom"}
                >
                    <Typography variant="body2" style={{ fontWeight: 500 }} className={classes.title}>
                        {item.translations.filter(t => t.languageCode === languageCode)[0]?.name ?? ""}
                    </Typography>
                </Tooltip>
                {scoreArray.length > dashboardRowLimits ? (
                    <div className={classes.titleSide}></div>
                ) : (
                    <div className={classes.titleSide}>{getSideBarTitle()}</div>
                )}
            </div>
            {scoreArray.length > dashboardRowLimits ? (
                <div className={classes.tooManyRows}>{lang.tooManyRowsWarning}</div>
            ) : (
                getChart()
            )}
        </div>
    );
};

export default CellLookdown;
